import { AxiosError } from 'axios';
import { notification } from 'antd';
import { DetailedError } from './interfaces/detailedError';
import i18n from '../../../../i18n';
import { urlsToWhichNotReturn } from 'app.config';
import agent from '../agent';
import axios from 'axios';
import store from 'App/state/store';
import { refreshTokenSuccess } from 'App/state/auth/auth.slice';
import history from '../../../../app.history';
import { newOrderErrorCode } from 'App/api/endpoints/userOrders/utils/newOrderErrorCodes';
import { addDiscountCodeErrorCodes } from 'App/api/endpoints/userPromotions/utils/addDiscountCodeErrorCodes';

export const errorHandler = (error: AxiosError) => {
	const { status } = error.response || {};

	switch (status) {
		case 400:
			handleBadRequest(error);
			return Promise.reject(error);

		case 401:
			return handleUnauthorized(error);

		case 403:
			handleForbidden(error);
			return Promise.reject(error);

		case 404:
			handleNotFound(error);
			return Promise.reject(error);

		case 409:
			handleConflict(error);
			return Promise.reject(error);

		case 500:
			handleInternalServerError(error);
			return Promise.reject(error);

		default:
			return Promise.reject(error);
	}
};

function handleBadRequest(error: AxiosError<any>) {
	const { data } = error.response || {};

	console.log({ error });

	//ERROR HANDLING ERESTO
	if (error?.config) {
		switch (error.config.url) {
			//POST ORDER
			case '/client/orders-web':
			case '/client/orders-web-anonymous':
				switch (data?.status_code) {
					case newOrderErrorCode.PROMOTION_NOT_ACTIVE:
						notification['error']({
							message: i18n.t('common:Errors.Error'),
							description: 'Wybrana promocja nie jest już aktywna',
						});
						return;
					case newOrderErrorCode.PROMOTION_WRONG_TIME:
						notification['error']({
							message: i18n.t('common:Errors.Error'),
							description: 'Wybrana promocja nie jest aktywna w wybranych godzinach',
						});
						return;
					case newOrderErrorCode.WRONG_PRICE:
						notification['error']({
							message: i18n.t('common:Errors.Error'),
							description: 'Nieprawidłowa cena',
						});
						return;
					case newOrderErrorCode.UNAVAILABLE_RESTAURANT:
						notification['error']({
							message: i18n.t('common:Errors.Error'),
							description: 'Wybrany sklep nie jest dostępny',
						});
						return;
				}
				break;

			// logowanie ma swoj error handling w komponencie, więc nie potrzebujemy dodatkowego błędu
			case 'oauth/token':
				return;

			case '/client/promotions/codes':
				switch (data?.status_code) {
					case addDiscountCodeErrorCodes.WRONG_CODE:
						notification['error']({
							message: i18n.t('common:Errors.Error'),
							description: 'Nieprawidłowy kod',
						});
						return;
					case addDiscountCodeErrorCodes.NO_ACTIVE_PROMOTION:
						notification['error']({
							message: i18n.t('common:Errors.Error'),
							description: 'Wybrana promocja nie jest już aktywna',
						});
						return;
					case addDiscountCodeErrorCodes.WRONG_PROMOTION_TIME:
						notification['error']({
							message: i18n.t('common:Errors.Error'),
							description: 'Wybrana promocja nie jest aktywna w wybranych godzinach',
						});
						return;
				}
				break;

			default:
				break;
		}
	}

	// mamy 2 typy 400-tek (teoretycznie)

	// zwykła 400 - zawiera obiekt errors, który zawiera obiekty detailedErrors oraz commonErrors
	// foramularzowa - nie zawiera obiektu errors ani detailedErrors ani commonErrors,
	// ale zawiera słownik o nazwach kluczy takich, jak pole jest nazwane, czyli np. mając w formularzu
	// inputy dla pól roles oraz firstName, to dostaniemy słowniki o kluczu roles oraz firstName, a dla nich mamy jako valuesy
	// już podaną arrayke detailedErrorów

	if (data.errors) {
		// 400-tka zwykła(nieformularzowa)
		let mainErrorObject = data.errors;
		if (mainErrorObject.detailedErrors) {
			let detailedErrors = mainErrorObject.detailedErrors as DetailedError[];
			detailedErrors.forEach((detailedError) => {
				const { errorCode, errorParameters } = detailedError;

				const errorCodeWithDot = errorCode.replace('-', '.');
				const translationKey = `detailedErrors:${errorCodeWithDot}.DescriptionFormatter`;

				const args = errorParameters;

				notification['error']({
					message: i18n.t('common:Errors.Error'),
					description: i18n.t(translationKey, { args }),
				});
			});
		}

		if (mainErrorObject.commonErrors) {
			console.log(mainErrorObject.commonErrors);
		}
	} else if (data.detailedErrors) {
		// 400-tka formularzowa
		Object.keys(data.detailedErrors).forEach((key) => {
			let detailedErrorsForCurrentKey = data.detailedErrors[key] as DetailedError[];
			detailedErrorsForCurrentKey.forEach((detailedError) => {
				const { errorCode, errorParameters } = detailedError;

				const errorCodeWithDot = errorCode.replace('-', '.');
				const translationKey = `detailedErrors:${errorCodeWithDot}.DescriptionFormatter`;

				const args = errorParameters;

				notification['error']({
					message: i18n.t('common:Errors.Error'),
					description: i18n.t(translationKey, { args }),
				});
			});
		});
	} else {
		notification['error']({
			message: i18n.t('common:Errors.Error'),
			description: i18n.t('common:Errors.AnErrorOccured'),
		});
	}
}

function handleInternalServerError(error: AxiosError<any>) {
	notification['error']({
		message: i18n.t('common:Errors.Error'),
		description: i18n.t('common:Errors.ServerSideError'),
	});
	console.log(`500: ${error.response.data}`);

	history.push('/500');
}

function handleUnauthorized(error) {
	// odswiezenie tokena sie nie powiodło, 401 jako odpowiedz na refresh tokena czyli wygasł
	if (error.response.config.url === 'oauth/token') {
		localStorage.removeItem('refresh_token');
		history.push('/sign-in');
		return Promise.reject(error);
	}

	let browserUrl = history.location.pathname;

	if (!urlsToWhichNotReturn.some((url) => browserUrl.includes(url))) localStorage.setItem('returnUrl', browserUrl);

	return agent.Auth.refreshToken()
		.then((res) => {
			store.dispatch(refreshTokenSuccess(res));
			return axios.request(error.config);
		})
		.catch((err) => {
			return Promise.reject(error);
		});
}

function handleForbidden(error: AxiosError<any>) {
	console.log('403: ' + error.response);
	notification['error']({
		message: i18n.t('common:Errors.Error'),
		description: i18n.t('common:Errors.DontHaveAccess'),
	});

	history.push('/403');
}

function handleNotFound(error: AxiosError<any>) {
	const { data } = error.response || {};

	if (data.errors) {
		// 404 z kodem błędu
		let mainErrorObject = data.errors;
		if (mainErrorObject.detailedErrors) {
			let detailedErrors = mainErrorObject.detailedErrors as DetailedError[];
			detailedErrors.forEach((detailedError) => {
				const { errorCode, errorParameters } = detailedError;

				const errorCodeWithDot = errorCode.replace('-', '.');
				const translationKey = `detailedErrors:${errorCodeWithDot}.DescriptionFormatter`;

				const args = errorParameters;

				notification['error']({
					message: i18n.t('common:Errors.Error'),
					description: i18n.t(translationKey, { args }),
				});
			});
		}

		if (mainErrorObject.commonErrors) {
			console.log(mainErrorObject.commonErrors);
		}
	} else {
		notification['error']({
			message: i18n.t('common:Errors.Error'),
			description: i18n.t('common:Errors.NoResourceWasFound'),
		});
	}
	console.log('404: ' + error);
}

function handleConflict(error: AxiosError<any>) {
	if (error?.response?.data === 'User with given email address already exists')
		notification['error']({
			message: i18n.t('common:Errors.Error'),
			description: 'Użytkownik o takim e-mailu już istnieje',
		});
	else
		notification['error']({
			message: i18n.t('common:Errors.Error'),
			description: 'Wystąpił błąd',
		});
}
