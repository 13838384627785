import { CartProduct } from "App/domain/entities/cart_product";

export const cartProductService = {

    getFinalPrice(cartProduct: CartProduct) {
        if (!cartProduct)
            return 0

        let price = cartProduct.product.price_pln

        cartProduct.option_items.forEach((optionItem) => {
            price += optionItem.price_in_pln
        })

        return cartProduct.count * price;
    },

    getCartProductFromForm(values, product) {
        let cartProduct: CartProduct = {
            product: {
                ...product,
                product_category: null,
                product_options: null,
                promotion_products: null,
            },
            count: values['quantity'],
            option_items: [],
            promotions: [],
        };

        let valuesKeys = Object.keys(values)
        let firstValuesKey = valuesKeys[0]

        //Jak pierwsze nie jest quantity to znaczy że są productOption
        if (firstValuesKey !== 'quantity') {
            if (!isNaN(Number(firstValuesKey))) {
                for (let i = 0; i < valuesKeys.length; i++) {

                    if (!isNaN(Number(valuesKeys[i]))) {
                        let productOption = product.product_options.find((productOption) => productOption.id === Number(valuesKeys[i]));

                        if (productOption) {
                            let optionItems = undefined;
                            let optionItemsIds = values[valuesKeys[i]]

                            //są jako tablica dla Checkbox.Group
                            if (Array.isArray(optionItemsIds))
                                optionItems = productOption.option_items.filter((optionItem) =>
                                    optionItemsIds.includes(optionItem.id)
                                );
                            //są jako zmienna dla Radiobox.Group
                            else
                                optionItems = productOption.option_items.filter((optionItem) => optionItem.id === optionItemsIds);

                            cartProduct.option_items = cartProduct.option_items.concat(optionItems)
                        }
                    }
                }
            }
        }

        return cartProduct;
    },

    getFinalPriceFromForm(allFields, product) {
        let priceToAdd = product.price_pln;

        allFields.forEach((field) => {
            //fieldy które są Checkbox.Group albo Radiobox.Group jako nazwę mają 1 elementową tablicę w której jest ich Id (Id ProductOption)
            if (Array.isArray(field.name) && !field.name.some(isNaN)) {
                //wiec sprawdzamy czy taki jest w product
                let productOption = product.product_options.find((productOption) => productOption.id === field.name[0]);

                if (productOption) {
                    //a potem wyciągamy optionItemsy bo w fieldzie ich Id zapisane są jako tablica/zmienna w field.value
                    let optionItems = undefined;

                    //są jako tablica dla Checkbox.Group
                    if (Array.isArray(field.value))
                        optionItems = productOption.option_items.filter((optionItem) =>
                            field.value.includes(optionItem.id)
                        );
                    //są jako zmienna dla Radiobox.Group
                    else optionItems = productOption.option_items.filter((optionItem) => optionItem.id === field.value);

                    if (optionItems && optionItems.length > 0)
                        optionItems.forEach((optionItem) => (priceToAdd += optionItem.price_in_pln));
                }
            }
        });

        let quantityField = allFields.find((field) => field.name.includes('quantity'));

        if (quantityField)
            return priceToAdd * quantityField.value;

        return priceToAdd;
    }
}