import { Modal } from 'antd';
import { addDiscountCodePromotion } from 'App/state/promotions/promotions.thunk';
import { RootState } from 'App/state/root.reducer';
import StatusType from 'App/types/requestStatus';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddDiscountCodeForm from '../forms/AddDiscountCodeForm';

const AddDiscountCodeModal = (props: { visible: boolean; handleClose: any; }) => {
    const { visible, handleClose } = props;
    const dispatch = useDispatch()

    const addDiscountCodePromotionStatus = useSelector((state: RootState) => state.promotions.status.addDiscountCodePromotion);

    const onFinish = (values) => {
        dispatch(addDiscountCodePromotion(values.code, handleClose))
    };

    return (
        <Modal title='Dodaj kod zniżkowy' visible={visible} onOk={handleClose} onCancel={handleClose} footer={null}>
            <AddDiscountCodeForm
                onFinish={onFinish}
                loading={addDiscountCodePromotionStatus === StatusType.LOADING}
            />
        </Modal>
    );
};

export default AddDiscountCodeModal;
