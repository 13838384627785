import React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { FormProps } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './TrainingContactForm.less';

interface TrainingContactFormProps extends FormProps {
	// loading: boolean;
}

const TrainingContactForm: React.FC<TrainingContactFormProps> = (props: TrainingContactFormProps) => {
	const { t } = useTranslation(['page', 'form', 'common']);
	// const { loading, ...rest } = props;

	return (
		<Form 
		style={{width: '100%'}}
		// {...rest}
		>
			<Form.Item
				name='fullName'
				// messageVariables={{ arg: t('form:Common.User.Labels.Email') }}
				// rules={registerFormRules.email()}
			>
				<Input 
				// placeholder={t('form:Common.User.Placeholders.Email')} 
				placeholder={'Imię i nazwisko'}
				/>
			</Form.Item>

			<Form.Item
				name='phone'
				// messageVariables={{ arg: t('form:Common.User.Labels.Password') }}
				// rules={registerFormRules.password()}
			>
				<Input 
				// placeholder={t('form:Common.User.Placeholders.Password')} 
				placeholder={'Telefon'}
				/>
			</Form.Item>

			<Form.Item
				name='email'
				// rules={registerFormRules.confirmPassword()}
			>
				<Input 
				// placeholder={t('form:Common.User.Placeholders.ConfirmPassword')} 
				placeholder={'Email'}
				/>
			</Form.Item>


			<Form.Item 		
			style={{marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}
			>
				<Button 
				style={{marginLeft: 'auto', marginRight: 'auto'}}
				// loading={loading}
				 type='primary' htmlType='submit' size='large'>
					{/* {t('common:Actions.SignUp')} */}
					Wyślij
				</Button>
			</Form.Item>
		</Form>
	);
};

export default TrainingContactForm;
