import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'App/state/root.reducer';
import Role from 'App/types/role';
import NotLoggedInNavbar from '../components/Navbar/NotLoggedInNavbar';
import ClientNavbar from '../components/Navbar/ClientNavbar';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavbarContainer: React.FC = () => {
	const accountDetails = useSelector((state: RootState) => state.account.accountDetails);

	const { t } = useTranslation('page');

	// const restaurantMenu = (
	// 	<Menu.Item key='/restaurant-menu'>
	// 		<Link to='/restaurant-menu'>{t('Common.NavbarContainer.Menu')}</Link>
	// 	</Menu.Item>
	// );

	const restaurantMenu = (
		<Menu.Item key='/shop'>
			<Link to='/shop/1'>Sklep</Link>
		</Menu.Item>
	);

	const aboutUsMenu = (
		<Menu.Item key='/about-us'>
			<Link to='/about-us'>O nas</Link>
		</Menu.Item>
	);

	const instructors = (
		<Menu.Item key='/instructors'>
			<Link to='/instructors'>Instruktorzy</Link>
		</Menu.Item>
	);

	const trainings = (
		<Menu.Item key='/trainings'>
			<Link to='/trainings'>Szkolenia</Link>
		</Menu.Item>
	);

	const distributors = (
		<Menu.Item key='/distributors'>
			<Link to='/distributors'>Dystrybutorzy</Link>
		</Menu.Item>
	);

	const contact = (
		<Menu.Item key='/contact'>
			<Link to='/contact'> Kontakt</Link>
		</Menu.Item>
	);

	const promotions = (
		<Menu.Item key='/promotions'>
			<Link to='/promotions'>{t('Common.NavbarContainer.Promotions')}</Link>
		</Menu.Item>
	);

	const orders = (
		<Menu.Item key='/orders'>
			<Link to='/orders'>{t('Common.NavbarContainer.Orders')}</Link>
		</Menu.Item>
	);

	if (accountDetails) {
		if (accountDetails.user_role.includes(Role.CLIENT)) {
			return (
				<ClientNavbar
					accountDetails={accountDetails}
					orders={orders}
					promotions={promotions}
					restaurantMenu={restaurantMenu}
				/>
			);
		}
	} else {
		return (
			<NotLoggedInNavbar
				promotions={promotions}
				restaurantMenu={restaurantMenu}
				aboutUs={aboutUsMenu}
				instructors={instructors}
				trainigs={trainings}
				distributors={distributors}
				contact={contact}
			/>
		);
	}
};

export default NavbarContainer;
