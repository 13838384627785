import agent from 'App/api/agent/agent';
import { AppThunk } from '../store';
import {
	getProductCategoriesFailure,
	getProductCategoriesStart,
	getProductCategoriesSuccess,
} from './productCategories.slice';

export const getProductCategories = (): AppThunk => async (dispatch) => {
	dispatch(getProductCategoriesStart());
	agent.ProductCategories.getAllProductCategories()
		.then((response) => {
			dispatch(getProductCategoriesSuccess(response));
		})
		.catch(() => dispatch(getProductCategoriesFailure()));
};
