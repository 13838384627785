import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductForGetAllProductsCategoriesResponse } from "App/api/endpoints/productCategories/responses/getAllProductsCategoriesResponse";
import StatusType from "App/types/requestStatus";
import { productCategoriesInitialState, ProductCategoriesState } from "./productCategories.state";

const { FAILED, SUCCESS, LOADING } = StatusType;

const productCategoriesSlice = createSlice({
    name: 'product-categories',
    initialState: productCategoriesInitialState,
    reducers: {

        getProductCategoriesStart: (state: ProductCategoriesState) => {
            state.status.getProductCatgories = LOADING;
            state.productCategories = [];
        },
        getProductCategoriesSuccess: (state: ProductCategoriesState, action: PayloadAction<ProductForGetAllProductsCategoriesResponse[]>) => {
            state.status.getProductCatgories = SUCCESS;
            state.productCategories = action.payload;
        },
        getProductCategoriesFailure: (state: ProductCategoriesState) => {
            state.status.getProductCatgories = FAILED;
        },

    }
})

export default productCategoriesSlice;

export const {
    getProductCategoriesStart,
    getProductCategoriesSuccess,
    getProductCategoriesFailure,

} = productCategoriesSlice.actions;