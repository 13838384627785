import React, { useEffect, useState } from 'react';

import { Row, Col, Collapse, Modal } from 'antd';

import './TrainingsPageContainer.less';
import trainingsSectionRowTopImage from '../HomePage/assets/łezka.png';
import { CaretRightOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

import trainerImage1 from './assets/1.png';
import trainerImage2 from './assets/2.png';
import trainerImage3 from './assets/3.png';
import AOS from 'aos';
import TrainingContactForm from './components/TrainingContactForm';


const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const TrainingsPageContainer: React.FC<{}> = () => {

    const isMobile = useMediaQuery({ maxWidth: 768 });

    const [openContactForm, setOpenContactForm] = useState(false);

    useEffect(() => {
		AOS.init({
			duration: 2600,
			once: true,
		});
		window.addEventListener('load', AOS.refresh);
    }, []);
    
	return (
		<div>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>

                    <div data-aos="fade-in">
                        <Row justify='center' className='trainings-page-container-title'>
                            Szkolenia
                        </Row>
                        <Row justify='center' className='trainings-page-container-image-divider'>
                            <img src={trainingsSectionRowTopImage} className='trainings-section-row-top-image'></img>
                        </Row>
                        <Row justify='center' className='trainings-page-container-description'>
                            <Col xs={22} sm={22} md={12} lg={12} xl={12} className=''>
                                Wiedza na szkoleniach InLei przekazywana jest przez wykwalifikowaną kadrę specjalistów, zgodnie z najwyższymi standardami marki. Jeśli chcesz być na bieżąco z nowościami, pogłębić swoją wiedzę z zakresu laminacji i poznać wszystkie niuanse pracy z produktami InLei to zapraszamy do zapoznania się z naszą ofertą szkoleniową.
                            </Col>
                        </Row>  
                    </div>

                    				<Modal
										centered
										visible={openContactForm}
										onOk={() => setOpenContactForm(false)}
										onCancel={() => setOpenContactForm(false)}
										width={isMobile? '90%' : '50%'}
									>
										<Col xs={24} sm={24} md={24} lg={24} xl={24}>

											<Row>
												<Col xs={22} sm={22} md={22} lg={22} xl={22}>
													<Row justify='center' className='trainings-page-container-modal-title'>
                                                        Zapisz się na szkolenie
													</Row>
												</Col>
											</Row>
 
											<Row>
												<Col xs={22} sm={22} md={22} lg={22} xl={22}>
													<Row justify='end'>
                                                    <TrainingContactForm
                                                        style={{marginRight: 'auto', marginLeft: 'auto'}}
                                                        preserve
                                                        className='training-contact-form'
                                                        name='trainingContactForm'
                                                        size='large'
                                                        // onFinish={registerHandler}
                                                        autoComplete='off'
                                                        // loading={status === StatusType.LOADING}
                                                    />
													
													</Row>
												</Col>
											</Row>

                                            <Row>
												<Col xs={22} sm={22} md={22} lg={22} xl={22}>
													<Row justify='center' className='trainings-page-container-modal-description'>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
													</Row>
												</Col>
											</Row>

                                        </Col>
 
									</Modal>


                    <Row justify='center' data-aos="fade-in">
                        <Collapse
                            className='trainings-page-container-collapse'
                            accordion={true}
                            bordered={false}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        >
                            <Panel header={<Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row trainings-page-container-collapse-date' justify='center' align='middle'>
                                        18.09.2021
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row' justify='center' align='middle'>
                                        Warszawa
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6} className=''>
                                    <Row className='trainings-page-container-collapse-row trainings-page-container-collapse-description' justify='center' align='middle'>
                                        Lorem ipsum dolor sit amet
                                    </Row>
                                
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row' justify='center' align='middle'>
                                        <img src={trainerImage1} className='trainings-page-container-collapse-trainer-image'></img>

                                        Anna nowak
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row' justify='center' align='middle'>
                                        <div onClick={() => setOpenContactForm(true)} className='trainings-page-container-collapse-button'>Zapisz się</div>
                                    </Row>
                                </Col>

                            </Row>
                            } key="1" className="site-collapse-custom-panel">
                            <Row>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='trainings-page-container-collapse-inner-col'>
                                    <Row className='trainings-page-container-collapse-inner-row'>
                                        Plan szkolenia
                                    </Row>
                                    <Row>
                                        <div className='brow-bomber-page-container-description-divider'></div>
                                    </Row>
                                    <Row>
                                        1. Lorem ipsum <br></br>
                                        2. Lorem ipsum <br></br>
                                        3. Lorem ipsum <br></br>
                                        4. Lorem ipsum <br></br>
                                        5. Lorem ipsum <br></br>
                                        6. Lorem ipsum <br></br>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='trainings-page-container-collapse-inner-col'>
                                    <Row className='trainings-page-container-collapse-inner-row'>
                                        Opis szkolenia
                                    </Row>
                                    <Row>
                                        <div className='brow-bomber-page-container-description-divider'></div>
                                    </Row>
                                    <Row>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </Row>
                                </Col>
                            </Row>




                            </Panel>
                            <Panel header={<Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row trainings-page-container-collapse-date' justify='center' align='middle'>
                                        18.09.2021
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row' justify='center' align='middle'>
                                        Warszawa
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6} className=''>
                                    <Row className='trainings-page-container-collapse-row trainings-page-container-collapse-description' justify='center' align='middle'>
                                        Lorem ipsum dolor sit amet
                                    </Row>
                                
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row' justify='center' align='middle'>
                                        <img src={trainerImage1} className='trainings-page-container-collapse-trainer-image'></img>

                                        Anna nowak
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row' justify='center' align='middle'>
                                        <div onClick={() => setOpenContactForm(true)} className='trainings-page-container-collapse-button'>Zapisz się</div>
                                    </Row>
                                </Col>

                            </Row>
                            } key="2" className="site-collapse-custom-panel">
                            <Row>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='trainings-page-container-collapse-inner-col'>
                                    <Row className='trainings-page-container-collapse-inner-row'>
                                        Plan szkolenia
                                    </Row>
                                    <Row>
                                        <div className='brow-bomber-page-container-description-divider'></div>
                                    </Row>
                                    <Row>
                                        1. Lorem ipsum <br></br>
                                        2. Lorem ipsum <br></br>
                                        3. Lorem ipsum <br></br>
                                        4. Lorem ipsum <br></br>
                                        5. Lorem ipsum <br></br>
                                        6. Lorem ipsum <br></br>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='trainings-page-container-collapse-inner-col'>
                                    <Row className='trainings-page-container-collapse-inner-row'>
                                        Opis szkolenia
                                    </Row>
                                    <Row>
                                        <div className='brow-bomber-page-container-description-divider'></div>
                                    </Row>
                                    <Row>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </Row>
                                </Col>
                            </Row>




                            </Panel>
                            <Panel header={<Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row trainings-page-container-collapse-date' justify='center' align='middle'>
                                        18.09.2021
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row' justify='center' align='middle'>
                                        Warszawa
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6} className=''>
                                    <Row className='trainings-page-container-collapse-row trainings-page-container-collapse-description' justify='center' align='middle'>
                                        Lorem ipsum dolor sit amet
                                    </Row>
                                
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row' justify='center' align='middle'>
                                        <img src={trainerImage1} className='trainings-page-container-collapse-trainer-image'></img>

                                        Anna nowak
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row' justify='center' align='middle'>
                                        <div onClick={() => setOpenContactForm(true)} className='trainings-page-container-collapse-button'>Zapisz się</div>
                                    </Row>
                                </Col>

                            </Row>
                            } key="3" className="site-collapse-custom-panel">
                            <Row>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='trainings-page-container-collapse-inner-col'>
                                    <Row className='trainings-page-container-collapse-inner-row'>
                                        Plan szkolenia
                                    </Row>
                                    <Row>
                                        <div className='brow-bomber-page-container-description-divider'></div>
                                    </Row>
                                    <Row>
                                        1. Lorem ipsum <br></br>
                                        2. Lorem ipsum <br></br>
                                        3. Lorem ipsum <br></br>
                                        4. Lorem ipsum <br></br>
                                        5. Lorem ipsum <br></br>
                                        6. Lorem ipsum <br></br>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='trainings-page-container-collapse-inner-col'>
                                    <Row className='trainings-page-container-collapse-inner-row'>
                                        Opis szkolenia
                                    </Row>
                                    <Row>
                                        <div className='brow-bomber-page-container-description-divider'></div>
                                    </Row>
                                    <Row>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </Row>
                                </Col>
                            </Row>




                            </Panel>
                            <Panel header={<Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row trainings-page-container-collapse-date' justify='center' align='middle'>
                                        18.09.2021
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row' justify='center' align='middle'>
                                        Warszawa
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6} className=''>
                                    <Row className='trainings-page-container-collapse-row trainings-page-container-collapse-description' justify='center' align='middle'>
                                        Lorem ipsum dolor sit amet
                                    </Row>
                                
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row' justify='center' align='middle'>
                                        <img src={trainerImage1} className='trainings-page-container-collapse-trainer-image'></img>

                                        Anna nowak
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4} className=''>
                                    <Row className='trainings-page-container-collapse-row' justify='center' align='middle'>
                                        <div onClick={() => setOpenContactForm(true)} className='trainings-page-container-collapse-button'>Zapisz się</div>
                                    </Row>
                                </Col>

                            </Row>
                            } key="4" className="site-collapse-custom-panel">
                            <Row>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='trainings-page-container-collapse-inner-col'>
                                    <Row className='trainings-page-container-collapse-inner-row'>
                                        Plan szkolenia
                                    </Row>
                                    <Row>
                                        <div className='brow-bomber-page-container-description-divider'></div>
                                    </Row>
                                    <Row>
                                        1. Lorem ipsum <br></br>
                                        2. Lorem ipsum <br></br>
                                        3. Lorem ipsum <br></br>
                                        4. Lorem ipsum <br></br>
                                        5. Lorem ipsum <br></br>
                                        6. Lorem ipsum <br></br>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className='trainings-page-container-collapse-inner-col'>
                                    <Row className='trainings-page-container-collapse-inner-row'>
                                        Opis szkolenia
                                    </Row>
                                    <Row>
                                        <div className='brow-bomber-page-container-description-divider'></div>
                                    </Row>
                                    <Row>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </Row>
                                </Col>
                            </Row>


                            </Panel>


                        </Collapse>

                    </Row>

                </Col>
            </Row>
		</div>
	);
};

export default TrainingsPageContainer;
