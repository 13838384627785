import store from 'App/state/store';
import axios from 'axios';
import { errorHandler } from '../errorHandling/errorHandler';

export const baseFilesURL = `https://inlei-backend-dev.eresto1.xaa.pl/`;
// export const baseFilesURL = `http://localhost:8000/`;
export const baseURL = `${baseFilesURL}api/`;

export const returnUrlAfterPayment = 'https://inlei-dev.eresto1.xaa.pl/orders/';
// export const returnUrlAfterPayment = "http://localhost:3000/orders/"
export const paymentStatusURL = baseURL + 'payment/status';

export const isDev = true;
export const paymentURL = isDev
	? 'https://sandbox.przelewy24.pl/trnRequest/'
	: 'https://secure.przelewy24.pl/trnRequest/';

export const wroclawGeolocation = [51.107883, 17.038538];
axios.defaults.baseURL = baseURL;

axios.interceptors.request.use(
	(config) => {
		// token interceptor goes here
		const token = store.getState()?.auth?.tokens?.token;

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(null, errorHandler);
