import agent from 'App/api/agent/agent';
import {
	AddUserAddressRequest,
	ChangePasswordRequest,
	GenerateNewPasswordRequest,
	UpdateAccountDetailsRequest,
	UpdateAccountPhoneNumberRequest,
	UpdateUserAddressRequest,
} from 'App/api/endpoints/account/requests';
import { GetAccountDetailsResponse } from 'App/api/endpoints/account/responses';
import { AppThunk } from '../store';
import {
	addUserAddressFailure,
	addUserAddressStart,
	addUserAddressSuccess,
	changePasswordFailure,
	changePasswordStart,
	changePasswordSuccess,
	clearAccountStart,
	confirmEmailFailure,
	confirmEmailStart,
	confirmEmailSuccess,
	deleteAccountFailure,
	deleteAccountStart,
	deleteAccountSuccess,
	deleteUserAddressFailure,
	deleteUserAddressStart,
	deleteUserAddressSuccess,
	generateNewPasswordFailure,
	generateNewPasswordStart,
	generateNewPasswordSuccess,
	getAccountDetailsFailure,
	getAccountDetailsStart,
	getAccountDetailsSuccess,
	getUserAddressesFailure,
	getUserAddressesStart,
	getUserAddressesSuccess,
	updateAccountDetailsFailure,
	updateAccountDetailsStart,
	updateAccountDetailsSuccess,
	updateAccountPhoneNumberFailure,
	updateAccountPhoneNumberStart,
	updateAccountPhoneNumberSuccess,
	updateUserAddressFailure,
	updateUserAddressStart,
	updateUserAddressSuccess,
} from './account.slice';

export const confirmEmail = (activationCode: string, onSuccess?: () => void): AppThunk => async (dispatch) => {
	dispatch(confirmEmailStart());
	agent.Account.confirmEmail(activationCode)
		.then(() => {
			dispatch(confirmEmailSuccess());
			if (onSuccess) onSuccess();
		})
		.catch(() => dispatch(confirmEmailFailure()));
};

export const generateNewPassword = (request: GenerateNewPasswordRequest, onSuccess?: () => void): AppThunk => async (
	dispatch
) => {
	dispatch(generateNewPasswordStart());
	agent.Account.generateNewPassword(request)
		.then(() => {
			dispatch(generateNewPasswordSuccess());
			if (onSuccess) onSuccess();
		})
		.catch(() => dispatch(generateNewPasswordFailure()));
};

export const getAccountDetails = (onSuccess?: () => void, onFailure?: () => void): AppThunk => async (dispatch) => {
	dispatch(getAccountDetailsStart());
	agent.Account.getAccountDetails()
		.then((accountDetailsResponse: GetAccountDetailsResponse) => {
			dispatch(getAccountDetailsSuccess(accountDetailsResponse));
			if (onSuccess) {
				onSuccess();
			}
		})
		.catch((err) => {
			dispatch(getAccountDetailsFailure());
			if (onFailure) onFailure();
		});
};

export const updateAccountDetails = (request: UpdateAccountDetailsRequest, onSuccess?: () => void): AppThunk => async (
	dispatch
) => {
	dispatch(updateAccountDetailsStart());
	agent.Account.updateAccountDetails(request)
		.then(() => {
			dispatch(updateAccountDetailsSuccess(request));
			dispatch(getAccountDetails());
			if (onSuccess) {
				onSuccess();
			}
		})
		.catch(() => dispatch(updateAccountDetailsFailure()));
};

export const updateAccountPhoneNumber = (
	request: UpdateAccountPhoneNumberRequest,
	onSuccess?: () => void
): AppThunk => async (dispatch) => {
	dispatch(updateAccountPhoneNumberStart());
	agent.Account.updateAccountPhoneNumber(request)
		.then((response) => {
			dispatch(updateAccountPhoneNumberSuccess(response));
			dispatch(getAccountDetails());
			if (onSuccess) {
				onSuccess();
			}
		})
		.catch(() => dispatch(updateAccountPhoneNumberFailure()));
};

export const changePassword = (request: ChangePasswordRequest, onSuccess?: () => void): AppThunk => async (
	dispatch
) => {
	dispatch(changePasswordStart());
	agent.Account.changePassword(request)
		.then(() => {
			dispatch(changePasswordSuccess());
			if (onSuccess) {
				onSuccess();
			}
		})
		.catch(() => dispatch(changePasswordFailure()));
};

export const addUserAddress = (request: AddUserAddressRequest, onSuccess?: () => void): AppThunk => async (
	dispatch
) => {
	dispatch(addUserAddressStart());
	agent.Account.addUserAddress(request)
		.then(() => {
			dispatch(addUserAddressSuccess());
			if (onSuccess) {
				onSuccess();
			}
		})
		.catch(() => dispatch(addUserAddressFailure()));
};

export const updateUserAddress = (
	addressId: number,
	request: UpdateUserAddressRequest,
	onSuccess?: () => void
): AppThunk => async (dispatch) => {
	dispatch(updateUserAddressStart());
	agent.Account.updateUserAddress(addressId, request)
		.then(() => {
			dispatch(updateUserAddressSuccess());
			if (onSuccess) {
				onSuccess();
			}
		})
		.catch(() => dispatch(updateUserAddressFailure()));
};

export const deleteUserAddress = (addressId: number, onSuccess?: () => void): AppThunk => async (dispatch) => {
	dispatch(deleteUserAddressStart());
	agent.Account.deleteUserAddress(addressId)
		.then((resp: any) => {
			dispatch(deleteUserAddressSuccess());
			if (onSuccess) {
				onSuccess();
			}
		})
		.catch((err) => {
			dispatch(deleteUserAddressFailure());
		});
};

export const getUserAddresses = (onSuccess?: () => void): AppThunk => async (dispatch) => {
	dispatch(getUserAddressesStart());
	agent.Account.getUserAddresses()
		.then((response) => {
			dispatch(getUserAddressesSuccess(response));
			if (onSuccess) {
				onSuccess();
			}
		})
		.catch(() => dispatch(getUserAddressesFailure()));
};

export const deleteAccount = (request: UpdateAccountDetailsRequest, onSuccess?: () => void): AppThunk => async (
	dispatch
) => {
	dispatch(deleteAccountStart());
	agent.Account.updateAccountDetails(request)
		.then(() => {
			dispatch(deleteAccountSuccess());
			if (onSuccess) onSuccess();
		})
		.catch(() => dispatch(deleteAccountFailure()));
};

export const clearAccountDetails = (): AppThunk => async (dispatch) => {
	dispatch(clearAccountStart());
};
