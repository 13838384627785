import { Cart } from 'App/domain/entities/cart';
import { Promotion } from 'App/domain/entities/promotion';
import { Restaurant } from 'App/domain/entities/restaurant';
import { cartProductService } from './cartProductService';
import { promotionService } from './promotionService';
import { ServiceResponse, ServiceResponseStatus } from './utils/serviceResponse';

export const cartService = {
	getCartPrice(cart: Cart) {
		if (!cart) return 0;

		let price = 0;

		cart.cart_products.forEach((cartProduct) => {
			price += cartProductService.getFinalPrice(cartProduct);
		});

		return price;
	},

	getAllDistinctPromotions(cart: Cart) {
		let cartProducts = cart.cart_products;

		let promotions: Promotion[] = [];

		cartProducts.forEach((cartProduct) => {
			if (cartProduct?.promotions.length > 0) {
				cartProduct.promotions.forEach((productPromotion) => {
					if (!promotions.find((promotion) => promotion.id === productPromotion.id)) {
						if (this.isPromotionValidForCart(cart, productPromotion)) {
							promotions.push(productPromotion);
						}
					}
				});
			}
		});
		return promotions;
	},

	getDiscountPrice(cart: Cart) {
		let discount = 0.0;
		let totalCartPrice = this.getCartPrice(cart);

		cart.promotions.forEach((promotion) => {
			discount += promotionService.getDiscountFromValue(
				totalCartPrice,
				promotion.discount_type,
				promotion.discount
			);
		});

		return discount;
	},

	getFinalCartPrice(cart: Cart) {
		return this.getCartPrice(cart) - this.getDiscountPrice(cart);
	},

	getProductsCount(cart: Cart) {
		return cart?.cart_products?.reduce((sum, nextProduct) => sum + nextProduct.count, 0);
	},

	isPromotionValidForCart(cart: Cart, promotion: Promotion) {
		return (
			promotion.minimal_cart_count <= this.getProductsCount(cart) &&
			promotion.minimal_cart_value_in_pln <= this.getCartPrice(cart)
		);
	},

	isCouponCodeValidForCart(cart: Cart, restaurant: Restaurant, promotion: Promotion): ServiceResponse {
		if (!promotionService.isValidForCartProducts(promotion, cart.cart_products))
			return { message: "Wybrany kod promocyjny nie obejmuje żadnych z wybranych produktów w koszyku.", status: ServiceResponseStatus.FAILURE }

		if (!promotionService.isValidForRestaurant(promotion, restaurant))
			return { message: "Wybrany kod promocyjny nie obejmuje wybranej restauracji.", status: ServiceResponseStatus.FAILURE }

		if (!promotionService.isValidForMinimalTotalCartValue(promotion, cart))
			return { message: `Kod promocyjny jest dostępny od minimum ${promotion.minimal_cart_value_in_pln} PLN.`, status: ServiceResponseStatus.FAILURE }

		return { status: ServiceResponseStatus.OK };
	}
};


