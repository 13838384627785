import { ProductForGetAllProductsCategoriesResponse } from 'App/api/endpoints/productCategories/responses/getAllProductsCategoriesResponse';
import { PlaceProductForGetRestaurantResponse } from 'App/api/endpoints/restaurants/responses/getRestaurantResponse';

export const getMenuCategoryWithSubcategories = (
	productCategory: ProductForGetAllProductsCategoriesResponse,
	products: PlaceProductForGetRestaurantResponse[]
) => {
	let categoryProducts = products?.filter((product) => {
		return product.product.categories.length;
	});

	// let subcategories = productCategory.product_subcategories.map((subcategory) =>
	// 	getMenuCategoryWithSubcategories(subcategory, products)
	// );

	return {
		id: productCategory.id,
		menuOrder: productCategory.menu_order,
		name: productCategory.name,
		products: categoryProducts,
		// subcategories: subcategories,
	};
};
