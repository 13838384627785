import { ShoppingCartOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import { RootState } from 'App/state/root.reducer';
import { useSelector } from 'react-redux';
import { cartService } from 'App/services/cartService';

interface ShoppingCartMenuItemProps {
    isMobile?: boolean;
}


const CartMenuItem = (props: ShoppingCartMenuItemProps) => {
    const cart = useSelector((state: RootState) => state.newOrder.cart);

    const { isMobile, ...rest } = props;
    const className = isMobile ? '' : 'float-right';

    return (
        <Menu.Item {...rest} key='/' className={className}>
            <Link to='/cart'>
                <Badge count={cartService.getProductsCount(cart)} size="small">
                    <ShoppingCartOutlined />
                </Badge>
            </Link>
        </Menu.Item>
    );
};

export default CartMenuItem;
