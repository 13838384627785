import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';

import './HomePageContainer.less';

import landingSectionImage from './assets/produkt.png';

import productsSectionImage1 from './assets/kits-image-2.png';

import productsSectionImage2 from './assets/2lashfiller.png';
import productsSectionImage3 from './assets/3browbomber.png';
import productsSectionImage4 from './assets/4_formysilikonowe.png';
import productsSectionImage5 from './assets/5koloryzacja.png';
import productsSectionImage6 from './assets/6akcesoria.png';
import productsSectionImage7 from './assets/7pielegnacja.png';
import productsSectionImage8 from './assets/8materialypromocyjne.png';

import aboutSectionImage1 from './assets/FORM1-milk-splash.png';
import aboutSectionImage2 from './assets/MADE-IN-ITALY-icon (1).png';
import aboutSectionImage3 from './assets/CLINICALLY-TESTED-icon (1).png';
import aboutSectionImage4 from './assets/ikona z krolikiem-2.png';

import aboutProductSection1 from './assets/2lashfiler.png';
import aboutProductSection2 from './assets/volume-icon.png';
import aboutProductSection3 from './assets/made-in-italy-icon.png';
import aboutProductSection4 from './assets/clinically-tested-icon.png';
import aboutProductSection5 from './assets/platinum-icon.png';

import trainingsSectionRowTopImage from './assets/łezka.png';
import trainingsSectionRowBottomImage1 from './assets/ksiazka-ikona.png';
import trainingsSectionRowBottomImage2 from './assets/trenerka-ikona.png';
import trainingsSectionRowBottomImage3 from './assets/wysokie-wyniki-ikona.png';

import trainingsEnrolmentSectionRowTopImage from './assets/mail-ikona.png';
import trainingsEnrolmentSectionRowBottomImage1 from './assets/brew-ikona.png';
import trainingsEnrolmentSectionRowBottomImage2 from './assets/rzesy-ikona.png';
import trainingsEnrolmentSectionRowBottomImage3 from './assets/krem-ikona.png';

import newProductsSectionImage1 from './assets/new-products-item-1.png';
import newProductsSectionImage2 from './assets/new-products-item-2.png';
import newProductsSectionImage3 from './assets/new-products-item-3.png';

import specialOfferSectionImage1 from './assets/special-offer-left.png';
import specialOfferSectionImage2 from './assets/special-offer-item-1.png';
import specialOfferSectionImage3 from './assets/special-offer-right.png';

import AOS from 'aos';

import { useHistory } from 'react-router';
import 'aos/dist/aos.css';
import InstructorsMap from './components/instructorsMap/InstructorsMap';
import { instructorsData } from './components/instructorsMap/utils/instructorsData';

const HomePageContainer: React.FC<{}> = () => {
	const history = useHistory();

	useEffect(() => {
		AOS.init({
			duration: 2600,
			once: true,
		});
		window.addEventListener('load', AOS.refresh);
	}, []);

	const navigateToShopCategory = (categoryId: number) => {
		history.push(`/shop/${categoryId}`);
	};

	const navigateToTrainings = () => {
		history.push('/trainings');
	};

	const [chosenInstructor, setChosenInstructor] = useState(0);

	const handleInstructorSelectClick = (instructorId: number) => {
		document.getElementById('map-item-' + chosenInstructor).classList.remove('map-section-image-grow');

		setChosenInstructor(instructorId);

		document.getElementById('map-item-' + instructorId).classList.add('map-section-image-grow');
		//instructorsSlider.current.goTo(Number(instructorId))
	};

	return (
		<div className='home-page-container'>
			<Row justify='center' align='middle' className='landing-section'>
				<Col xs={22} sm={20} md={18} lg={10} xl={10} data-aos='fade-right'>
					<div className='landing-section-divider'>
						<span></span>
					</div>
					<div className='landing-section-title'>Witaj w InLei!</div>
					<div className='landing-section-subtitle'>
						Miło nam Cię gościć w świecie profesjonalnych kosmetyków Made in Italy.
					</div>
					<div className='landing-section-description'>
						Z okazji uruchomienia nowej strony przygotowaliśmy dla Ciebie wyjątkowe rabaty, a także darmową
						wysyłkę Twoich ulubionych produktów InLei.
					</div>
					<div onClick={() => navigateToShopCategory(0)} className='landing-section-button'>
						Zobacz
					</div>
				</Col>
				<Col xs={22} sm={20} md={18} lg={10} xl={10} data-aos='fade-left'>
					<Row justify='center'>
						<img src={landingSectionImage} className='landing-section-image'></img>
					</Row>
				</Col>
			</Row>

			<Row justify='center' align='middle' className='products-section'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Row justify='center'>
						<Col
							xs={22}
							sm={20}
							md={10}
							lg={5}
							xl={5}
							className='products-section-column column-1'
							data-aos='fade-up'
						>
							<Row align='bottom' style={{ height: '100%' }}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<img
										onClick={() => navigateToShopCategory(4)}
										src={productsSectionImage1}
										className='products-section-image'
									></img>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Row align='bottom'>
										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Row justify='center'>
												<div className='products-section-title'>ZESTAWY STARTOWE</div>
											</Row>
											<Row justify='center'>
												<div className='products-section-divider'></div>
											</Row>
											<Row>
												<div
													onClick={() => navigateToShopCategory(4)}
													className='products-section-button'
												>
													Zobacz
												</div>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>

						<Col
							xs={22}
							sm={20}
							md={10}
							lg={5}
							xl={5}
							className='products-section-column column-2'
							data-aos='fade-up'
						>
							<Row align='bottom' style={{ height: '100%' }}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<img
										onClick={() => navigateToShopCategory(5)}
										src={productsSectionImage2}
										className='products-section-image'
									></img>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Row align='bottom'>
										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Row justify='center'>
												<div className='products-section-title'>LASH FILLER</div>
											</Row>
											<Row justify='center'>
												<div className='products-section-divider'></div>
											</Row>
											<Row>
												<div
													onClick={() => navigateToShopCategory(5)}
													className='products-section-button'
												>
													Zobacz
												</div>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>

						<Col
							xs={22}
							sm={20}
							md={10}
							lg={5}
							xl={5}
							className='products-section-column column-3'
							data-aos='fade-up'
						>
							<Row align='bottom' style={{ height: '100%' }}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<img
										onClick={() => navigateToShopCategory(2)}
										src={productsSectionImage3}
										className='products-section-image'
									></img>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Row align='bottom'>
										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Row justify='center'>
												<div className='products-section-title'>BROW BOMBER 3</div>
											</Row>
											<Row justify='center'>
												<div className='products-section-divider'></div>
											</Row>
											<Row>
												<div
													onClick={() => navigateToShopCategory(2)}
													className='products-section-button'
												>
													Zobacz
												</div>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>

						<Col
							xs={22}
							sm={20}
							md={10}
							lg={5}
							xl={5}
							className='products-section-column column-7'
							data-aos='fade-up'
						>
							<Row align='bottom' style={{ height: '100%' }}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<img
										onClick={() => navigateToShopCategory(6)}
										src={productsSectionImage4}
										className='products-section-image'
									></img>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Row align='bottom'>
										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Row justify='center'>
												<div className='products-section-title'>FORMY SILIKONOWE</div>
											</Row>
											<Row justify='center'>
												<div className='products-section-divider'></div>
											</Row>
											<Row>
												<div
													onClick={() => navigateToShopCategory(6)}
													className='products-section-button'
												>
													Zobacz
												</div>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row justify='center' className='products-section-bottom-row'>
						<Col
							xs={22}
							sm={20}
							md={10}
							lg={5}
							xl={5}
							className='products-section-column column-4'
							data-aos='fade-up'
						>
							<Row align='bottom' style={{ height: '100%' }}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<img
										onClick={() => navigateToShopCategory(7)}
										src={productsSectionImage5}
										className='products-section-image'
									></img>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Row align='bottom'>
										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Row justify='center'>
												<div className='products-section-title'>KOLORYZACJA</div>
											</Row>
											<Row justify='center'>
												<div className='products-section-divider'></div>
											</Row>
											<Row>
												<div
													onClick={() => navigateToShopCategory(7)}
													className='products-section-button'
												>
													Zobacz
												</div>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>

						<Col
							xs={22}
							sm={20}
							md={10}
							lg={5}
							xl={5}
							className='products-section-column column-5'
							data-aos='fade-up'
						>
							<Row align='bottom' style={{ height: '100%' }}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<img
										onClick={() => navigateToShopCategory(11)}
										src={productsSectionImage6}
										className='products-section-image'
									></img>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Row align='bottom'>
										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Row justify='center'>
												<div className='products-section-title'>AKCESORIA</div>
											</Row>
											<Row justify='center'>
												<div className='products-section-divider'></div>
											</Row>
											<Row>
												<div
													onClick={() => navigateToShopCategory(11)}
													className='products-section-button'
												>
													Zobacz
												</div>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>

						<Col
							xs={22}
							sm={20}
							md={10}
							lg={5}
							xl={5}
							className='products-section-column column-6'
							data-aos='fade-up'
						>
							<Row align='bottom' style={{ height: '100%' }}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<img
										onClick={() => navigateToShopCategory(8)}
										src={productsSectionImage7}
										className='products-section-image'
									></img>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Row align='bottom'>
										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Row justify='center'>
												<div className='products-section-title'>PIELĘGNACJA</div>
											</Row>
											<Row justify='center'>
												<div className='products-section-divider'></div>
											</Row>
											<Row>
												<div
													onClick={() => navigateToShopCategory(8)}
													className='products-section-button'
												>
													Zobacz
												</div>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>

						<Col
							xs={22}
							sm={20}
							md={10}
							lg={5}
							xl={5}
							className='products-section-column column-8'
							data-aos='fade-up'
						>
							<Row align='bottom' style={{ height: '100%' }}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<img
										onClick={() => navigateToShopCategory(1)}
										src={productsSectionImage8}
										className='products-section-image'
									></img>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Row align='bottom'>
										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Row justify='center'>
												<div className='products-section-title'>MATERIAŁY PROMOCYJNE</div>
											</Row>
											<Row justify='center'>
												<div className='products-section-divider'></div>
											</Row>
											<Row>
												<div
													onClick={() => navigateToShopCategory(1)}
													className='products-section-button'
												>
													Zobacz
												</div>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row justify='center' align='middle' className='about-section'>
				<Col xs={22} sm={20} md={18} lg={10} xl={10} data-aos='fade-right'>
					<Row justify='center'>
						<img src={aboutSectionImage1} className='about-section-image'></img>
					</Row>
				</Col>
				<Col xs={22} sm={20} md={18} lg={10} xl={10} data-aos='fade-left'>
					<div className='about-section-title'>Poznaj markę InLei</div>
					<div className='about-section-description'>Marka InLei® to gwarancja wysokiej jakości!</div>

					<div className='about-section-divider'></div>
					<div className='about-section-quote'>
						Od ogólnej koncepcji, opracowania receptury, doboru surowców, grafiki, pakowania, testowania i
						certyfikacji, wszystkie produkty InLei® w całości produkowane są we Włoszech. Tworzymy linię
						profesjonalnych produktów z dbałością o każdy szczegół, aby sprostać oczekiwaniom nawet
						najbardziej wymagających klientów.
					</div>
					<div className='about-section-read-more'>czytaj więcej</div>
				</Col>
				<Row>
					<Row justify='center' className='about-section-bottom-row'>
						<Col xs={22} sm={20} md={18} lg={20} xl={20} data-aos='fade-up'>
							<Row>
								<Col xs={24} sm={24} md={24} lg={8} xl={8} className='about-section-items-column'>
									<Row>
										<Col xs={8} sm={8} md={8} lg={8} xl={8}>
											<Row justify='center' align='middle'>
												<img
													src={aboutSectionImage2}
													className='about-section-items-image'
												></img>
											</Row>
										</Col>
										<Col xs={16} sm={16} md={16} lg={16} xl={16}>
											<Row className='about-section-items-title'>W pełni włoska produkcja</Row>
											<Row className='about-section-items-description'>
												zgodna z rygorystycznymi procedurami i kontrolami europejskimi, aby
												dotrzeć na rynek z bezpiecznym i wysokiej jakości produktem.
											</Row>
										</Col>
									</Row>
								</Col>

								<Col xs={24} sm={24} md={24} lg={8} xl={8} className='about-section-items-column'>
									<Row>
										<Col xs={8} sm={8} md={8} lg={8} xl={8}>
											<Row justify='center' align='middle'>
												<img
													src={aboutSectionImage3}
													className='about-section-items-image'
												></img>
											</Row>
										</Col>
										<Col xs={16} sm={16} md={16} lg={16} xl={16}>
											<Row className='about-section-items-title'>InLei Family</Row>
											<Row className='about-section-items-description'>
												Naszą markę znajdziesz już w 31 krajach na całym świecie, ściśle
												współpracujemy z naszymi przedstawicielami, aby doskonalić szkolenia i
												produkty InLei®
											</Row>
										</Col>
									</Row>
								</Col>

								<Col xs={24} sm={24} md={24} lg={8} xl={8} className='about-section-items-column'>
									<Row>
										<Col xs={8} sm={8} md={8} lg={8} xl={8}>
											<Row justify='center' align='middle'>
												<img
													src={aboutSectionImage4}
													className='about-section-items-image'
												></img>
											</Row>
										</Col>
										<Col xs={16} sm={16} md={16} lg={16} xl={16}>
											<Row className='about-section-items-title'>
												Nie testowane na zwierzętach
											</Row>
											<Row className='about-section-items-description'>
												żaden z naszych produktów nie jest testowany na zwierzętach!
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Row>
			</Row>

			<Row justify='center' align='middle' className='about-product-section'>
				<Col xs={22} sm={20} md={18} lg={12} xl={12} data-aos='fade-right'>
					<div className='about-product-section-title'>Dlaczego wybrać nasz produkt?</div>
					<Row align='middle' justify='center' className='about-product-top-row'>
						<Col xs={24} sm={24} md={24} lg={12} xl={12} className='about-product-section-items-column'>
							<Row>
								<Col xs={7} sm={7} md={7} lg={7} xl={7}>
									<Row justify='center' align='middle'>
										<img
											src={aboutProductSection2}
											className='about-product-section-section-items-image'
										></img>
									</Row>
								</Col>
								<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
								<Col xs={16} sm={16} md={16} lg={16} xl={16}>
									<Row className='about-product-section-items-title'>Udowodnione działanie</Row>
									<Row className='about-product-section-items-description'>
										Potwierdzone przez Instytut Badawczy w Mediolanie. Zwiększa gęstość i średnicę
										rzęs oraz zapewnia maksymalne odżywienie!
									</Row>
								</Col>
							</Row>
						</Col>

						<Col xs={24} sm={24} md={24} lg={12} xl={12} className='about-product-section-items-column'>
							<Row>
								<Col xs={7} sm={7} md={7} lg={7} xl={7}>
									<Row justify='center' align='middle'>
										<img
											src={aboutProductSection3}
											className='about-product-section-section-items-image'
										></img>
									</Row>
								</Col>
								<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
								<Col xs={16} sm={16} md={16} lg={16} xl={16}>
									<Row className='about-product-section-items-title'>100% MADE IN ITALY</Row>
									<Row className='about-product-section-items-description'>
										Staranny dobór surowców z certyfikowanych źródeł jest połączony z produkcją,
										która musi przejść bardzo surową kontrolę jakości . Każdy produkt posiada
										certyfikat GMP.
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row align='middle' justify='center' className='about-product-bottom-row'>
						<Col xs={24} sm={24} md={24} lg={12} xl={12} className='about-product-section-items-column'>
							<Row>
								<Col xs={7} sm={7} md={7} lg={7} xl={7}>
									<Row justify='center' align='middle'>
										<img
											src={aboutProductSection4}
											className='about-product-section-section-items-image'
										></img>
									</Row>
								</Col>
								<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
								<Col xs={16} sm={16} md={16} lg={16} xl={16}>
									<Row className='about-product-section-items-title'>Testowany klinicznie</Row>
									<Row className='about-product-section-items-description'>
										Zabieg w pełni bezpieczny. Preparaty przeszły wszystkie niezbędne testy zgodnie
										z europejskimi normami jakości
									</Row>
								</Col>
							</Row>
						</Col>

						<Col xs={24} sm={24} md={24} lg={12} xl={12} className='about-product-section-items-column'>
							<Row>
								<Col xs={7} sm={7} md={7} lg={7} xl={7}>
									<Row justify='center' align='middle'>
										<img
											src={aboutProductSection5}
											className='about-product-section-section-items-image'
										></img>
									</Row>
								</Col>
								<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
								<Col xs={16} sm={16} md={16} lg={16} xl={16}>
									<Row className='about-product-section-items-title'>Silikon platynowy </Row>
									<Row className='about-product-section-items-description'>
										Jedyne formy, które można sterylizować w autoklawie, wykonane z najwyższej
										jakości silikonu- miękkiego i lekkiego, aby zapewnić idealne dopasowanie do
										powieki i komfort użytkowania
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>

				<Col xs={22} sm={20} md={18} lg={8} xl={8} data-aos='fade-left'>
					<Row justify='center' align='bottom'>
						<img src={aboutProductSection1} className='landing-section-image'></img>
					</Row>
				</Col>
			</Row>

			<Row justify='center' align='middle' className='trainings-section'>
				<Row justify='center' align='middle' className='trainings-section-row-top'>
					<Col xs={22} sm={20} md={18} lg={10} xl={10} data-aos='fade-left'>
						<Row justify='center' className='trainings-section-row-top-title'>
							SZKOLENIA
						</Row>
						<Row>
							<img src={trainingsSectionRowTopImage} className='trainings-section-row-top-image'></img>
						</Row>
						<Row justify='center' className='trainings-section-row-top-description'>
							Wiedza na szkoleniach InLei przekazywana jest przez wykwalifikowaną kadrę specjalistów,
							zgodnie z najwyższymi standardami marki. Jeśli chcesz być na bieżąco z nowościami, pogłębić
							swoją wiedzę z zakresu laminacji i poznać wszystkie niuanse pracy z produktami InLei to
							zapraszamy do zapoznania się z naszą ofertą szkoleniową.
						</Row>
					</Col>
				</Row>

				<Row justify='center' align='middle' className='trainings-section-row-bottom'>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Row justify='center'>
							<Col
								xs={22}
								sm={20}
								md={18}
								lg={6}
								xl={6}
								className='trainings-section-column column-1'
								data-aos='fade-up'
							>
								<Row justify='center'>
									<img
										src={trainingsSectionRowBottomImage1}
										className='trainings-section-row-bottom-image'
									></img>
								</Row>
								<div className='trainings-section-row-bottom-title'>Obszerna wiedza</div>
								<div className='trainings-section-row-bottom-description'>
									Którą zdobędziesz dzięki naszym profesjonalnym skryptom, w których znajdziesz zawsze
									aktualną wiedzę oraz mnóstwo dodatkowych wskazówek, które podniosą poziom Twojej
									pracy
								</div>
							</Col>

							<Col
								xs={22}
								sm={20}
								md={18}
								lg={6}
								xl={6}
								className='trainings-section-column column-2'
								data-aos='fade-up'
							>
								<Row justify='center'>
									<img
										src={trainingsSectionRowBottomImage2}
										className='trainings-section-row-bottom-image'
									></img>
								</Row>
								<div className='trainings-section-row-bottom-title'>Wykwalifikowani trenerzy</div>
								<div className='trainings-section-row-bottom-description'>
									Naszą kadrę instruktorską tworzą wysokiej klasy specjaliści w branży lash&brow
								</div>
							</Col>

							<Col
								xs={22}
								sm={20}
								md={18}
								lg={6}
								xl={6}
								className='trainings-section-column column-3'
								data-aos='fade-up'
							>
								<Row justify='center'>
									<img
										src={trainingsSectionRowBottomImage3}
										className='trainings-section-row-bottom-image'
									></img>
								</Row>

								<div className='trainings-section-row-bottom-title'>Gwarantowany rezultat</div>
								<div className='trainings-section-row-bottom-description'>
									Na naszych szkoleniach kursanci otrzymują gruntowną wiedzę i są gotowi do
									wykonywania samodzielnych zabiegów i podjęcia nowych wyzwań!
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Row>

			<Row justify='center' className='map-section-current-trainings'>
				<div onClick={navigateToTrainings} className='map-section-current-trainings-button'>
					Zapisz się na szkolenie
				</div>
			</Row>

			<InstructorsMap instructors={instructorsData} />

			{/* <Row justify='center' className='map-section-current-trainings'>
				<div onClick={navigateToSHhop} className='map-section-current-trainings-button'>Zapisz się na szkolenie</div>
			</Row> */}

			<Row justify='center' align='middle' className='trainings-enrolment-section'>
				<Col xs={22} sm={22} md={20} lg={20} xl={20} data-aos='fade-up'>
					<Row justify='center' align='middle' className='trainings-enrolment-section-row-top'>
						<Col xs={24} sm={24} md={24} lg={24} xl={24} data-aos='fade-up'>
							<Row justify='center' className='trainings-enrolment-section-row-top-title'>
								<img
									src={trainingsEnrolmentSectionRowTopImage}
									className='trainings-enrolment-section-row-top-image'
								></img>
							</Row>
							<Row justify='center'>
								<Col xs={2} sm={2} md={2} lg={2} xl={2}>
									<Row
										justify='end'
										align='middle'
										className='trainings-enrolment-section-row-top-row-divider'
									>
										<div className='trainings-enrolment-section-row-top-divider'></div>
									</Row>
								</Col>
								<Col xs={20} sm={20} md={14} lg={14} xl={14}>
									<Row
										justify='center'
										align='middle'
										className='trainings-enrolment-section-row-top-title'
									>
										Szkolenia online
									</Row>
								</Col>
								<Col xs={2} sm={2} md={2} lg={2} xl={2}>
									<Row
										justify='start'
										align='middle'
										className='trainings-enrolment-section-row-top-row-divider'
									>
										<div className='trainings-enrolment-section-row-top-divider'></div>
									</Row>
								</Col>
							</Row>
							<Row justify='center' className='trainings-enrolment-section-row-top-description'>
								Możesz pogłębić swoją wiedzę również na szkoleniach w formie online, które obejmują
								wiedzę teoretyczną oraz pracę pokazową na modelce. Szczegółowe informacje i dokładny
								opis programu szkoleń znajdziesz poniżej.
							</Row>
						</Col>
					</Row>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24} data-aos='fade-up'>
					<Row justify='center' align='middle' className='trainings-enrolment-section-row-bottom'>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Row justify='center'>
								<Col
									xs={22}
									sm={20}
									md={18}
									lg={6}
									xl={6}
									className='trainings-enrolment-section-column column-1'
									data-aos='fade-up'
								>
									<Row justify='center'>
										<img
											src={trainingsEnrolmentSectionRowBottomImage1}
											className='trainings-enrolment-section-row-bottom-image'
										></img>
									</Row>
									<div className='trainings-enrolment-section-row-bottom-title'>Brow Bomber</div>
									<div className='trainings-enrolment-section-row-bottom-description'>
										Szkolenie obejmujące wiedzę z zakresu zabiegu laminacji brwi
									</div>
									<div
										className='trainings-enrolment-section-button'
										onClick={() => history.push('/brow-bomber')}
									>
										Więcej
									</div>
								</Col>
								<Col xs={0} sm={0} md={0} lg={1} xl={1}></Col>
								<Col
									xs={22}
									sm={20}
									md={18}
									lg={6}
									xl={6}
									className='trainings-enrolment-section-column column-2'
									data-aos='fade-up'
								>
									<Row justify='center'>
										<img
											src={trainingsEnrolmentSectionRowBottomImage2}
											className='trainings-enrolment-section-row-bottom-image'
										></img>
									</Row>
									<div className='trainings-enrolment-section-row-bottom-title'>Lash Filler</div>
									<div className='trainings-enrolment-section-row-bottom-description'>
										Szkolenie obejmujące wiedzę z zakresu laminacji rzęs
									</div>
									<div
										className='trainings-enrolment-section-button'
										onClick={() => history.push('/lash-filler')}
									>
										Więcej
									</div>
								</Col>
								<Col xs={0} sm={0} md={0} lg={1} xl={1}></Col>
								<Col
									xs={22}
									sm={20}
									md={18}
									lg={6}
									xl={6}
									className='trainings-enrolment-section-column column-3'
									data-aos='fade-up'
								>
									<Row justify='center'>
										<img
											src={trainingsEnrolmentSectionRowBottomImage3}
											className='trainings-enrolment-section-row-bottom-image'
										></img>
									</Row>

									<div className='trainings-enrolment-section-row-bottom-title'>
										Szkolenie produktowe{' '}
									</div>
									<div className='trainings-enrolment-section-row-bottom-description'>
										Szczegółowa wiedza dotycząca produktów InLei oraz ich działania
									</div>
									<div
										className='trainings-enrolment-section-button'
										onClick={() => history.push('/product-trainings')}
									>
										Więcej
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row justify='center' align='middle' className='new-products-section'>
				<Col xs={22} sm={22} md={20} lg={20} xl={20} data-aos='fade-up'>
					<Row justify='center' align='middle' className='new-products-section-row-top'>
						<Col xs={24} sm={24} md={24} lg={24} xl={24} data-aos='fade-up'>
							<Row justify='center'>
								<Col xs={2} sm={2} md={2} lg={2} xl={2}>
									<Row
										justify='end'
										align='middle'
										className='new-products-section-row-top-row-divider'
									>
										<div className='new-products-section-row-top-divider'></div>
									</Row>
								</Col>
								<Col xs={10} sm={6} md={6} lg={6} xl={6}>
									<Row justify='center' align='middle' className='new-products-section-row-top-title'>
										Nowości
									</Row>
								</Col>
								<Col xs={2} sm={2} md={2} lg={2} xl={2}>
									<Row
										justify='start'
										align='middle'
										className='new-products-section-row-top-row-divider'
									>
										<div className='new-products-section-row-top-divider'></div>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>

				<Col xs={22} sm={22} md={20} lg={20} xl={20} data-aos='fade-up'>
					<Row justify='center' className='new-products-items-row'>
						<Col xs={22} sm={22} md={22} lg={22} xl={22}>
							<Row>
								<Col onClick={() => navigateToShopCategory(0)} xs={24} sm={24} md={8} lg={8} xl={8}>
									<Row justify='center'>
										<img
											onClick={() => navigateToShopCategory(0)}
											src={newProductsSectionImage2}
											className='new-products-section-image'
										></img>
									</Row>
									<Row justify='center' className='new-products-section-name'>
										LOREM IPSUM
									</Row>
									<Row justify='center' className='new-products-section-price'>
										55,99 zł
									</Row>
								</Col>

								<Col onClick={() => navigateToShopCategory(0)} xs={24} sm={24} md={8} lg={8} xl={8}>
									<Row justify='center'>
										<img
											onClick={() => navigateToShopCategory(0)}
											src={newProductsSectionImage1}
											className='new-products-section-image'
										></img>
									</Row>
									<Row justify='center' className='new-products-section-name'>
										LOREM IPSUM
									</Row>
									<Row justify='center' className='new-products-section-price'>
										55,99 zł
									</Row>
								</Col>

								<Col onClick={() => navigateToShopCategory(0)} xs={24} sm={24} md={8} lg={8} xl={8}>
									<Row justify='center'>
										<img
											onClick={() => navigateToShopCategory(0)}
											src={newProductsSectionImage3}
											className='new-products-section-image'
										></img>
									</Row>
									<Row justify='center' className='new-products-section-name'>
										LOREM IPSUM
									</Row>
									<Row justify='center' className='new-products-section-price'>
										55,99 zł
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row justify='center' align='middle' className='special-offer-section'>
				<Col xs={22} sm={22} md={20} lg={20} xl={20} data-aos='fade-up'>
					<Row justify='center' align='middle' className='special-offer-section-row-top'>
						<Col xs={24} sm={24} md={24} lg={24} xl={24} data-aos='fade-up'>
							<Row justify='center'>
								<Col xs={2} sm={2} md={2} lg={2} xl={2}>
									<Row
										justify='end'
										align='middle'
										className='special-offer-section-row-top-row-divider'
									>
										<div className='special-offer-section-row-top-divider'></div>
									</Row>
								</Col>
								<Col xs={10} sm={6} md={6} lg={6} xl={6}>
									<Row
										justify='center'
										align='middle'
										className='special-offer-section-row-top-title'
									>
										Oferta specialna
									</Row>
								</Col>
								<Col xs={2} sm={2} md={2} lg={2} xl={2}>
									<Row
										justify='start'
										align='middle'
										className='special-offer-section-row-top-row-divider'
									>
										<div className='special-offer-section-row-top-divider'></div>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>

				<Col xs={22} sm={22} md={20} lg={20} xl={20} data-aos='fade-up'>
					<Row justify='center' className='special-offer-items-row'>
						<Col xs={22} sm={22} md={22} lg={22} xl={22}>
							<Row justify='center'>
								<Col xs={24} sm={24} md={7} lg={7} xl={7} className='special-offer-product-column'>
									<Row justify='center'>
										<img
											onClick={() => navigateToShopCategory(0)}
											src={specialOfferSectionImage1}
											className='special-offer-section-image'
										></img>
									</Row>
									<Row justify='center' className='special-offer-section-name'>
										LOREM IPSUM
									</Row>
									<Row justify='center' className='special-offer-section-price'>
										23,99 zł
									</Row>
									<Row justify='center'>
										<div
											onClick={() => navigateToShopCategory(0)}
											className='special-offer-section-button'
										>
											Zobacz
										</div>
									</Row>
								</Col>
								<Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
								<Col xs={24} sm={24} md={7} lg={7} xl={7} className='special-offer-product-column'>
									<Row justify='center'>
										<img
											onClick={() => navigateToShopCategory(0)}
											src={specialOfferSectionImage2}
											className='special-offer-section-image'
										></img>
									</Row>
									<Row justify='center' className='special-offer-section-name'>
										LOREM IPSUM
									</Row>
									<Row justify='center' className='special-offer-section-price'>
										23,99 zł
									</Row>
									<Row justify='center'>
										<div
											onClick={() => navigateToShopCategory(0)}
											className='special-offer-section-button'
										>
											Zobacz
										</div>
									</Row>
								</Col>
								<Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
								<Col xs={24} sm={24} md={7} lg={7} xl={7} className='special-offer-product-column'>
									<Row justify='center'>
										<img
											onClick={() => navigateToShopCategory(0)}
											src={specialOfferSectionImage3}
											className='special-offer-section-image'
										></img>
									</Row>
									<Row justify='center' className='special-offer-section-name'>
										LOREM IPSUM
									</Row>
									<Row justify='center' className='special-offer-section-price'>
										23,99 zł
									</Row>
									<Row justify='center'>
										<div
											onClick={() => navigateToShopCategory(0)}
											className='special-offer-section-button'
										>
											Zobacz
										</div>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row justify='center' align='middle' className='footer-section'>
				<Col xs={22} sm={22} md={20} lg={20} xl={14} data-aos='fade-up'>
					<Row justify='center'>
						<Col xs={22} sm={22} md={8} lg={6} xl={6} className='footer-section-column'>
							<Row className='footer-section-item' justify='center'>
								Pomocne linki
							</Row>
							<Row className='footer-section-item' justify='center'>
								Gwaracja jakości
							</Row>
							<Row className='footer-section-item' justify='center'>
								Darmowa wysyłka
							</Row>
						</Col>

						<Col xs={22} sm={22} md={8} lg={6} xl={6} className='footer-section-column'>
							<Row className='footer-section-item' justify='center'>
								Adres
							</Row>
							<Row className='footer-section-item' justify='center'>
								ul. Armii Krajowej
							</Row>
							<Row className='footer-section-item' justify='center'>
								12/4
							</Row>
							<Row className='footer-section-item' justify='center'>
								50-541 Wrocław
							</Row>
						</Col>

						<Col xs={22} sm={22} md={8} lg={6} xl={6} className='footer-section-column'>
							<Row className='footer-section-item' justify='center'>
								Obsługa Klienta
							</Row>
							<Row className='footer-section-item' justify='center'>
								531-223-533
							</Row>
							<Row className='footer-section-item' justify='center'>
								sklep@inlei.pl
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default HomePageContainer;
