import React, { useEffect } from 'react';

import { Row, Col } from 'antd';
import landingSectionImage from './assets/7pielegnacja.png';

import AOS from 'aos';
import "aos/dist/aos.css";
import './ProductTrainingsPageContainer.less';

const BrowBomberPageContainer: React.FC<{}> = () => {

	useEffect(() => {
		AOS.init({
			duration: 2600,
			once: true,
		});
		window.addEventListener('load', AOS.refresh);
	}, []);
	
	return (
		<div className='product-trainings-page-container'>

			<Row justify='center'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} data-aos="fade-in">
					<Row className='product-trainings-page-container-title' justify='center'>
						Produktowe InLei
					</Row>
					<Row className='product-trainings-page-container-title-divider' justify='center'>
						<span></span>
					</Row>
				</Col>
			</Row>
			<Row justify='center'>
				<Col xs={24} sm={24} md={10} lg={10} xl={10} data-aos="fade-right">
				<Row align='middle' style={{height: '100%'}}>

					<div className='product-trainings-page-container-subtitle'>
						Szkolenie produktowe firmy InLei jest to autorskie szkolenie Sashy Krytsyny oraz pierwsze w Polsce! 
						<br></br>
						<br></br>
						Szkolenie jest skierowane dla osób, które ukończyły już szkolenia w zakresie liftingu / laminowania / botoxu rzęs z innych firm i chcą poznać produkty InLei, aby móc wykonywać innowacyjny włoski zabieg LASH FILLER, jak również dla osób, które jeszcze się nie zdecydowały na szkolenie, aczkolwiek chcą poznać na czym polega zabieg liftingu/laminowania rzęs InLei LASH FILLER.
						<br></br>
						<br></br>
						Nie jest to pełne szkolenie! 
						Jest to szkolenie, które obejmuje wiedzę o produktach oraz jak je stosować!
						<div>
							<a target='_blank' href='https://sash.pl/szkolenie-on-line/zakup-dostepu-do-szkolenia-on-line-produktowe-inlei/'>
								<div className='product-trainings-page-container-button'>Kup dostęp</div>
							</a>
						</div>
					</div>
				</Row>

				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} data-aos="fade-left">
					<Row justify='center'>
						<img src={landingSectionImage} className='landing-section-image'></img>
					</Row>

				</Col>
			</Row>

			<Row className='product-trainings-page-container-details-row' justify='center'>
				<Col xs={24} sm={24} md={10} lg={10} xl={10} data-aos="fade-in">
					<Row className='product-trainings-page-container-description-title'>
						Zagadnienia
					</Row>
					<Row className='product-trainings-page-container-description-divider'>

					</Row>
					<Row>
													<div className="product-trainings-page-container-description-element">
														1. Historia powstania zabiegu
													</div>
													<div className="product-trainings-page-container-description-element">
														2. Na czym polega zabieg liftingu / laminowania rzęs / LASH FILLER
													</div>
													<div className="product-trainings-page-container-description-element">
														3. Dlaczego zabieg jest INNOWACJĄ
													</div>
													<div className="product-trainings-page-container-description-element">
														4. Chemiczne działanie produktów na rzęsy naturalne
													</div>
													<div className="product-trainings-page-container-description-element">
														5. Wszystko o zabiegu „Włoska regeneracja LASH FILLER”
													</div>
													<div className="product-trainings-page-container-description-element">
														6. Co odróżnia LASH FILLER od standardowych zabiegów znanych nam na rynku
													</div>
													<div className="product-trainings-page-container-description-element">
														7. Działanie henny, która została zrobiona według nowoczesnej opatentowanej technologii, której nie ma nikt na świecie
													</div>											
													<div className="product-trainings-page-container-description-element">
														8. Prezentacja badań klinicznych przeprowadzonych w Mediolanie pod względem skuteczności pogrubienia rzęs średnio o 24%
													</div>
													<div className="product-trainings-page-container-description-element">
														9. Prezentacja wyników badań klinicznych, które udowodniają pogrubienie średnicy rzęs oraz trwały skręt
													</div>
													<div className="product-trainings-page-container-description-element">
														10. Jak działa zabieg LASH FILLER na rzęsy. Prezentacja rzęs pod mikroskopem
													</div>
													<div className="product-trainings-page-container-description-element">
														11. Omówienie innowacyjnych form silikonowych
													</div>
													<div className="product-trainings-page-container-description-element">
														12. Pielęgnacja rzęs klientki przed i po zabiegu
													</div>
													<div className="product-trainings-page-container-description-element">
														13. Przeciwwskazania
													</div>
													<div className="product-trainings-page-container-description-element">
														14. Sterylizacja narzędzi oraz dezynfekcja stanowiska pracy
													</div>
													<div className="product-trainings-page-container-description-element">
														15. Prezentacja filmowa wykonania zabiegu oraz korzystania z całej linii produktów InLei
													</div>
													<div className="product-trainings-page-container-description-element">
														16. Omówienie krok po kroku zabiegu
													</div>
													<div className="product-trainings-page-container-description-element">
														17. Odpowiedzi na najczęściej zadawane pytania
													</div>
													<div className="product-trainings-page-container-description-element">
														18. „Ile utrzymuje się efekt?”,
													</div>
													<div className="product-trainings-page-container-description-element">
														19. „Dlaczego skręt się nie udał?”,
													</div>
													<div className="product-trainings-page-container-description-element">
														20. „W jaki sposób możemy zdecydować, czy klientka ma spalone rzęsy i co wtedy zrobić?” itd.
													</div>	

					</Row>
				</Col>
			</Row>

			<Row justify='center' data-aos="fade-in">
				<a target='_blank' href='https://sash.pl/szkolenie-on-line/zakup-dostepu-do-szkolenia-on-line-produktowe-inlei/'>
					<div className='product-trainings-page-container-button'>Kup dostęp</div>
				</a>
			</Row>

			<Row className='product-trainings-page-container-footer-row' data-aos="fade-in">
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Row className='product-trainings-page-container-footer-title'>
						Czas trwania
					</Row>
					<Row className='product-trainings-page-container-footer-divider'>

					</Row>
					<Row>
						<div className='product-trainings-page-container-footer-element'>
							- 1h 38min
						</div>
					</Row>
				</Col>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Row className='product-trainings-page-container-footer-title'>
						Certyfikaty
					</Row>
					<Row className='product-trainings-page-container-footer-divider'>

					</Row>
					<Row>
						<div className='product-trainings-page-container-footer-element'>
							- InLei
						</div>
					</Row>
				</Col>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Row className='product-trainings-page-container-footer-title'>
						Elementy dodatkowe
					</Row>
					<Row className='product-trainings-page-container-footer-divider'>

					</Row>
					<Row>
						<div className='product-trainings-page-container-footer-element'>
							- Jednorazowa zniżka 10% i stała 5% na produkty InLei.pl
						</div>
						<div className='product-trainings-page-container-footer-element'>
							- Stała 5% na produkty w tym także InLei na LightBrow.pl
						</div>
						<div className='product-trainings-page-container-footer-element'>
							- Zniżka na szkolenia on-line 10% na Sash.pl
						</div>
						<div className='product-trainings-page-container-footer-element'>
							- Wystawiamy Faktury VAT
						</div>
					</Row>
				</Col>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Row className='product-trainings-page-container-footer-title'>
						Zaplecze techniczne
					</Row>
					<Row className='product-trainings-page-container-footer-divider'>

					</Row>
					<Row>
						<div className='product-trainings-page-container-footer-element'>
							- Nielimitowany dostęp do szkolenia przez 30 dni
						</div>
						<div className='product-trainings-page-container-footer-element'>
							- Skrypt w wersji PDF (do druku)
						</div>
						<div className='product-trainings-page-container-footer-element'>
							- Salonowa ankieta klienta
						</div>
					</Row>
				</Col>
			</Row>




		</div>
	);
};

export default BrowBomberPageContainer;
