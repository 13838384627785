import { Instructor } from '../interfaces/instructor';

export const instructorsData: Instructor[] = [
	{
		id: 0,
		firstName: 'Anna',
		lastName: 'Krukowska',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Łódź',
		imageUrl: './assets/1.1.png',
		latitudePercentage: 0,
		lonitudePercentage: 6,
	},
	{
		id: 1,
		firstName: 'Olga',
		lastName: 'Klucznik',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Zielona Góra',
		imageUrl: './assets/3.1.png',
		latitudePercentage: -5,
		lonitudePercentage: -28,
	},
	{
		id: 2,
		firstName: 'Marzena',
		lastName: 'Grochala',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Sosnowiec',
		imageUrl: './assets/4.1.png',
		latitudePercentage: 20,
		lonitudePercentage: 0,
	},
	{
		id: 3,
		firstName: 'Jagoda',
		lastName: 'Jankowska',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Piła',
		imageUrl: './assets/5.1.png',
		latitudePercentage: -22,
		lonitudePercentage: -21,
	},
	{
		id: 4,
		firstName: 'Justyna',
		lastName: 'Iliaszuk',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Białystok',
		imageUrl: './assets/6.1.png',
		latitudePercentage: -21,
		lonitudePercentage: 38,
	},
	{
		id: 5,
		firstName: 'Maria',
		lastName: 'Wyszyńska',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Rzeszów',
		imageUrl: './assets/7.1.png',
		latitudePercentage: 29,
		lonitudePercentage: 27,
	},
	{
		id: 6,
		firstName: 'Magdalena',
		lastName: 'Szczygielska',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Kielce',
		imageUrl: './assets/8.1.png',
		latitudePercentage: 13,
		lonitudePercentage: 19,
	},
	{
		id: 7,
		firstName: 'Aleksandra',
		lastName: 'Kopowska',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Trójmiasto',
		imageUrl: './assets/9.1.png',
		latitudePercentage: -40,
		lonitudePercentage: -2,
	},
	{
		id: 8,
		firstName: 'Elżbieta',
		lastName: 'Chwalisz',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Gorzów Wielkopolski',
		imageUrl: './assets/10.1.png',
		latitudePercentage: -17,
		lonitudePercentage: -31,
	},
	{
		id: 9,
		firstName: 'Martyna',
		lastName: 'Jasińska',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Warszawa',
		imageUrl: './assets/11.1.png',
		latitudePercentage: -9,
		lonitudePercentage: 23,
	},
	{
		id: 10,
		firstName: 'Olimpia',
		lastName: 'Babiarz',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Wałbrzych',
		imageUrl: './assets/12.1.png',
		latitudePercentage: 15,
		lonitudePercentage: -26,
	},
	{
		id: 11,
		firstName: 'Joanna',
		lastName: 'Wiedeńska',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Lublin',
		imageUrl: './assets/13.1.png',
		latitudePercentage: 12,
		lonitudePercentage: 35,
	},
	{
		id: 12,
		firstName: 'Weronika',
		lastName: 'Ptaszyńska',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Radzyń Podlaski',
		imageUrl: './assets/14.1.png',
		latitudePercentage: -5,
		lonitudePercentage: 35,
	},
	{
		id: 13,
		firstName: 'Sasha',
		lastName: 'Krystyna',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Radzyń Podlaski',
		imageUrl: './assets/15.1.png',
		latitudePercentage: 7,
		lonitudePercentage: -18,
	},
	{
		id: 14,
		firstName: 'Ola',
		lastName: 'Kardela-Sygo',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Radzyń Podlaski',
		imageUrl: './assets/16.1.png',
		latitudePercentage: 14,
		lonitudePercentage: -8,
	},
	{
		id: 15,
		firstName: 'Monika',
		lastName: 'Mroczka',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Radzyń Podlaski',
		imageUrl: './assets/17.1.png',
		latitudePercentage: -30,
		lonitudePercentage: 15,
	},
	{
		id: 16,
		firstName: 'Klaudia',
		lastName: 'Kasuła',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Radzyń Podlaski',
		imageUrl: './assets/18.1.png',
		latitudePercentage: -30,
		lonitudePercentage: -40,
	},
	{
		id: 17,
		firstName: 'Monika',
		lastName: 'Frandofert',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Radzyń Podlaski',
		imageUrl: './assets/19.1.png',
		latitudePercentage: 23,
		lonitudePercentage: 9,
	},
	{
		id: 18,
		firstName: 'Nicolette',
		lastName: 'Wudel',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
		city: 'Radzyń Podlaski',
		imageUrl: './assets/20.1.png',
		latitudePercentage: -35,
		lonitudePercentage: -28,
	},

];
