import { localStorageHelper } from 'App/common/utils/localStorageHelper';
import { Cart } from 'App/domain/entities/cart';
import { Restaurant } from 'App/domain/entities/restaurant';
import { UserAddress } from 'App/domain/entities/userAddress';
import { DeliveryMethodEnum } from 'App/domain/enums/deliveryMethodEnum';
import { PaymentMethodEnum } from 'App/domain/enums/paymentMethodEnum';
import StatusType from 'App/types/requestStatus';

const { INITIAL } = StatusType;

// todo przenieść to gdzie indziej
export interface AnonymousClientData {
	name: string;
	last_name: string;
	phone_number: string;
	email: string;
}

export interface NewOrderState {
	status: {
		addNewOrderStatus: StatusType;
	};
	cart: Cart;
	restaurant: Restaurant;
	userAddress: UserAddress;
	paymentMethodEnum: PaymentMethodEnum;
	deliveryMethodEnum: DeliveryMethodEnum;
	tableNumber: number;
	isAddressOnDeliveryZones: boolean;
	changingDeliveryOrAddress: boolean;
	anonymousClientData: AnonymousClientData;
}

export const newOrder_cartInitialState: Cart = {
	cart_products: [],
	delivery_cost_in_pln: 0,
	promotions: [],
};

export const newOrder_restaurantInitialState: Restaurant = {
	active_option_items: [],
	address: '',
	city: '',
	delivery_zones: [],
	description: '',
	id: 0,
	image: null,
	is_active: false,
	lat: 0,
	lng: 0,
	name: '',
	number_of_tables: 0,
	is_active_for_to_table_orders: false,
	time_open: null,
	products: [],
};

export const newOrderInitialStateLocalStorage: NewOrderState = {
	status: {
		addNewOrderStatus: INITIAL,
	},

	cart: localStorageHelper.getCart(),
	restaurant: localStorageHelper.getRestaurant(),
	userAddress: null,
	paymentMethodEnum: PaymentMethodEnum.fast_transfer,
	deliveryMethodEnum: DeliveryMethodEnum.self_pickup,
	tableNumber: 1,
	anonymousClientData: null,
	isAddressOnDeliveryZones: false,

	changingDeliveryOrAddress: false,
};
