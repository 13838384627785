import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authInitialState, AuthState } from './auth.state';
import { StatusType } from 'App/types/requestStatus';

const { FAILED, SUCCESS, LOADING } = StatusType;

const authSlice = createSlice({
	name: 'auth',
	initialState: authInitialState,
	reducers: {
		loginStart: (state: AuthState) => {
			state.status.login = LOADING;
			state.flags.login = true;
		},
		loginSuccess: (state: AuthState, action: PayloadAction<any>) => {
			state.status.login = SUCCESS;

			state.tokens = {
				token: action.payload.access_token,
				refreshToken: action.payload.refresh_token,
			};
			state.tokens.token = action.payload.access_token;
			state.tokens.refreshToken = action.payload.refresh_token;

			localStorage.setItem('refresh_token', action.payload.refresh_token);
		},
		loginFailure: (state: AuthState) => {
			state.status.login = FAILED;
		},
		loginFinish: (state: AuthState) => {
			state.flags.login = false;
		},

		registerStart: (state: AuthState) => {
			state.status.register = LOADING;
		},
		registerSuccess: (state: AuthState) => {
			state.status.register = SUCCESS;
		},
		registerFailure: (state: AuthState) => {
			state.status.register = FAILED;
		},

		logoutStart: (state: AuthState) => {
			state.status.logout = SUCCESS;
			state.tokens = { refreshToken: null, token: null };
		},

		refreshTokenStart: (state: AuthState) => {
			state.status.refreshToken = LOADING;
		},
		refreshTokenSuccess: (state: AuthState, action: PayloadAction<any>) => {
			state.tokens = {
				token: action.payload.access_token,
				refreshToken: action.payload.refresh_token,
			};
			state.tokens.token = action.payload.access_token;
			state.tokens.refreshToken = action.payload.refresh_token;
			localStorage.setItem('refresh_token', action.payload.refresh_token);

			state.status.refreshToken = SUCCESS;
		},
		refreshTokenFailure: (state: AuthState) => {
			state.status.refreshToken = FAILED;
		},

		appStarted: (state: AuthState) => {
			state.flags.appStarting = false;
		},

		cleanUpAuthStatusStart: (state: AuthState) => {
			state.status = authInitialState.status;
		},
	},
});

export default authSlice;

export const {
	loginStart,
	loginSuccess,
	loginFailure,
	loginFinish,

	registerStart,
	registerSuccess,
	registerFailure,

	refreshTokenStart,
	refreshTokenSuccess,
	refreshTokenFailure,

	appStarted,

	logoutStart,

	cleanUpAuthStatusStart,
} = authSlice.actions;
