import { Col, Row } from 'antd';
import React, { useEffect } from 'react';

import landingSectionImage from './assets/mapa (1).jpeg';

import productImage1 from './assets/4.png';
import productImage2 from './assets/5.png';
import productImage3 from './assets/6.png';
import footerImage from './assets/8.png';

import cardImage1 from './assets/1.png';
import cardImage2 from './assets/2.png';
import cardImage3 from './assets/3.png';
import { useMediaQuery } from 'react-responsive';
import AOS from 'aos';

import './AboutUsPageContainer.less';

const AboutUsPageContainer: React.FC<{}> = () => {
	const isMobile = useMediaQuery({ maxWidth: 768 });

	useEffect(() => {
		AOS.init({
			duration: 2600,
			once: true,
		});
		window.addEventListener('load', AOS.refresh);
	}, []);

	return (
		<div className='about-us-page-container'>
			<Row justify='center'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} data-aos='fade-in'>
					<Row className='about-us-page-container-title' justify='center'>
						O nas
					</Row>
					<Row className='about-us-page-container-title-divider' justify='center'>
						<span></span>
					</Row>
				</Col>
			</Row>

			<Row justify='center'>
				<Col xs={24} sm={24} md={16} lg={16} xl={16} data-aos='fade-left'>
					<Row justify='center'>
						<img src={landingSectionImage} className='landing-section-image'></img>
					</Row>
				</Col>
			</Row>

			<Row className='about-us-main-row' justify='center'>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} data-aos='fade-right'>
					<Row className='about-us-page-container-subtitle'>
						Produkty InLei® znajdziesz na całym świecie, ich wysoką jakość i bezpieczeństwo użytkowania
						pokochały już tysiące stylistek. W ciągu zaledwie kilku lat działalności nasza marka podbiła już
						ponad 31 krajów!
					</Row>
				</Col>
			</Row>

			<Row className='about-us-page-container-cards-section'>
				<Col xs={24} sm={24} md={8} lg={8} xl={8} data-aos='fade-in'>
					<Row justify='center' className='about-us-page-container-card-row'>
						<div className='about-us-page-container-card'>
							<Row justify='center'>
								<img src={cardImage1} className='about-us-page-container-card-image'></img>
							</Row>
							<Row justify='center' className='about-us-page-container-card-divider'>
								<span></span>
							</Row>
							<Row justify='center' className='about-us-page-container-card-title'>
								<span>Lorem ipsum</span>
							</Row>
							<Row justify='center'>
								<div className='about-us-page-container-card-description'>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
									incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
									exercitation ullamco laboris nisi ut aliquip
								</div>
							</Row>
						</div>
					</Row>
				</Col>
				<Col xs={24} sm={24} md={8} lg={8} xl={8} data-aos='fade-in'>
					<Row justify='center' className='about-us-page-container-card-row'>
						<div className='about-us-page-container-card'>
							<Row justify='center'>
								<img src={cardImage2} className='about-us-page-container-card-image'></img>
							</Row>
							<Row justify='center' className='about-us-page-container-card-divider'>
								<span></span>
							</Row>
							<Row justify='center' className='about-us-page-container-card-title'>
								<span>Lorem ipsum</span>
							</Row>
							<Row justify='center'>
								<div className='about-us-page-container-card-description'>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
									incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
									exercitation ullamco laboris nisi ut aliquip
								</div>
							</Row>
						</div>
					</Row>
				</Col>
				<Col xs={24} sm={24} md={8} lg={8} xl={8} data-aos='fade-in'>
					<Row justify='center' className='about-us-page-container-card-row'>
						<div className='about-us-page-container-card'>
							<Row justify='center'>
								<img src={cardImage3} className='about-us-page-container-card-image'></img>
							</Row>
							<Row justify='center' className='about-us-page-container-card-divider'>
								<span></span>
							</Row>
							<Row justify='center' className='about-us-page-container-card-title'>
								<span>Lorem ipsum</span>
							</Row>
							<Row justify='center'>
								<div className='about-us-page-container-card-description'>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
									incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
									exercitation ullamco laboris nisi ut aliquip
								</div>
							</Row>
						</div>
					</Row>
				</Col>
			</Row>

			{/* Produkt 1 */}
			<Row className='about-us-page-container-product-row'>
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={12}
					xl={12}
					data-aos='fade-in'
					className='about-us-page-container-product-col-left'
				>
					<Row className='about-us-page-container-product-title'>Lorem Ipsum</Row>
					<Row className='about-us-page-container-product-title-divider'>
						<span></span>
					</Row>
					<Row className='about-us-page-container-product-subtitle'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
						labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
						laboris nisi ut aliquip ex ea commodo consequat.
					</Row>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} data-aos='fade-in'>
					<Row className='about-us-page-container-product-image-right' justify={isMobile ? 'center' : 'end'}>
						<img src={productImage1} className='landing-section-product-image'></img>
					</Row>
				</Col>
			</Row>

			{/* Produkt 2 */}
			<Row className='about-us-page-container-product-row'>
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={12}
					xl={12}
					data-aos='fade-in'
					className='about-us-page-container-product-col-1'
				>
					<Row className='about-us-page-container-product-image-left' justify={isMobile ? 'center' : 'start'}>
						<img src={productImage2} className='landing-section-product-image'></img>
					</Row>
				</Col>
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={12}
					xl={12}
					data-aos='fade-in'
					className='about-us-page-container-product-col-2'
				>
					<Row className='about-us-page-container-product-title'>Lorem Ipsum</Row>
					<Row className='about-us-page-container-product-title-divider'>
						<span></span>
					</Row>
					<Row className='about-us-page-container-product-subtitle'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
						labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
						laboris nisi ut aliquip ex ea commodo consequat.
					</Row>
				</Col>
			</Row>

			{/* Produkt 3 */}
			<Row className='about-us-page-container-product-row'>
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={12}
					xl={12}
					data-aos='fade-in'
					className='about-us-page-container-product-col-left'
				>
					<Row className='about-us-page-container-product-title'>Lorem Ipsum</Row>
					<Row className='about-us-page-container-product-title-divider'>
						<span></span>
					</Row>
					<Row className='about-us-page-container-product-subtitle'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
						labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
						laboris nisi ut aliquip ex ea commodo consequat.
					</Row>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} data-aos='fade-in'>
					<Row className='about-us-page-container-product-image-right' justify={isMobile ? 'center' : 'end'}>
						<img src={productImage3} className='landing-section-product-image'></img>
					</Row>
				</Col>
			</Row>

			{/* Zdjecie i opis */}
			<Row>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} data-aos='fade-in'>
					<Row>
						<img src={footerImage} className='landing-section-footer-image'></img>
					</Row>
				</Col>

				<Col xs={24} sm={24} md={12} lg={12} xl={12} data-aos='fade-in'>
					<Row justify='center' align='middle' className='about-us-page-container-footer-background'>
						<div className='about-us-page-container-footer-inner-card'>
							<Row justify='center' className='about-us-page-container-footer-inner-card-title'>
								Eiusmod tempor incididunt ut labor
							</Row>
							<Row justify='center' className='about-us-page-container-footer-inner-card-description'>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat Duis aute irure
								dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
								Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
								mollit anim id est laborum.
							</Row>
						</div>
					</Row>
				</Col>
			</Row>

			{/* Zdjecie i opis */}
			{/* {isMobile ? 
				<Row>
					<Col xs={24} sm={24} md={24} lg={24} xl={24} 
					data-aos="fade-in" className='about-us-page-container-footer-mobile-col'>
						<Row justify='center' align='middle' className='about-us-page-container-footer-background-mobile'>
							<div className='about-us-page-container-footer-inner-card'>
								<Row justify='center' className='about-us-page-container-footer-inner-card-title'>
									Eiusmod tempor incididunt ut labor
								</Row>
								<Row justify='center' className='about-us-page-container-footer-inner-card-description'>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
									Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
								</Row>
							</div>
						</Row>
					</Col>
				</Row>
			: 		 <Row>
				<Col xs={24} sm={24} md={8} lg={8} xl={8}
				data-aos="fade-in"
				>
					<Row>
						<img src={footerImage} 
						className='landing-section-footer-image'
						></img>
					</Row>
				</Col>

				<Col xs={24} sm={24} md={16} lg={16} xl={16} data-aos="fade-in">
					<Row justify='center' align='middle' className='about-us-page-container-footer-background'>
						<div className='about-us-page-container-footer-inner-card'>
							<Row justify='center' className='about-us-page-container-footer-inner-card-title'>
								Eiusmod tempor incididunt ut labor
							</Row>
							<Row justify='center' className='about-us-page-container-footer-inner-card-description'>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
								Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
							</Row>
						</div>
					</Row>
				</Col>
			</Row>
			} */}
		</div>
	);
};

export default AboutUsPageContainer;
