import { Card, Col, Modal, Result, Row, Typography } from 'antd';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { getAccountDetails } from 'App/state/account/account.thunk';
import { updateUserAddress } from 'App/state/newOrder/newOrder.slice';
import { RootState } from 'App/state/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ChangeAddressModal.less';

const ChangeAddressModal = (props: { visible: boolean; handleClose: any }) => {
	const { visible, handleClose } = props;

	const dispatch = useDispatch();

	const accountDetails = useSelector((state: RootState) => state.account.accountDetails);
	const choosenAddress = useSelector((state: RootState) => state.newOrder.userAddress);
	const accountDetailsStatus = useSelector((state: RootState) => state.account.status.getAccountDetails);

	useEffect(() => {
		if (!accountDetails) dispatch(getAccountDetails());
	}, [dispatch, accountDetails]);

	const onAddressChange = (address) => {
		dispatch(updateUserAddress(address));
		handleClose();
	};

	return (
		<Modal title='Zmień adres' visible={visible} onOk={handleClose} onCancel={handleClose} footer={null}>
			{accountDetailsStatus === StatusType.LOADING ? (
				<LoadingScreen container='screen' />
			) : (
				<>
					<Row>
						<Col xs={24}>
							<Row gutter={[16, 16]}>
								{accountDetails.client_information.client_addresses &&
									accountDetails.client_information.client_addresses.map((item, key) => {
										let className = 'change-address-modal__card';
										if (choosenAddress?.id === item.id) {
											className = 'change-address-modal__card__active';
										}
										return (
											<Col key={item.id} xs={24} md={12}>
												<Card className={className} onClick={() => onAddressChange(item)}>
													<Row>
														<Col xs={24}>
															<Typography.Text strong>{item.name}</Typography.Text>
														</Col>
														<Col xs={24}>
															{item.address_2 ? (
																<Typography.Text>
																	{item.address_1}/{item.address_2}
																</Typography.Text>
															) : (
																<Typography.Text>{item.address_1}</Typography.Text>
															)}
														</Col>
														<Col xs={24}>
															<Typography.Text>{item.city}</Typography.Text>
														</Col>

														<Col xs={24}>
															<Typography.Text>{item.comment}</Typography.Text>
														</Col>
													</Row>
												</Card>
											</Col>
										);
									})}
							</Row>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col xs={24}>
							{accountDetails.client_information.client_addresses &&
								accountDetails.client_information.client_addresses.length < 0 && (
									<Result title='Nie masz żadnych adresów' />
								)}
						</Col>
					</Row>
				</>
			)}
		</Modal>
	);
};

export default ChangeAddressModal;
