import { RestaurantForGetAllRestaurantsResponse } from "App/api/endpoints/restaurants/responses/getAllRestaurantsResponse";
import { GetRestaurantResponse } from "App/api/endpoints/restaurants/responses/getRestaurantResponse";
import StatusType from "App/types/requestStatus";
const { INITIAL } = StatusType;

export interface RestaurantsState {
    status: {
        getRestaurants: StatusType;
        getRestaurant: StatusType;
    };
    restaurants: RestaurantForGetAllRestaurantsResponse[];
    restaurant: GetRestaurantResponse;
}

export const restaurantsInitialState: RestaurantsState = {
    status: {
        getRestaurants: INITIAL,
        getRestaurant: INITIAL
    },
    restaurants: null,
    restaurant: null
}