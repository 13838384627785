import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetAnonymousOrderResponse } from 'App/api/endpoints/userOrders/responses/getAnonymousOrderResponse';
import { GetUserOrderResponse } from 'App/api/endpoints/userOrders/responses/getUserOrderResponse';
import { OrderForGetUserOrdersResponse } from 'App/api/endpoints/userOrders/responses/getUserOrdersResponse';
import StatusType from 'App/types/requestStatus';
import { ordersInitialState, OrdersState } from './orders.state';

const { FAILED, SUCCESS, LOADING } = StatusType;

const ordersSlice = createSlice({
	name: 'orders',
	initialState: ordersInitialState,
	reducers: {
		getUserOrdersStart: (state: OrdersState) => {
			state.status.getUserOrders = LOADING;
			state.orders = ordersInitialState.orders;
		},
		getUserOrdersSuccess: (state: OrdersState, action: PayloadAction<OrderForGetUserOrdersResponse[]>) => {
			state.status.getUserOrders = SUCCESS;
			state.orders = action.payload;
		},
		getUserOrdersFailure: (state: OrdersState) => {
			state.status.getUserOrders = FAILED;
		},

		getUserOrderStart: (state: OrdersState) => {
			state.status.getUserOrder = LOADING;
			state.selectedOrder = ordersInitialState.selectedOrder;
		},
		getUserOrderSuccess: (state: OrdersState, action: PayloadAction<GetUserOrderResponse>) => {
			state.status.getUserOrder = SUCCESS;
			state.selectedOrder = action.payload;
		},
		getUserOrderFailure: (state: OrdersState) => {
			state.status.getUserOrder = FAILED;
		},

		getAnonymousOrderStart: (state: OrdersState) => {
			state.status.getUserOrder = LOADING;
			state.selectedOrder = ordersInitialState.selectedOrder;
		},
		getAnonymousOrderSuccess: (state: OrdersState, action: PayloadAction<GetAnonymousOrderResponse>) => {
			state.status.getUserOrder = SUCCESS;
			console.log(action.payload);
			state.selectedOrder = action.payload;
		},
		getAnonymousOrderFailure: (state: OrdersState) => {
			state.status.getUserOrder = FAILED;
		},

		cleanUpOrdersState: (state: OrdersState) => {
			state.status.getUserOrder = ordersInitialState.status.getUserOrder;
			state.status.getUserOrders = ordersInitialState.status.getUserOrders;
			state.orders = ordersInitialState.orders;
			state.selectedOrder = ordersInitialState.selectedOrder;
		},
	},
});

export default ordersSlice;

export const {
	getUserOrdersStart,
	getUserOrdersSuccess,
	getUserOrdersFailure,

	getUserOrderStart,
	getUserOrderSuccess,
	getUserOrderFailure,

	getAnonymousOrderStart,
	getAnonymousOrderSuccess,
	getAnonymousOrderFailure,

	cleanUpOrdersState,
} = ordersSlice.actions;
