import React from 'react';
import { Col, Row } from 'antd';
import mainAppLogo from '../assets/logo.jpeg';

const FooterContainer: React.FC = () => {
	return (
		<Row justify='center' align='middle' className='footer-section-main-row'>
			<Col xs={22} sm={22} md={20} lg={20} xl={20}>
				<Row>
					<Col xs={24} sm={24} md={6} lg={6} xl={6}>
						<Row justify='center'>
							<img src={mainAppLogo} className='footer-section-inner-img' alt='inlei logo' />
						</Row>
					</Col>
					<Col xs={24} sm={24} md={6} lg={6} xl={6}>
						<Row className='footer-section-inner-header'>Sklep internetowy</Row>
						<Row className='footer-section-inner-divider-row'>
							<div className='footer-section-inner-divider'></div>
						</Row>
						<Row className='footer-section-inner-text-link'>Oferta</Row>
						<Row className='footer-section-inner-text-link'>Kontakt</Row>
						<Row className='footer-section-inner-text-link'>Dostawa i płatność</Row>
						<Row className='footer-section-inner-text-link'>Reklamacje i zwroty</Row>
						<Row className='footer-section-inner-text-link'>Regulamin</Row>
						<Row className='footer-section-inner-text-link'>Polityka prywatności</Row>
					</Col>
					<Col xs={24} sm={24} md={6} lg={6} xl={6}>
						<Row className='footer-section-inner-header'>Biuro Obsługi klienta</Row>
						<Row className='footer-section-inner-divider-row'>
							<div className='footer-section-inner-divider'></div>
						</Row>
						<Row className='footer-section-inner-text'>Telefon: 531-223-533</Row>
						<Row className='footer-section-inner-text'>Email: sklep@inlei.pl</Row>
					</Col>
					<Col xs={24} sm={24} md={6} lg={6} xl={6}>
						<Row className='footer-section-inner-header'>Adres</Row>
						<Row className='footer-section-inner-divider-row'>
							<div className='footer-section-inner-divider'></div>
						</Row>
						<Row className='footer-section-inner-text'>ul. Konduktorska 2/40</Row>
						<Row className='footer-section-inner-text'>52-130 Wrocław</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default FooterContainer;
