import { ProductForGetAllProductsCategoriesResponse } from "App/api/endpoints/productCategories/responses/getAllProductsCategoriesResponse";
import StatusType from "App/types/requestStatus";
const { INITIAL } = StatusType;

export interface ProductCategoriesState {
    status: {
        getProductCatgories: StatusType;
    };
    productCategories: ProductForGetAllProductsCategoriesResponse[];
}

export const productCategoriesInitialState: ProductCategoriesState = {
    status: {
        getProductCatgories: INITIAL,
    },
    productCategories: null,
}