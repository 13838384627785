import React, { useEffect } from 'react';

import { Row, Col } from 'antd';
import landingSectionImage from './assets/3browbomber.png';

import AOS from 'aos';
import "aos/dist/aos.css";
import './BrowBomberPageContainer.less';

const BrowBomberPageContainer: React.FC<{}> = () => {

	useEffect(() => {
		AOS.init({
			duration: 2600,
			once: true,
		});
		window.addEventListener('load', AOS.refresh);
	}, []);
	
	return (
		<div className='brow-bomber-page-container'>

			<Row justify='center'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} data-aos="fade-in">
					<Row className='brow-bomber-page-container-title' justify='center'>
						Brow Bomber
					</Row>
					<Row className='brow-bomber-page-container-title-divider' justify='center'>
						<span></span>
					</Row>
				</Col>
			</Row>
			<Row justify='center'>
				<Col xs={24} sm={24} md={10} lg={10} xl={10} data-aos="fade-right">
				<Row align='middle' style={{height: '100%'}}>

					<div className='brow-bomber-page-container-subtitle'>
						Dzięki niesamowitemu kursowi InLei® BROW BOMBER będziesz umiała wykonać zabieg laminacji i koloryzacji brwi absolutnie każdej osobie! 
						<br></br>
						Szkolenie skierowane dla osób, które nie mają doświadczenia w stylizacji brwi, a także dla zaawansowanych stylistek rzęs i brwi chcących wprowadzić do swojej oferty popularny zabieg. InLei® BROW BOMBER to rewolucyjny profesjonalny zabieg, stworzony dla brwi i mający na celu uczynienie włosów łuku brwiowego uporządkowanymi, miękkimi, jedwabistymi i odżywionymi. 
						INNOWACYJNA FORMUŁA ZABIEGU, KTÓRA NIE WYMAGA FOLII! 
						<br></br>
						Włoskie produkty stworzone są z wysokiej jakości surowców i przetestowane klinicznie, aby zapewnić maksymalne bezpieczeństwo podczas zabiegu. 
						<br></br>
						* Po szkoleniu zalecamy wykonać prace samodzielnie na modelce wspomagając się szkoleniem oraz materiałami szkoleniowymi
						<div>
							<a target='_blank' href='https://sash.pl/szkolenie-on-line/zakup-dostepu-do-szkolenia-on-line-brow-bomber/#description'>
								<div className='brow-bomber-page-container-button'>Kup dostęp</div>
							</a>
						</div>
					</div>

				</Row>

				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} data-aos="fade-left">
					<Row justify='center'>
						<img src={landingSectionImage} className='brow-bomber-page-container-landing-image'></img>
					</Row>

				</Col>
			</Row>

			<Row className='brow-bomber-page-container-details-row' justify='center'>
				<Col xs={24} sm={24} md={10} lg={10} xl={10} data-aos="fade-right">
					<Row className='brow-bomber-page-container-description-title'>
						Część teoretyczna
					</Row>
					<Row className='brow-bomber-page-container-description-divider'>
					</Row>
					<Row>
													<div className="brow-bomber-page-container-description-element">
														1. Historia brwi od starożytności do naszych czasów
													</div>
													<div className="brow-bomber-page-container-description-element">
														2. Proporcje brwi	
													</div>
													<div className="brow-bomber-page-container-description-element">
														3. Profesjonalne nazewnictwo punktów na brwiach	
													</div>
													<div className="brow-bomber-page-container-description-element">
														4. Wzrost włosków naturalnych	
													</div>
													<div className="brow-bomber-page-container-description-element">
														5. Prawidłowe kierunki ułożenia włosków względem wzrostu naturalnych	
													</div>
													<div className="brow-bomber-page-container-description-element">
														6. Budowa włosa	
													</div>
													<div className="brow-bomber-page-container-description-element">
														7. Łańcuchy keratynowe we włosie
													</div>											
													<div className="brow-bomber-page-container-description-element">
														8. Jak chemicznie zmienia się skręt włosa	
													</div>
													<div className="brow-bomber-page-container-description-element">
														9. 4 fazy wzrostu włosa	
													</div>
													<div className="brow-bomber-page-container-description-element">
														10. Budowa skóry	
													</div>
													<div className="brow-bomber-page-container-description-element">
														11. Rodzaje cery i jak pracować z każdą
													</div>
													<div className="brow-bomber-page-container-description-element">
														12. Szczegółowe omówienie produktów BROW BOMBER – ich skład, PH, działanie, czasy	
													</div>
													<div className="brow-bomber-page-container-description-element">
														13. BOMBER i jego składniki	
													</div>
													<div className="brow-bomber-page-container-description-element">
														14. Czym jest zabieg BROW FILLER?
													</div>
													<div className="brow-bomber-page-container-description-element">
														15. Różnica zabiegów BROW FILLER oraz BROW BOMBER i jak pracować z każdym
													</div>
													<div className="brow-bomber-page-container-description-element">
														16. Wykonanie zabiegu krok po kroku	
													</div>
													<div className="brow-bomber-page-container-description-element">
														17. Przeciwskazania do zabiegu	
													</div>
													<div className="brow-bomber-page-container-description-element">
														18. Koło Ittena – jak pracować i dlaczego jest tak ważne w koloryzacji
													</div>
													<div className="brow-bomber-page-container-description-element">
														19. Pigmenty zawarte w farbkach	
													</div>
													<div className="brow-bomber-page-container-description-element">
														20. Koloryzacja jako reakcja chemiczna	
													</div>
													<div className="brow-bomber-page-container-description-element">
														21. Chemiczne działania farbek – ich skład oraz dlaczego farbki InLei są innowacyjne	
													</div>
													<div className="brow-bomber-page-container-description-element">
														22. Szczegółowe omówienie każdego koloru z 8 farb InLei – jakie zawiera pigmenty procentowo	
													</div>
													<div className="brow-bomber-page-container-description-element">
														23. Praca z każdym rodzajem melaniny – jak prawidłowo wybrać farbkę na prawidłowym pigmencie	
													</div>
													<div className="brow-bomber-page-container-description-element">
														24. Procedura koloryzacji 3 główne fazy
													</div>
													<div className="brow-bomber-page-container-description-element">
														25. Procedura dekoloryzacji	
													</div>
													<div className="brow-bomber-page-container-description-element">
														26. Rozjaśnianie brwi o 1,2,3 gradacji	
													</div>
													<div className="brow-bomber-page-container-description-element">
														27. Regulacja pęsetą i woskiem	
													</div>
													<div className="brow-bomber-page-container-description-element">
														28. Dezynfekcja i sterylizacja	
													</div>
													<div className="brow-bomber-page-container-description-element">
														29. Przeciwwskazania	
													</div>
													<div className="brow-bomber-page-container-description-element">
														30. Zapoznanie się szczegółową z pełną gamą produktów InLei	
													</div>
					</Row>
				</Col>
				<Col xs={24} sm={24} md={10} lg={10} xl={10} data-aos="fade-left">
					<div className='brow-bomber-page-container-description-title'>
						Część praktyczna
					</div>
					<div className='brow-bomber-page-container-description-divider'>

					</div>
					<div>
													<div className="brow-bomber-page-container-description-element">
														1. Wykonanie i omówienie zabiegu na modelce krok po kroku
													</div>
													<div className="brow-bomber-page-container-description-element">
														2. Przygotowanie brwi
													</div>
													<div className="brow-bomber-page-container-description-element">
														3. Praca preparatami chemicznymi
													</div>
													<div className="brow-bomber-page-container-description-element">
														4. Ułożenie włosa	
													</div>
													<div className="brow-bomber-page-container-description-element">
														5. Farbowanie	
													</div>
													<div className="brow-bomber-page-container-description-element">
														6. Odżywienie
													</div>
													<div className="brow-bomber-page-container-description-element">
														7. Regulacja
													</div>											
													<div className="brow-bomber-page-container-description-element">
														8. Omówienie technik ułożenia włosa
													</div>
													<div className="brow-bomber-page-container-description-element">
														9. Omownie techniki wykonania zabiegu
													</div>
													<div className="brow-bomber-page-container-description-element">
														10. Omówienie produktów, potrzebnych do wykonania zabiegu
													</div>
													<div className="brow-bomber-page-container-description-element">
														11. Praca z klientem 
													</div>
					</div>
				</Col>
			</Row>

			<Row justify='center' data-aos="fade-in">
				<a target='_blank' href='https://sash.pl/szkolenie-on-line/zakup-dostepu-do-szkolenia-on-line-brow-bomber/#description'>
					<div className='brow-bomber-page-container-button'>Kup dostęp</div>
				</a>
			</Row>

			<Row className='brow-bomber-page-container-footer-row' data-aos="fade-in">
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Row className='brow-bomber-page-container-footer-title'>
						Czas trwania
					</Row>
					<Row className='brow-bomber-page-container-footer-divider'>

					</Row>
					<Row>
						<div className='brow-bomber-page-container-footer-element'>
							- 1h 30min
						</div>
					</Row>
				</Col>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Row className='brow-bomber-page-container-footer-title'>
						Certyfikaty
					</Row>
					<Row className='brow-bomber-page-container-footer-divider'>

					</Row>
					<Row>
						<div className='brow-bomber-page-container-footer-element'>
							- Międzynarodowy certyfikat ukończenia InLei w dwóch językach [POL/ENG]
						</div>
					</Row>
				</Col>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Row className='brow-bomber-page-container-footer-title'>
						Elementy dodatkowe
					</Row>
					<Row className='brow-bomber-page-container-footer-divider'>

					</Row>
					<Row>
						<div className='brow-bomber-page-container-footer-element'>
							- Jednorazowa zniżka 10% i stała 5% na produkty InLei.pl
						</div>
						<div className='brow-bomber-page-container-footer-element'>
							- Stała 5% na produkty w tym także InLei na LightBrow.pl
						</div>
						<div className='brow-bomber-page-container-footer-element'>
							- Zniżka na szkolenia on-line 10% na Sash.pl
						</div>
						<div className='brow-bomber-page-container-footer-element'>
							- Wystawiamy Faktury VAT
						</div>
					</Row>
				</Col>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Row className='brow-bomber-page-container-footer-title'>
						Zaplecze techniczne
					</Row>
					<Row className='brow-bomber-page-container-footer-divider'>

					</Row>
					<Row>
						<div className='brow-bomber-page-container-footer-element'>
							- Nielimitowany dostęp do szkolenia przez 30 dni
						</div>
						<div className='brow-bomber-page-container-footer-element'>
							- Skrypt w wersji PDF (do druku)
						</div>
					</Row>
				</Col>
			</Row>











		</div>
	);
};

export default BrowBomberPageContainer;
