import agent from 'App/api/agent/agent';
import { RestaurantForGetAllRestaurantsResponse } from 'App/api/endpoints/restaurants/responses/getAllRestaurantsResponse';
import { AppThunk } from '../store';
import {
	getRestaurantFailure,
	getRestaurantsFailure,
	getRestaurantsStart,
	getRestaurantsSuccess,
	getRestaurantStart,
	getRestaurantSuccess,
} from './restaurants.slice';

export const getRestaurants = (): AppThunk => async (dispatch) => {
	dispatch(getRestaurantsStart());
	agent.Restaurants.getAllRestaurants()
		.then((response: RestaurantForGetAllRestaurantsResponse[]) => {
			dispatch(getRestaurantsSuccess(response));
		})
		.catch(() => dispatch(getRestaurantsFailure()));
};

export const getRestaurant = (restaurantId: number): AppThunk => async (dispatch) => {
	dispatch(getRestaurantStart());
	agent.Restaurants.getRestaurant(restaurantId)
		.then((response) => {
			dispatch(getRestaurantSuccess(response));
		})
		.catch(() => dispatch(getRestaurantFailure()));
};
