import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { GetAccountDetailsResponse } from 'App/api/endpoints/account/responses';
import { localStorageHelper } from 'App/common/utils/localStorageHelper';
import { logout } from 'App/state/auth/auth.thunk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

interface AccountSubMenuProps {
	accountDetails: GetAccountDetailsResponse;
	isMobile: boolean;
}

const AccountSubMenu = (props: AccountSubMenuProps) => {
	const history = useHistory();
	const { t } = useTranslation('page');
	const dispatch = useDispatch();

	const onLogoutClick = () => {
		let handleSuccess: () => void = () => {
			history.push('/');
			localStorageHelper.clear()
		};
		dispatch(logout(handleSuccess));
	};

	const { accountDetails, isMobile, ...rest } = props;

	const className = isMobile ? '' : 'float-right';
	return (
		<Menu.SubMenu
			{...rest}
			key='/account'
			className={className}
			title={t('Common.NavbarContainer.Profile')}
		>
			<Menu.Item key='/account' icon={<SettingOutlined />}>
				<Link to='/account'>{t('Common.NavbarContainer.Edit')}</Link>
			</Menu.Item>
			<Menu.Divider />

			<Menu.Item key='logout' onClick={onLogoutClick} icon={<LogoutOutlined />}>
				{t('Common.NavbarContainer.Logout')}
			</Menu.Item>
		</Menu.SubMenu>
	);
};

export default AccountSubMenu;
