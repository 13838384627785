import { ProductForGetAllProductsCategoriesResponse } from 'App/api/endpoints/productCategories/responses/getAllProductsCategoriesResponse';
import { GetRestaurantResponse } from 'App/api/endpoints/restaurants/responses/getRestaurantResponse';
import { getMenuCategoryWithSubcategories } from './getMenuCategoryWithSubcategories';

export const getRestaurantMenuFromRestaurantAndCategories = (
	productCategories: ProductForGetAllProductsCategoriesResponse[],
	restaurant: GetRestaurantResponse
) => {
	let menuCategories = productCategories?.map((productCategory) =>
		getMenuCategoryWithSubcategories(productCategory, restaurant?.place_products)
	);

	menuCategories?.sort((a, b) => (a.menuOrder > b.menuOrder ? 1 : 0));

	// console.log(
	// 	'menuCategories: ',
	// 	menuCategories.find((mC) => mC.products.find((p) => p.product.categories.length))
	// );
	return {
		id: restaurant?.id,
		categories: menuCategories,
		active_option_items: restaurant?.active_option_items,
	};
};
