import { Modal, notification } from 'antd';
import { AddUserAddressRequest } from 'App/api/endpoints/account/requests';
import { UserAddress } from 'App/domain/entities/userAddress';
import AddUserAddressForm from 'App/pages/AccountPage/editProfile/components/addUserAddressForm/AddUserAddressForm';
import { getAccountDetails, addUserAddress } from 'App/state/account/account.thunk';
import { setUnloggedUserAddress } from 'App/state/newOrder/newOrder.slice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const AddAddressModal = (props: { visible: boolean; handleClose: any; userLoggedIn: boolean }) => {
	const { visible, handleClose, userLoggedIn } = props;

	const { t } = useTranslation(['page', 'common']);
	const dispatch = useDispatch();


	const onFinish = (values: AddUserAddressRequest) => {
		if (userLoggedIn) {
			const onSuccess = () => {
				dispatch(getAccountDetails());
				handleClose();

				notification.success({
					message: t('common:Success.Success'),
					description: 'Dodano nowy adres',
				});
			};

			dispatch(addUserAddress(values, onSuccess));
		} else {
			const address = {
				address_1: values.address_1,
				address_2: values.address_2,
				city: values.city,
				id: 0,
				name: '',
			} as UserAddress;

			dispatch(setUnloggedUserAddress(address));
			handleClose();
		}
	};
	return (
		<Modal title='Dodaj adres' visible={visible} onOk={handleClose} onCancel={handleClose} footer={null}>
			<AddUserAddressForm
				onFinish={onFinish}
				modal={true}
				userLoggedIn={userLoggedIn}
			/>
		</Modal>
	);
};

export default AddAddressModal;
