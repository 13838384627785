import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import StatusType from 'App/types/requestStatus';
const { INITIAL } = StatusType;

export interface PromotionsState {
	status: {
		getAllUserPromotions: StatusType;
		addDiscountCodePromotion: StatusType;
	};
	promotions: PromotionForGetAllUserPromotions[];
}

export const promotionsInitialState: PromotionsState = {
	status: {
		getAllUserPromotions: INITIAL,
		addDiscountCodePromotion: INITIAL,
	},
	promotions: [],
};
