import React, { useEffect } from 'react';

import { Row, Col } from 'antd';
import landingSectionImage from './assets/2lashfiler.png';

import AOS from 'aos';
import "aos/dist/aos.css";
import './LashFillerPageContainer.less';

const BrowBomberPageContainer: React.FC<{}> = () => {

	useEffect(() => {
		AOS.init({
			duration: 2600,
			once: true,
		});
		window.addEventListener('load', AOS.refresh);
	}, []);
	
	return (
		<div className='lash-filler-page-container'>

			<Row justify='center'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} data-aos="fade-in">
					<Row className='lash-filler-page-container-title' justify='center'>
						Lash Filler
					</Row>
					<Row className='lash-filler-page-container-title-divider' justify='center'>
						<span></span>
					</Row>
				</Col>
			</Row>
			<Row justify='center'>
				<Col xs={24} sm={24} md={10} lg={10} xl={10} data-aos="fade-right">
					<Row align='middle' style={{height: '100%'}}>
						<div className='lash-filler-page-container-subtitle'>
						Szkolenie skierowane dla osób, które mają doświadczenia w zabiegach liftingu, laminacji i botoxu rzęs. 
						<br></br><br></br>
						InLei LASH FILLER – Jest innowacyjną kuracją całkowicie opatentowaną i wyprodukowaną we Włoszech, co gwarantuje najwyższą jakość produktu, pewność poprawnego wykonania zabiegu, oraz dzięki testom klinicznym najwyższe bezpieczeństwo użytkowania. Dzięki użyciu produktów pogrubiamy rzęsy naturalne średnio 24% i więcej. Rzęsy zatem po zastosowaniu produktu stają się podkręcone, odżywione, błyszczące, zdrowe i jedwabiście miękkie w dotyku. Spojrzenie staje się podkreślone i bardziej intensywne, bez potrzeby użycia dodatkowych produktów. 
						<br></br><br></br>
						Jeżeli chcesz poznać tajniki produktowe, chemiczne, wykonanie poprawnego zabiegu i mieć odpowiedź na każde pytanie – to szkolenie jest właśnie dla Ciebie! 
						<br></br><br></br>
						* Po szkoleniu zalecamy wykonać prace samodzielnie na modelce wspomagając się szkoleniem oraz materiałami szkoleniowymi
							<div>
								<a target='_blank' href='https://sash.pl/szkolenie-on-line/zakup-dostepu-do-szkolenia-on-line-inlei-lash-filler/'>
									<div className='lash-filler-page-container-button'>Kup dostęp</div>
								</a>
							</div>
						</div>
					</Row>

				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} data-aos="fade-left">
					<Row justify='center'>
						<img src={landingSectionImage} className='landing-section-image'></img>
					</Row>

				</Col>
			</Row>

			<Row className='lash-filler-page-container-details-row' justify='center'>
				<Col xs={24} sm={24} md={10} lg={10} xl={10} data-aos="fade-right">
					<Row className='lash-filler-page-container-description-title'>
						Część teoretyczna
					</Row>
					<Row className='lash-filler-page-container-description-divider'>

					</Row>
					<Row>
													<div className="lash-filler-page-container-description-element">
														1. Historia zabiegu
													</div>
													<div className="lash-filler-page-container-description-element">
														2. Mocne strony
													</div>
													<div className="lash-filler-page-container-description-element">
														3. Budowa oraz biochemia włosa
													</div>
													<div className="lash-filler-page-container-description-element">
														4. Działanie chemiczne produktów na rzęsy naturalne
													</div>
													<div className="lash-filler-page-container-description-element">
														5. Zmiana skrętu naturalnego rzęs za pomocą produktów
													</div>
													<div className="lash-filler-page-container-description-element">
														6. Jak i dlaczego rzęsa zmienia swój skręt
													</div>
													<div className="lash-filler-page-container-description-element">
														7. Dekoloryzacja i koloryzacja rzęs
													</div>											
													<div className="lash-filler-page-container-description-element">
														8. LASH FILLER – najważniejsze informacje
													</div>
													<div className="lash-filler-page-container-description-element">
														9. Porównanie oraz omówienie istniejących zabiegów: lifting, laminacja, botox – prawda czy mit?
													</div>
													<div className="lash-filler-page-container-description-element">
														10. Jak działa zabieg LASH FILLER na rzęsy, prezentacja rzęs pod mikroskopem
													</div>
													<div className="lash-filler-page-container-description-element">
														11. Prezentacja badań klinicznych przeprowadzonych w Mediolanie pod względem skuteczności zabiegu
													</div>
													<div className="lash-filler-page-container-description-element">
														12. Przebieg zabiegu
													</div>
													<div className="lash-filler-page-container-description-element">
														13. Jak zwiększa się średnica rzęsy
													</div>
													<div className="lash-filler-page-container-description-element">
														14. Jak zwiększa się skręt rzęsy
													</div>
													<div className="lash-filler-page-container-description-element">
														15. Omówienie norm zgodnie z regulaminem Unii Europejskiej
													</div>
													<div className="lash-filler-page-container-description-element">
														16. Produkty linii InLei
													</div>
													<div className="lash-filler-page-container-description-element">
														17. Rewolucja w formach silikonowych
													</div>
													<div className="lash-filler-page-container-description-element">
														18. Pojęcie materiałów
													</div>
													<div className="lash-filler-page-container-description-element">
														19. Rewolucja w formach silikonowych
													</div>
													<div className="lash-filler-page-container-description-element">
														20. Pojęcie materiałów
													</div>
													<div className="lash-filler-page-container-description-element">
														21. Dobór prawidłowego czasu do rzęs naturalnych
													</div>
													<div className="lash-filler-page-container-description-element">
														22. Ułożenie rzęs na formie silikonowej
													</div>
													<div className="lash-filler-page-container-description-element">
														23. Modelowanie
													</div>
													<div className="lash-filler-page-container-description-element">
														24. Prawidłowe nałożenie preparatów
													</div>
													<div className="lash-filler-page-container-description-element">
														25. Dobór odpowiedniej formy wraz ze skrętem do oczu klientki uwzględniając wzrost rzęs naturalnych
													</div>
													<div className="lash-filler-page-container-description-element">
														26. Jak pracować nie przyklejając formy za pomocą kleju
													</div>
													<div className="lash-filler-page-container-description-element">
														27. Jak osiągnąć idealny skręt oraz idealne naciągnięcie rzęs na formę
													</div>
													<div className="lash-filler-page-container-description-element">
														28. Jak pracować z dzikim rzędem rzęs u klientki
													</div>	
					</Row>
				</Col>
				<Col xs={24} sm={24} md={10} lg={10} xl={10} data-aos="fade-left">
					<div className='lash-filler-page-container-description-title'>
						Część praktyczna
					</div>
					<div className='lash-filler-page-container-description-divider'>

					</div>
					<div>
													<div className="lash-filler-page-container-description-element">
														1. Wykonanie zabiegu krok po kroku przez instruktora
													</div>
													<div className="lash-filler-page-container-description-element">
														2. Techniczne aspekty prawidłowego wykonania
													</div>
					</div>
				</Col>
			</Row>

			<Row justify='center' data-aos="fade-in">
				<a target='_blank' href='https://sash.pl/szkolenie-on-line/zakup-dostepu-do-szkolenia-on-line-inlei-lash-filler/'>
					<div className='lash-filler-page-container-button'>Kup dostęp</div>
				</a>
			</Row>

			<Row className='lash-filler-page-container-footer-row' data-aos="fade-in">
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Row className='lash-filler-page-container-footer-title'>
						Czas trwania
					</Row>
					<Row className='lash-filler-page-container-footer-divider'>

					</Row>
					<Row>
						<div className='lash-filler-page-container-footer-element'>
							- 2h 33min
						</div>
					</Row>
				</Col>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Row className='lash-filler-page-container-footer-title'>
						Certyfikaty
					</Row>
					<Row className='lash-filler-page-container-footer-divider'>

					</Row>
					<Row>
						<div className='lash-filler-page-container-footer-element'>
							- InLei
						</div>
						<div className='lash-filler-page-container-footer-element'>
							- SASH Lash&Brow w dwóch językach [POL/ENG]
						</div>
					</Row>
				</Col>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Row className='lash-filler-page-container-footer-title'>
						Elementy dodatkowe
					</Row>
					<Row className='lash-filler-page-container-footer-divider'>

					</Row>
					<Row>
						<div className='lash-filler-page-container-footer-element'>
							- Jednorazowa zniżka 10% i stała 5% na produkty InLei.pl
						</div>
						<div className='lash-filler-page-container-footer-element'>
							- Stała 5% na produkty w tym także InLei na LightBrow.pl
						</div>
						<div className='lash-filler-page-container-footer-element'>
							- Zniżka na szkolenia on-line 10% na Sash.pl
						</div>
						<div className='lash-filler-page-container-footer-element'>
							- Wystawiamy Faktury VAT
						</div>
					</Row>
				</Col>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Row className='lash-filler-page-container-footer-title'>
						Zaplecze techniczne
					</Row>
					<Row className='lash-filler-page-container-footer-divider'>

					</Row>
					<Row>
						<div className='lash-filler-page-container-footer-element'>
							- Nielimitowany dostęp do szkolenia przez 30 dni
						</div>
						<div className='lash-filler-page-container-footer-element'>
							- Skrypt w wersji PDF (do druku)
						</div>
						<div className='lash-filler-page-container-footer-element'>
							- Salonowa ankieta klienta
						</div>
					</Row>
				</Col>
			</Row>
















		</div>
	);
};

export default BrowBomberPageContainer;
