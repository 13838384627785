import { DeliveryZone } from 'App/domain/entities/deliveryZone';
import { LatLngExpression } from 'leaflet';

export const newOrderService = {
	isPlaceMarkInAnyOfDeliveryZones(cords: LatLngExpression, deliveryZones: DeliveryZone[]) {
		let isAnyDeliveryZone = false;

		deliveryZones.forEach((deliveryZone) => {
			const isInPolygon = this.isPointInPolygon(cords, deliveryZone.lat_lngs);
			if (isInPolygon) {
				isAnyDeliveryZone = true;
			}
		});
		return isAnyDeliveryZone;
	},

	getDeliveryPriceInPlnForPlacemarkInDeliveryZones(cords: LatLngExpression, deliveryZones: DeliveryZone[]) {
		let lowestDeliveryPrice = Number.POSITIVE_INFINITY;

		deliveryZones.forEach((deliveryZone) => {
			const isInPolygon = this.isPointInPolygon(cords, deliveryZone.lat_lngs);

			if (isInPolygon) {
				if (deliveryZone.price_in_pln < lowestDeliveryPrice) {
					lowestDeliveryPrice = deliveryZone.price_in_pln;
				}
			}
		});

		return lowestDeliveryPrice;
	},

	isPointInPolygon(point: LatLngExpression, polygonVertices: LatLngExpression[]) {
		let intersectCount = 0;

		for (let j = 0; j < polygonVertices.length - 1; j++) {
			if (this.rayCastIntersect(point, polygonVertices[j], polygonVertices[j + 1])) intersectCount++;
		}

		return intersectCount % 2 === 1; // odd = inside, even = outside;
	},

	rayCastIntersect(tap: any, vertA: any, vertB: any) {
		let aY = vertA.lat;
		let bY = vertB.lat;
		let aX = vertA.lng;
		let bX = vertB.lng;
		let pY = tap[0];
		let pX = tap[1];

		if ((aY > pY && bY > pY) || (aY < pY && bY < pY) || (aX < pX && bX < pX)) {
			return false; // a and b can't both be above or below pt.y, and a or
			// b must be east of pt.x
		}

		let m = (aY - bY) / (aX - bX); // Rise over run
		let bee = -aX * m + aY; // y = mx + b
		let x = (pY - bee) / m; // algebra is neat!

		return x > pX;
	},
};
