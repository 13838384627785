import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import appLogo from '../../assets/logo.jpeg';

const HomeMenuItem = (props) => {
	const { t } = useTranslation('page');
	return (
		<Menu.Item {...props} key='/'>
			<Link to='/'>
				<img width={80} alt={t('Common.NavbarContainer.Home')} src={appLogo} />
			</Link>
		</Menu.Item>
	);
};

export default HomeMenuItem;
