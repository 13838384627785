import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Result, Row } from 'antd';
import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import { getProductCategories } from 'App/state/productCategories/productCategories.thunk';
import { getAllUserPromotions } from 'App/state/promotions/promotions.thunk';
import { getRestaurant } from 'App/state/restaurants/restaurants.thunk';
import { RootState } from 'App/state/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getMinutesOfTime } from '../RestaurantsPage/restaurantsList/utils/isRestaurantOpen';
import RestaurantMenuCard from './restaurantMenuCard/RestaurantMenuCard';
import { getRestaurantMenuFromRestaurantAndCategories } from './utils/getRestaurantMenuFromRestaurantAndCategories';
import './RestaurantMenuPageContainer.less';

interface RouteParams {
	restaurantId: string;
	selectedCategory: string;
}

interface RestaurantMenuPageContainerProps extends RouteComponentProps<RouteParams> {}

const { LOADING } = StatusType;

const RestaurantMenuPageContainer: React.FC<RestaurantMenuPageContainerProps> = ({
	match,
}: RestaurantMenuPageContainerProps) => {
	const restaurantId = '1';
	const selectedCategory = match.params.selectedCategory;

	const favouriteRestaurantId = localStorage.getItem('favouriteRestaurantId');

	const dispatch = useDispatch();

	const restaurant = useSelector((state: RootState) => state.restaurants.restaurant);
	const getRestaurantStatus = useSelector((state: RootState) => state.restaurants.status.getRestaurant);

	const productCategories = useSelector((state: RootState) => state.productCategories.productCategories);
	const getProductCatgoriesStatus = useSelector(
		(state: RootState) => state.productCategories.status.getProductCatgories
	);

	const promotions = useSelector((state: RootState) => state.promotions.promotions);
	const promotionsStatus = useSelector((state: RootState) => state.promotions.status.getAllUserPromotions);

	const [restaurantMenu, setRestaurantMenu] = useState(
		getRestaurantMenuFromRestaurantAndCategories(productCategories, restaurant)
	);
	const [loading, setLoading] = useState(true);

	// zwraca tablicę id produktów oraz tablicę obowiązujących ten produkt promocji
	const getProductIdsToShowPromotionOnWithPromotions = (
		restaurantMenu,
		promotions: PromotionForGetAllUserPromotions[]
	) => {
		const arrToReturn: {
			productId: number;
			promotions: PromotionForGetAllUserPromotions[];
		}[] = [];

		if (!restaurantMenu || !promotions || !restaurantMenu.categories) {
			return arrToReturn;
		}

		// wszystkie produkty z menu restauracji:
		const allProductsFromRestaurant = restaurantMenu.categories
			.flatMap((c) => c.products)
			.filter((p) => p.is_active)
			.flatMap((p) => p.product);

		// promocje, które obejmują tą restaurację (czyli arrayka miejsc zawiera id restauracji lub arrayka miejsc jest pusta)
		const promotionsForThisRestaurant = promotions.filter(
			(p) =>
				p.promotion_places.flatMap((pp) => pp.place.id).includes(restaurantMenu.id) ||
				p.promotion_places.length <= 0
		);

		// teraz musimy pobrać te promocje, w które wpadamy czasowo oraz są aktywne
		const activePromotionsForThisRestaurant = promotionsForThisRestaurant.filter(
			(promotion) => promotion.is_active
		);

		const now = new Date();

		const activePromotionsForThisRestaurantAvailableNow = activePromotionsForThisRestaurant.filter((promotion) => {
			const promotionFromLocal = new Date(promotion.date_from + 'Z');
			const promotionUntilLocal = new Date(promotion.date_until + 'Z');

			// jeśli kupon stracił ważność to nie bierzemy
			if (now < promotionFromLocal || now > promotionUntilLocal) {
				return false;
			}

			// jeśli nie są ustawione godziny to bierzemy (traktujemy, że codziennie przez cały dzień)
			if (promotion.time_active.hasOwnProperty('None')) {
				return true;
			}

			const currentDayOfWeek = now.getDay(); // dzień w UTC, Sunday = 0, Mon = 1
			const allDaysFromBackend = ['sunday', 'monday', 'tuesday', 'wednsday', 'thursday', 'friday', 'saturday'];

			// jeśli dziś nie ma ustawionych godzin to promocja trwa cały dzień (bierzemy)
			if (!promotion.time_active.hasOwnProperty(allDaysFromBackend[currentDayOfWeek])) {
				return true;
			}

			// pobieramy w jakich godzinach dziś promocja jest dostępna
			const timeStartUtc = promotion.time_active[allDaysFromBackend[currentDayOfWeek]].time_start;
			const timeEndUtc = promotion.time_active[allDaysFromBackend[currentDayOfWeek]].time_end;

			// czasy są w UTC, chcemy je przekonwertować na czas lokalny i następnie porównać
			const startLocale = new Date(`${now.toDateString()} ${timeStartUtc}Z`);
			const endLocale = new Date(`${now.toDateString()} ${timeEndUtc}Z`);

			const startLocaleMinutes = getMinutesOfTime(startLocale);
			const endLocaleMinutes = getMinutesOfTime(endLocale);
			const nowMinutes = getMinutesOfTime(now);

			// wszystkie sprowadzamy do lokalnego date i porównujemy
			// jeśli nie wpadamy w godziny obowiązywania to promocja nie jest wyświetlana
			if (nowMinutes < startLocaleMinutes || nowMinutes > endLocaleMinutes) {
				return false;
			}

			return true;
		});

		// inicjalizujemy arraykę wszystkimi produktami bez promocji
		allProductsFromRestaurant.forEach((product) => {
			arrToReturn.push({
				productId: product.id,
				promotions: [],
			});
		});

		for (const promotion of activePromotionsForThisRestaurantAvailableNow) {
			// jeśli promka ma pustą arraykę promotion_products, to traktujemy to, że wszystkie produkty są na promocji
			if (promotion.promotion_products.length <= 0) {
				// czyli do każdego produktu dodajemy tą promocję
				allProductsFromRestaurant.forEach((product) => {
					const indexInArray = arrToReturn.findIndex((p) => p.productId === product.id);
					if (indexInArray < 0) {
					} else {
						arrToReturn[indexInArray].promotions.push(promotion);
					}
				});
			} else {
				// promocja nie ma pustej arrayki, więc musimy zciągnąć wszystkie id produktów, które są w arrayce produktow promocji
				const productIdsInThisPromotion = promotion.promotion_products.flatMap((pp) => pp.product.id);

				// dla każdego takiego produktu trzeba dodać tą promocję
				productIdsInThisPromotion.forEach((productId) => {
					const indexInArray = arrToReturn.findIndex((p) => p.productId === productId);
					if (indexInArray < 0) {
					} else {
						arrToReturn[indexInArray].promotions.push(promotion);
					}
				});
			}
		}

		// Oto lista produktów i obowiązujacych ich promocji:
		return arrToReturn;
	};

	const [productIdsWithValidPromotions, setProductIdsWithValidPromotions] = useState(
		getProductIdsToShowPromotionOnWithPromotions(restaurantMenu, promotions)
	);
	// const restaurantMenu = getRestaurantMenuFromRestaurantAndCategories(productCategories, restaurant);

	useEffect(() => {
		if (restaurantMenu && restaurantMenu.categories && promotions) {
			const productIdsWithValidPromotions = getProductIdsToShowPromotionOnWithPromotions(
				restaurantMenu,
				promotions
			);
			setProductIdsWithValidPromotions(productIdsWithValidPromotions);
		}
	}, [restaurantMenu, promotions]);

	useEffect(() => {
		if (restaurantId) {
			dispatch(getRestaurant(Number(restaurantId)));
			dispatch(getProductCategories());
			dispatch(getAllUserPromotions());
		} else if (favouriteRestaurantId) {
			dispatch(getRestaurant(Number(favouriteRestaurantId)));
			dispatch(getProductCategories());
			dispatch(getAllUserPromotions());
		}
	}, [dispatch, restaurantId, favouriteRestaurantId]);

	useEffect(() => {
		if (productCategories && restaurant)
			setRestaurantMenu(getRestaurantMenuFromRestaurantAndCategories(productCategories, restaurant));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productCategories, restaurant]);

	useEffect(() => {
		if (getRestaurantStatus === LOADING || getProductCatgoriesStatus === LOADING || promotionsStatus === LOADING) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [getRestaurantStatus, getProductCatgoriesStatus, promotionsStatus]);

	const shouldShowRibbon = () => {
		if (restaurantId && favouriteRestaurantId === restaurantId) return true;

		if (!restaurantId && favouriteRestaurantId) return true;
		return false;
	};

	// console.log(
	// 	'res: ',
	// 	restaurant?.place_products.filter((pp) => pp.product.categories.length),
	// 	selectedCategory
	// );

	if (restaurantId || favouriteRestaurantId) {
		return (
			<Row justify='center' className='pt-3'>
				<Col xs={24} lg={22} xl={20} xxl={16}>
					<RestaurantMenuCard
						selectedCategory={selectedCategory}
						showRibbon={shouldShowRibbon()}
						restaurantInfo={restaurant}
						restaurantMenu={restaurantMenu}
						productCategories={productCategories}
						loading={loading}
						productIdsWithValidPromotions={productIdsWithValidPromotions}
					/>
				</Col>
				<Col xs={24} lg={24} xl={24} xxl={24} className='text-center restaurant-menu-page-container-button-col'>
					{productCategories && productCategories.length > 0 && (
						<Link to='/cart'>
							<Button className='restaurant-menu-page-container-button'>Do kasy</Button>
						</Link>
					)}
				</Col>
			</Row>
		);
	} else {
		return (
			<Row justify='center'>
				<Col>
					<Result
						icon={<InfoCircleOutlined />}
						title='Brak wybranego sklepu'
						subTitle='Oznacz sklep jako ulubiony aby zobaczyć produkty'
						extra={
							<Link to='/restaurants'>
								<Button type='primary' size='large'>
									Wybierz sklep
								</Button>
							</Link>
						}
					/>
				</Col>
			</Row>
		);
	}
};

export default RestaurantMenuPageContainer;
