import { Input, Button, Form } from 'antd';
import React, { } from 'react';
import { useTranslation } from 'react-i18next';

interface AddDiscountCodeFormProps {
    onFinish: (values) => any
    loading: boolean
}

const AddDiscountCodeForm = (props: AddDiscountCodeFormProps) => {
    const { onFinish, loading } = props
    const { t } = useTranslation(['form', 'common']);


    return (
        <Form className='px-3' onFinish={onFinish}>
            <Form.Item
                name='code'
                label="Kod zniżkowy"
            >
                <Input />
            </Form.Item>

            <Form.Item>
                <Button block type='primary' htmlType='submit' loading={loading}>
                    {t('common:Actions.Save')}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddDiscountCodeForm;
