import { Instructor } from '../interfaces/instructor';

export const instructorsData: Instructor[] = [
	{
		id: 0,
		firstName: 'Anna',
		lastName: 'Krukowska',
		description:
			'Nazywam się Krukowska Anna i jestem założycielem Butterfly Academy w Łodzi a także trenerem  marki Inlei w województwie Łódzkim. Kosmetyką zajmuje się od 5 lat. Na co dzień wykonuje takie zabiegi jak stylizacja rzęs z pełnym wachlarzem dostępnych opcji oraz stylizacją brwi. Największą popularnością cieszą się u mnie szkolenia z zakresu Lash Filler oraz Brow Bomber stające się czołowymi zabiegami salonowymi. Prywatnie jestem szczęśliwą mamą łączącą macierzyństwo ze swoją pasją. Ponadto wielokrotnie zajmowałam podium na mistrzostwach oraz miałam przyjemność tworzyć testy wiedzy dla innych stylistek/ stylistów z wyżej wymienionych zabiegów. Jeśli chcesz również by Twoja praca stała się jednocześnie pasją, zapraszam do mojej akademii.',
		city: 'Łódź',
		imageUrl: './assets/1-min.png',
		phone: '791 951 661',
		email: 'butterflystudioanna@gmail.com',
		facebookUrl: 'https://www.facebook.com/ButterflyStudioAnnaKrukowska',
		instagramUrl: 'https://www.instagram.com/butterfly_academy_lodz/',
	},
	{
		id: 1,
		firstName: 'Olga',
		lastName: 'Klucznik',
		description: `Kieruje się mottem „I do what i love, I am proud of who I am”
 
			Swoje kursantki uczę̨ szacunku do zawodu stylistki w branży beauty, to nie trend ani moda. To odpowiedzialność́ za swoje działania, która zawsze musi być na pierwszym miejscu. 
			 
			- Multimedalistka stylizacji rzęs i brwi w tym tytuł mistrza Świata (Mińsk)
			- Trener stylizacji rzęs oraz brwi, makijażu permanentnego
			- Założycielka półrocznej szkoły stylizacji rzęs, a także brwi i PMU
			- Właścicielka duzego centrum szkoleniowego z zakresu: stylizacji rzęs, brwi, pmu i wizażu w Zielonej Górze
			- Założycielka  marki IMTHELASHMAKER
			`,
		city: 'Zielona Góra',
		imageUrl: './assets/3-min.png',
		phone: '530 412 948',
		email: 'imthelashmaker@gmail.com',
		facebookUrl: 'https://www.facebook.com/olgaklucznik.imthelashmaker',
		instagramUrl: '',
	},
	{
		id: 2,
		firstName: 'Marzena',
		lastName: 'Grochala',
		description: '',
		city: 'Sosnowiec',
		imageUrl: './assets/4-min.png',
		phone: '536 060 710',
		email: 'grochala.marzena@gmail.com',
		facebookUrl: 'https://www.facebook.com/MarzenaGrochalaInstruktor',
		instagramUrl: '',
	},
	{
		id: 3,
		firstName: 'Jagoda',
		lastName: 'Jankowska',
		description: '',
		city: 'Piła',
		imageUrl: './assets/5-min.png',
		phone: '733 424 840',
		email: 'lashroom@wp.eu',
		facebookUrl: 'https://www.facebook.com/lashroompila/',
		instagramUrl: 'https://www.instagram.com/jagoda.jankowska.lashes/',
	},
	{
		id: 4,
		firstName: 'Justyna',
		lastName: 'Iliaszuk',
		description: `Justyna Iliaszuk instruktor stylizacji rzęs i brwi z wieloletnim doświadczeniem po studiach pedagogicznych. Na swoim koncie posiada setki przeszkolonych i zadowolonych kursantek.
			Instruktor w firmach:
			InLei - laminacja rzęs/brwi
			Mlashes - stylizacja rzęs
			Pove - stylizacja brwi
			
			Swoją przygodę z brwiami i rzęsami zaczęła w 2012 r. W salonie prowadzi szkolenia z zakresu stylizacji rzęs i brwi. Do każdej kursantki podchodzi indywidualnie. Szkolenia organizowane są maksymalnie w grupie dwuosobowej. Każda z pań ma swoje własne, w pełni wyposażone stanowisko, a małe grupy pozwalają na poświęcenie odpowiedniej ilości czasu każdej kursantce.
			Jej mocne strony to przede wszystkim indywidualne podejście do kursantki i wieloletnie doświadczenie w pracy jako nauczyciel.
			`,
		city: 'Białystok',
		imageUrl: './assets/6-min.png',
		phone: '508 681 246',
		email: 'justyna.iliaszuk@gmail.com',
		facebookUrl: 'https://www.facebook.com/JustEyeLashes',
		instagramUrl: 'https://www.instagram.com/justyskai.lash/',
	},
	{
		id: 5,
		firstName: 'Maria',
		lastName: 'Wyszyńska',
		description: `Mgr. Maria Wyszyńska założycielka salonu Mary Salon Urody. Linergistka z pasji i wykształcenia kochająca piękno. 
			Absolwentka GWSH w Katowicach na wydziale medycznym kierunku Trener 
			Linergista oraz kosmetologii estetycznej, uczestniczka ogólnopolskiej akcji Long Time Liner ,,Sercem Malowane’’ pod patronatem Magdy Bogulak. 
			Regionalny instruktor marki Nikk Mole Polska, 
			Regionalny instruktor marki INLEI Polska,
			Finalistka mistrzostw PMU GOLD w 2020 r. 
			Laureatka Elitarnych Mistrzostw Polski Long Time Liner, 
			Zdobywczyni 3 miejsca w Międzynarodowych Mistrzostwach Stacjonarnych Lash Lift w 2019r., 
			Certyfikowana Mistrzyni Urody w Kategorii Linergistka roku 2019 województwa podkarpackiego
			`,
		city: 'Rzeszów',
		imageUrl: './assets/13-min.png',
		phone: '733 889 459',
		email: 'mary@permanentnyrzeszow.com',
		facebookUrl: 'https://www.facebook.com/PermanentnyRzeszow',
		instagramUrl: 'https://www.instagram.com/permanentny.rzeszow/',
	},
	{
		id: 6,
		firstName: 'Magdalena',
		lastName: 'Szczygielska',
		description: '',
		city: 'Kielce',
		imageUrl: './assets/8-min.png',
		phone: '730 500 556',
		email: 'szkolenia.lushbeauty@gmail.com',
		facebookUrl: 'https://www.facebook.com/LushBeautyKielce',
		instagramUrl: 'https://www.instagram.com/m.szczygielska_educator/',
	},
	{
		id: 7,
		firstName: 'Aleksandra',
		lastName: 'Kopowska',
		description: '',
		city: 'Trójmiasto',
		imageUrl: './assets/9-min.png',
		phone: '883 660 447',
		email: 'info@aleksandrakopowska.com',
		facebookUrl: 'https://www.facebook.com/akopowskaszkolenia/',
		instagramUrl: '',
	},
	{
		id: 8,
		firstName: 'Elżbieta',
		lastName: 'Chwalisz',
		description: '',
		city: 'Gorzów Wielkopolski',
		imageUrl: './assets/10-min.png',
		phone: '516 030 161',
		email: 'chwalisz@op.pl',
		facebookUrl:
			'https://www.facebook.com/Studio-Urody-Chwalisz-Lashes-Szkolenia-Gorz%C3%B3w-Wlkp-Drezdenko-1089690784384128',
		instagramUrl: '',
	},
	{
		id: 9,
		firstName: 'Martyna',
		lastName: 'Jasińska',
		description: '',
		city: 'Warszawa',
		imageUrl: './assets/13-min.png',
		phone: '881 553 251',
		email: 'marjass.szkolenia@gmail.com',
		facebookUrl: 'https://www.facebook.com/marjassbeautymaker',
		instagramUrl: '',
	},
	{
		id: 10,
		firstName: 'Olimpia',
		lastName: 'Babiarz',
		description: '',
		city: 'Wałbrzych',
		imageUrl: './assets/12-min.png',
		phone: '531 520 531',
		email: 'olimpia.babiarz@gmail.com',
		facebookUrl: 'https://www.facebook.com/LashBrowSzkolenia/',
		instagramUrl: 'https://www.instagram.com/olimpia.babiarz_inlei/',
	},
	{
		id: 11,
		firstName: 'Joanna',
		lastName: 'Wideńska',
		description: `Witaj! Cieszę się, że możemy się poznać. Nazywam się Joanna Wideńska i od 9 lat zajmuje się stylizacją rzęs,a od 6 lat prowadzę własny salon stylizacji rzęs i brwi na terenie Lublina.
			Od 2020 mam przyjemność reprezentować markę inLei w województwie lubelskim, oraz podnosić kwalifikacje uczestników szkoleń.
			Kilkukrotnie sama stawałam na podium jako uczestnik mistrzostw stacjonarnych oraz online.
			Mam na swoim koncie kilka tytułów z których jestem niezmiernie dumna. 
			- 1 miejsce w kategorii Nude - Mistrzostwa LashMe - Warszawa 2018
			- 2 miejsce w kategorii 1:1 - Mistrzostwa Lash.pl - Poznań 2018
			- 1 miejsce w kategorii Classic Lashes - II edycja Mistrzostw tPrzedłużania Rzęs Mazury 2018 - Ostróda
			- 3 miejsce w kategorii 1:1 - Mistrzostwa LashMe 2019 - Warszawa
			- 1 miejsce w kategorii Nude - Mistrzostwa LashMe 2019 - Warszawa
			- Tytuł Stylistki Roku w województwie lubelskim Top of the Top 2018
			- Tytuł Lash Room w województwie lubelskim Top of the Top 2018
			- 4 miejsce w kategorii 1:1 - Mistrzostwa Lash.pl - Poznań 2019
			 
			Chętnie wprowadzę Cię w ten mistrzowski zawód i przed stawie najlepsze produkty na rynku.
			Zapraszam Cię do zapoznania ze szczegółowym programem szkoleń.
			`,
		city: 'Lublin',
		imageUrl: './assets/13-min.png',
		phone: '724 525 212',
		email: 'widenskajoanna@gmail.com',
		facebookUrl: '',
		instagramUrl: '',
	},
	{
		id: 12,
		firstName: 'Weronika',
		lastName: 'Ptaszyńska',
		description:
			'Nazywam się Weronika i jestem instruktorem firmy Inlei na województwo lubelskie. Z wykształcenia jestem kosmetologiem i prowadzę własny salon w Radzyniu Podlaskim. Jestem również instruktorem firmy Imthelashmaker w której prowadzę szkolenia z przedłużania rzęs. Jestem laureatka międzynarodowych mistrzostw. Laminacja rzęs to zdecydowanie mój ulubiony zabieg. Zapraszam Cię na moje szkolenia',
		city: 'Radzyń Podlaski',
		imageUrl: './assets/13-min.png',
		phone: '530 246 588',
		email: '',
		facebookUrl: '',
		instagramUrl: '',
	},
	{
		id: 13,
		firstName: 'Sasha',
		lastName: 'Krystyna',
		description: '',
		city: 'Radzyń Podlaski',
		imageUrl: './assets/13-min.png',
		phone: '797 102 602',
		email: 'szkolenia.sash@gmail.com',
		facebookUrl: 'https://www.facebook.com/sash.lash.brow',
		instagramUrl: 'https://www.instagram.com/sash.lash/',
	},
	{
		id: 14,
		firstName: 'Ola',
		lastName: 'Kardela-Sygo',
		description: `Hej, jestem Ola i od 2015r prowadzę gabinet kosmetyczny w Opolu, od 2016 jestem szkoleniowcem z zakresu stylizacji rzęs i brwi. Spod swoich skrzydeł wypuściłam już wiele utalentowanych kursantek, które z powodzeniem wykonują zabiegi, osiągając spektakularne efekty. Moje kursantki są moją dumą, a moja praca jest moja pasją i uzależnieniem. Każdą z nich udało mi się zarazić miłością do wykonywanych zabiegów. Zabiegi laminacji rzęs skradły moje serce.
 
			Zapraszam Cię na moje szkoleni gdzie zarażę Cię swoją pasją do zabiegów na rzęsach i brwiach
			`,
		city: 'Radzyń Podlaski',
		imageUrl: './assets/2-min.png',
		phone: '796 764 171',
		email: 'ola.sygo@gmail.com',
		facebookUrl: 'https://www.facebook.com/kosmetyczneuslugi',
		instagramUrl: 'https://www.instagram.com/salonbeautyroom/',
	},
	{
		id: 15,
		firstName: 'Monika',
		lastName: 'Mroczka',
		description: '',
		city: 'Radzyń Podlaski',
		imageUrl: './assets/13-min.png',
		phone: '504 223 774',
		email: 'lashselling@gmail.com',
		facebookUrl: 'https://www.facebook.com/MonikaMroczkaSzkolenia',
		instagramUrl: 'https://www.instagram.com/monika_mroczka_/',
	},
	{
		id: 16,
		firstName: 'Klaudia',
		lastName: 'Kasuła',
		description: '',
		city: 'Radzyń Podlaski',
		imageUrl: './assets/13-min.png',
		phone: '726 559 388',
		email: 'szkoleniachojna@gmail.com',
		facebookUrl: 'https://www.facebook.com/lashhouseklaudiakasula',
		instagramUrl: 'https://www.instagram.com/klaudia.kasula/',
	},
	{
		id: 17,
		firstName: 'Monika',
		lastName: 'Frandofert',
		description: '',
		city: 'Radzyń Podlaski',
		imageUrl: './assets/13-min.png',
		phone: '660 741 072',
		email: 'monika.frandofert@gmail.com',
		facebookUrl: 'https://www.facebook.com/monart.frandofert',
		instagramUrl: '',
	},
	{
		id: 18,
		firstName: 'Nicolette',
		lastName: 'Wudel',
		description: `Zawodowo związana z branżą beauty jestem ponad 7lat. Z każdym kolejnym szkoleniem i pogłębianiem wiedzy rosłam w przekonaniu, że jest to właśnie to co chce robić w życiu, świadomie podjęłam decyzje o otworzeniu salonu kosmetycznego w Koszalinie, by spełniać się w roli stylistki rzęs oraz brwi. Moja pasja do pracy utwierdziła mnie w przekonaniu, że pragnę dzielić się z innymi miłością do rzęs i brwi i tak od 5 lat spełniam się również w roli instruktora. Szkolenia staram się prowadzić na najwyższym poziomie, przekazywać swoją wiedzę najlepiej jak potrafię, po to by zarazić Cię wspaniałą pasją.
			Marzeń mam mnóstwo, a to za co najbardziej uwielbiam swoją pracę to ciągła możliwość rozwoju, spełniania się oraz wyznaczania sobie coraz wyższych celów.
			Jeżeli Twoim marzeniem jest również zajmowanie się oprawą oka- Zapraszam Cię serdecznie na szkolenia
			`,
		city: 'Radzyń Podlaski',
		imageUrl: './assets/13-min.png',
		phone: '791 813 782',
		email: 'nitka58@wp.pl',
		facebookUrl:
			'https://www.facebook.com/PerfectLashes-Nicolette-Wudel-Instruktor-Amazing-LashesInLeiKoszalin-1591963397522741/',
		instagramUrl: 'https://www.instagram.com/perfectlashesnicolettewudel/',
	},
];
