import { Restaurant } from 'App/domain/entities/restaurant';

export const mapRestaurantToResponseType = (restaurant: Restaurant) => {
	let restaurantToReturn = {
		...restaurant,
	};

	if (restaurant.time_open?.monday_open_hours) {
		restaurantToReturn.time_open['monday']['time_open'] = restaurant.time_open.monday_open_hours?.time_start;
		restaurantToReturn.time_open['monday']['time_close'] = restaurant.time_open.monday_open_hours?.time_end;
	}

	if (restaurant.time_open?.tuesday_open_hours) {
		restaurantToReturn.time_open['tuesday']['time_open'] = restaurant.time_open.tuesday_open_hours?.time_start;
		restaurantToReturn.time_open['tuesday']['time_close'] = restaurant.time_open.tuesday_open_hours?.time_end;
	}

	if (restaurant.time_open?.wednesday_open_hours) {
		restaurantToReturn.time_open['wednsday']['time_open'] = restaurant.time_open.wednesday_open_hours?.time_start;
		restaurantToReturn.time_open['wednsday']['time_close'] = restaurant.time_open.wednesday_open_hours?.time_end;
	}

	if (restaurant.time_open?.thursday_open_hours) {
		restaurantToReturn.time_open['thursday']['time_open'] = restaurant.time_open.thursday_open_hours?.time_start;
		restaurantToReturn.time_open['thursday']['time_close'] = restaurant.time_open.thursday_open_hours?.time_end;
	}

	if (restaurant.time_open?.friday_open_hours) {
		restaurantToReturn.time_open['friday']['time_open'] = restaurant.time_open.friday_open_hours?.time_start;
		restaurantToReturn.time_open['friday']['time_close'] = restaurant.time_open.friday_open_hours?.time_end;
	}

	if (restaurant.time_open?.saturday_open_hours) {
		restaurantToReturn.time_open['saturday']['time_open'] = restaurant.time_open.saturday_open_hours?.time_start;
		restaurantToReturn.time_open['saturday']['time_close'] = restaurant.time_open.saturday_open_hours?.time_end;
	}

	if (restaurant.time_open?.sunday_open_hours) {
		restaurantToReturn.time_open['sunday']['time_open'] = restaurant.time_open.sunday_open_hours?.time_start;
		restaurantToReturn.time_open['sunday']['time_close'] = restaurant.time_open.sunday_open_hours?.time_end;
	}

	return restaurantToReturn;
};
