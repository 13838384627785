import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import { default as NotFoundPage } from './pages/ResultPages/NotFoundPage/NotFoundPageContainer';
import { default as ForbiddenPage } from './pages/ResultPages/ForbiddenPage/ForbiddenPageContainer';
import { default as InternalServerErrorPage } from './pages/ResultPages/InternalServerErrorPage/components/InternalServerErrorPageContainer';

import { default as HomePage } from './pages/HomePage/HomePageContainer';
import { default as LoginPage } from './pages/LoginPage/LoginPageContainer';
import { default as RegisterPage } from './pages/RegisterPage/RegisterPageContainer';
import { default as AccountPage } from './pages/AccountPage/AccountPageContainer';

import { default as RestaurantMenuPage } from './pages/RestaurantMenuPage/RestaurantMenuPageContainer';
import { default as PromotionsPage } from './pages/PromotionsPage/PromotionsPageContainer';
import { default as OrdersPage } from './pages/OrdersPage/OrdersPageContainer';
import { default as CartPage } from './pages/CartPage/CartPageContainer';
import { default as ContactPage } from './pages/ContactPage/ContactPageContainer';
import { default as DistributorsPage } from './pages/Distributors/DistributorsPageContainer';
import { default as TrainingsPage } from './pages/TrainingsPage/TrainingsPageContainer';
import { default as InstructorsPage } from './pages/InstructorsPage/InstructorsPageContainer';
import { default as BrowBomberPage } from './pages/HomePage/TrainingsPage/BrowBomberPage/BrowBomberPageContainer';
import { default as LashFillerPage } from './pages/HomePage/TrainingsPage/LashFillerPage/LashFillerPageContainer';
import { default as ProductTrainingsPage } from './pages/HomePage/TrainingsPage/ProductTrainigsPage/ProductTrainingsPageContainer';
import { default as AboutUsPage } from './pages/AboutUsPage/AboutUsPageContainer';
import { default as InstructorsDetails } from './pages/HomePage/components/instructorDetails/InstructorDetails';

import PaymentMaintenanceBreakPage from './pages/CartPage/components/payment/PaymentMaintenanceBreakPage';
import TermsAndConditionsContainer from './pages/LegalPages/TermsAndConditions/TermsAndConditionsContainer';
import PrivacyPolicyContainer from './pages/LegalPages/PrivacyPolicy/PrivacyPolicyContainer';

const Routes: React.FC = () => {
	return (
		<Switch>
			<Route exact path='/' component={HomePage} />
			<Route exact path='/contact' component={ContactPage} />
			<Route exact path='/distributors' component={DistributorsPage} />
			<Route exact path='/trainings' component={TrainingsPage} />
			<Route exact path='/instructors' component={InstructorsPage} />
			<Route exact path='/about-us' component={AboutUsPage} />

			<Route exact path='/instructors/:instructorId' component={InstructorsDetails} />

			<Route exact path='/brow-bomber' component={BrowBomberPage} />
			<Route exact path='/lash-filler' component={LashFillerPage} />
			<Route exact path='/product-trainings' component={ProductTrainingsPage} />

			{/* <Route exact path='/sign-in' component={LoginPage} /> */}
			{/* <Route exact path='/sign-up' component={RegisterPage} /> */}

			{/* <Route path='/account' component={AccountPage} /> */}

			<Route path='/shop/:selectedCategory?' component={RestaurantMenuPage} />
			{/* <Route path='/promotions' component={PromotionsPage} />
			<Route path='/orders' component={OrdersPage} /> */}
			<Route path='/cart' component={CartPage} />
{/* 
			<Route path='/payment-maintenance-break' component={PaymentMaintenanceBreakPage} />

			<Route path='/terms-and-conditions' component={TermsAndConditionsContainer} />
			<Route path='/privacy-policy' component={PrivacyPolicyContainer} /> */}

			<Route path='/403' component={ForbiddenPage} />
			<Route path='/404' component={NotFoundPage} />
			<Route path='/500' component={InternalServerErrorPage} />
			<Redirect to='/404' />
		</Switch>
	);
};

export default Routes;
