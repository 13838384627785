import {
	CheckCircleOutlined,
	ClockCircleOutlined,
	ConsoleSqlOutlined,
	FireFilled,
	HeartFilled,
	ShoppingCartOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Button, Card, Empty, List, Modal, notification, Tabs, Tag, Tooltip } from 'antd';
import { ProductForGetAllProductsCategoriesResponse } from 'App/api/endpoints/productCategories/responses/getAllProductsCategoriesResponse';
import { GetRestaurantResponse } from 'App/api/endpoints/restaurants/responses/getRestaurantResponse';
import React, { useState } from 'react';
import './styles/RestaurantMenuCard.less';
import DetailedMenuProduct from '../detailedMenuProduct/DetailedMenuProduct';
import { getProductImgUrl } from '../utils/getProductImgUrl';
import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import Meta from 'antd/lib/card/Meta';
import { cartProductService } from 'App/services/cartProductService';
import { useDispatch, useSelector } from 'react-redux';
import { updateCart, updateRestaurant } from 'App/state/newOrder/newOrder.slice';
import { newOrder_cartInitialState } from 'App/state/newOrder/newOrder.state';
import { RootState } from 'App/state/root.reducer';
import { useHistory } from 'react-router';

interface RestaurantMenuCardProps {
	selectedCategory: string;
	showRibbon?: boolean;
	productCategories: ProductForGetAllProductsCategoriesResponse[];
	restaurantMenu: any;
	restaurantInfo: GetRestaurantResponse;
	loading: boolean;
	productIdsWithValidPromotions: {
		productId: number;
		promotions: PromotionForGetAllUserPromotions[];
	}[];
}

const RestaurantMenuCard: React.FC<RestaurantMenuCardProps> = (props: RestaurantMenuCardProps) => {
	const {
		showRibbon,
		productCategories,
		restaurantMenu,
		restaurantInfo,
		loading,
		productIdsWithValidPromotions,
		selectedCategory,
	} = props;

	const [isProductDetailsModalVisible, setIsProductDetailsModalVisible] = useState(false);
	const [productToShowInModal, setProductToShowInModal] = useState(null);

	const dispatch = useDispatch();

	const cart = useSelector((state: RootState) => state.newOrder.cart);
	const restaurantFromOrder = useSelector((state: RootState) => state.newOrder.restaurant);
	const restaurant = useSelector((state: RootState) => state.restaurants.restaurant);

	const history = useHistory();

	const getSortedProductCategories = () => {
		if (!productCategories) {
			return [];
		}

		const sortedProductCategories = [...productCategories].sort((a, b) => a.menu_order - b.menu_order);
		return sortedProductCategories;
	};

	const openProductDetailsModal = (product: any) => {
		setIsProductDetailsModalVisible(true);
		setProductToShowInModal(product);
	};

	const closeProductDetailsModal = () => {
		setIsProductDetailsModalVisible(false);
		setProductToShowInModal(null);
	};

	const getValidPromotionsForProduct = (product: any) => {
		// jeśli produkt nie istnieje, to nie ma promocji
		if (!product || !product.id) {
			return [];
		}

		// pobieramy indeks produktu z arrayki produktów z promocjami
		const productIndexInValidPromotionsArray = productIdsWithValidPromotions.findIndex(
			(p) => p.productId === product.id
		);

		// jeśli nie znaleziono takiego produktu to nie ma promocji
		if (productIndexInValidPromotionsArray < 0) {
			return [];
		}

		// jeśli znaleziono promocje obejmujące produkt, to dopisujemy id
		// produktu do listy produktów, wśród których ma być pokazana promocja
		if (productIdsWithValidPromotions[productIndexInValidPromotionsArray].promotions.length > 0) {
			return productIdsWithValidPromotions[productIndexInValidPromotionsArray].promotions;
		}

		return [];
	};

	const buyNow = (product) => {
		console.log(product);
		const values = { quantity: 1 };
		const validPromotionsForProduct = getValidPromotionsForProduct(product?.product);

		//sprawdź czy w state jest restauracja
		if (!restaurantFromOrder) {
			//jak nie ma to dodaj do state i dodaj cartProduct
			dispatch(
				updateRestaurant({
					...(restaurantInfo as any),
					products: [],
					place_products: [],
					place_product_options: [],
				})
			);

			let cartProduct = cartProductService.getCartProductFromForm(values, product?.product);
			cartProduct.promotions = validPromotionsForProduct;
			dispatch(updateCart({ ...newOrder_cartInitialState, cart_products: [cartProduct] }));
		} else {
			//jak jest restauracja
			//to sprawdz czy to ta sama z ktorej jest dodawany ten cartProduct
			if (restaurantFromOrder.id !== restaurant.id) {
				//jak nie to wyrzuć błąd że nie można z dwóch restauracji na raz
				notification.warning({
					message: 'Uwaga',
					description: 'Do jednego koszyka nie można dodać produktów z różnych sklepów!',
				});
			} else {
				//jak jest jest ta sama restauracja
				//to sprawdz czy to ten sam produkt czy nie i albo dodaj nowy albo dodaj ammount
				let cartProduct = cartProductService.getCartProductFromForm(values, product?.product);
				let cartProductIndex = cart.cart_products.findIndex(
					(product) => JSON.stringify(product) === JSON.stringify(cartProduct)
				);

				//jak nie ma to znaczy ze nowy i dodajemy do arrayki
				if (cartProductIndex === -1) {
					cartProduct.promotions = validPromotionsForProduct;
					dispatch(updateCart({ ...cart, cart_products: [...cart.cart_products, cartProduct] }));
				} else {
					//jak produkt już jest w koszyczku
					//to ustalamy nową ilość
					const newCount = cart.cart_products[cartProductIndex].count + cartProduct.count;

					//i tworzymy nową arraykę z zaaktualizowaną ilością
					const newCartProducts = cart.cart_products.map((product, index) =>
						index === cartProductIndex
							? { ...cart.cart_products[cartProductIndex], count: newCount }
							: product
					);

					dispatch(updateCart({ ...cart, cart_products: newCartProducts }));
				}
			}
		}

		notification.success({
			message: 'Sukces',
			description: 'Dodano produkt do koszyka!',
		});

		history.push('/cart');
	};

	const backgroundImageUrl = require('../../../common/assets/restaurant-placeholder.jpeg');

	const sortedProductsCategories = getSortedProductCategories();

	const onTabChange = (key) => {
		history.push(`/shop/${key}`);
	};

	const card = (
		<Card
			className='menu'
			headStyle={{
				backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
								 url(${backgroundImageUrl})`,
				height: 200,
			}}
			title={restaurantInfo?.name}
			loading={loading}
		>
			<Tabs defaultActiveKey={selectedCategory || 'Ulotki'} onChange={onTabChange}>
				{sortedProductsCategories.length > 0 &&
					sortedProductsCategories?.map((spc) => {
						const products = restaurantMenu.categories
							?.find((c) => c.id === spc.id)
							?.products?.filter((p) => p.is_active);

						// console.log('products: ', products?.length, selectedCategory);

						if (products && products.length > 0) {
							return (
								<Tabs.TabPane tab={spc.name} key={spc.id}>
									<List
										grid={{
											gutter: 16,
											xs: 1,
											sm: 2,
											md: 3,
											lg: 4,
											xl: 4,
											xxl: 4,
										}}
										loading={loading}
										dataSource={products}
										locale={{
											emptyText: (
												<Empty
													description={'Brak produktów'}
													image={require('../../../common/assets/empty-placeholder-bw.jpg')}
													imageStyle={{
														height: 60,
													}}
												/>
											),
										}}
										renderItem={(product: any) => {
											// console.log(
											// 	'product.product.categories: ',
											// 	product.product.categories.map((category) => category.id),
											// 	product.product.name,
											// 	selectedCategory
											// );
											if (
												product.product.categories.find((category) => {
													return category.id === +selectedCategory;
												})
											) {
												return renderProduct(product);
											}
										}}
									></List>
								</Tabs.TabPane>
							);
						} else {
							return <React.Fragment key={spc.id}></React.Fragment>;
						}
					})}
			</Tabs>
			{getSortedProductCategories().length <= 0 && (
				<Empty
					description={'Brak produktów'}
					image={require('../../../common/assets/empty-placeholder-bw.jpg')}
					imageStyle={{
						height: 60,
					}}
				/>
			)}
		</Card>
	);

	const renderPromotionIfNeeded = (product: any) => {
		if (!product || !product.id) {
			return <></>;
		}

		const productIndexInValidPromotionsArray = productIdsWithValidPromotions.findIndex(
			(p) => p.productId === product.id
		);

		if (productIndexInValidPromotionsArray < 0) {
			return <></>;
		}

		if (productIdsWithValidPromotions[productIndexInValidPromotionsArray].promotions.length > 0) {
			return (
				<Tag color='volcano' className='ml-2'>
					Promocja
				</Tag>
			);
		}

		return <></>;
	};

	const renderProduct = (product: any): React.ReactNode => {
		return (
			<List.Item className='menu__list-item'>
				<Card
					hoverable
					cover={<img alt='example' src={getProductImgUrl(product.product)} />}
					onClick={() => {
						openProductDetailsModal(product);
					}}
				>
					<Meta
						title={product.product.name}
						description={product.product.description.substring(0, 100) + '...'}
					/>
					<div className='menu__price'>{`${product.product.price_pln.toFixed(2)} PLN`}</div>
					<Button
						htmlType='submit'
						type='primary'
						icon={<ShoppingCartOutlined />}
						onClick={(e) => {
							e.stopPropagation();
							buyNow(product);
						}}
					>
						Kup teraz
					</Button>
				</Card>
			</List.Item>
			// <List.Item
			// 	className='menu__list-item'
			// 	onClick={() => {
			// 		openProductDetailsModal(product);
			// 	}}
			// >
			// 	<List.Item.Meta
			// 		avatar={<Avatar src={getProductImgUrl(product.product)} />}
			// 		title={
			// 			<div>
			// 				{product.product.name}
			// 				{renderPromotionIfNeeded(product.product)}
			// 			</div>
			// 		}
			// 		description={
			// 			<>
			// 				{product.product.description && <span>{product.product.description}</span>}
			// 				<p>
			// 					{product.product.is_vegan && (
			// 						<span className='menu__attribute'>
			// 							<Tooltip title='Vegan'>
			// 								<CheckCircleOutlined className='menu__vegan' />
			// 							</Tooltip>
			// 						</span>
			// 					)}
			// 					{product.product.is_long_to_prepare && (
			// 						<span className='menu__attribute'>
			// 							<Tooltip title='Potrawa długa w przygotowaniu'>
			// 								<ClockCircleOutlined className='menu__long-to-prepare' />
			// 							</Tooltip>
			// 						</span>
			// 					)}
			// 					{product.product.is_spicy1 && (
			// 						<span className='menu__attribute'>
			// 							<Tooltip title='Ostry'>
			// 								<FireFilled className='menu__spicy1' />
			// 							</Tooltip>
			// 						</span>
			// 					)}
			// 					{product.product.is_spicy2 && (
			// 						<span className='menu__attribute'>
			// 							<Tooltip title='Ostrzejszy'>
			// 								<FireFilled className='menu__spicy2' />
			// 							</Tooltip>
			// 						</span>
			// 					)}
			// 					{product.product.is_spicy3 && (
			// 						<span className='menu__attribute'>
			// 							<Tooltip title='Najostrzejszy'>
			// 								<FireFilled className='menu__spicy3' />
			// 							</Tooltip>
			// 						</span>
			// 					)}
			// 				</p>
			// 			</>
			// 		}
			// 	/>
			// 	<div className='menu__price'>{`${product.product.price_pln.toFixed(2)} PLN`}</div>
			// </List.Item>
		);
	};

	return (
		<>
			<Modal
				title={productToShowInModal && productToShowInModal.product.name}
				visible={isProductDetailsModalVisible}
				onOk={closeProductDetailsModal}
				onCancel={closeProductDetailsModal}
				destroyOnClose
				footer={null}
			>
				<DetailedMenuProduct
					product={productToShowInModal?.product}
					validPromotionsForProduct={getValidPromotionsForProduct(productToShowInModal?.product)}
					restaurant={restaurantInfo}
					closeModal={closeProductDetailsModal}
				/>
			</Modal>
			{showRibbon ? <Badge.Ribbon text={<HeartFilled />}>{card}</Badge.Ribbon> : card}
		</>
	);
};

export default RestaurantMenuCard;
