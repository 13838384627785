import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateAccountDetailsRequest } from 'App/api/endpoints/account/requests';
import { GetAccountDetailsResponse, GetUserAddressesResponse } from 'App/api/endpoints/account/responses';
import StatusType from 'App/types/requestStatus';
import { accountInitialState, AccountState } from './account.state';

const { FAILED, SUCCESS, LOADING } = StatusType;

const accountSlice = createSlice({
	name: 'account',
	initialState: accountInitialState,
	reducers: {
		confirmEmailStart: (state: AccountState) => {
			state.status.confirmEmail = LOADING;
		},
		confirmEmailSuccess: (state: AccountState) => {
			state.status.confirmEmail = SUCCESS;
		},
		confirmEmailFailure: (state: AccountState) => {
			state.status.confirmEmail = FAILED;
		},

		generateNewPasswordStart: (state: AccountState) => {
			state.status.forgotPassword = LOADING;
		},
		generateNewPasswordSuccess: (state: AccountState) => {
			state.status.forgotPassword = SUCCESS;
		},
		generateNewPasswordFailure: (state: AccountState) => {
			state.status.forgotPassword = FAILED;
		},

		updateAccountDetailsStart: (state: AccountState) => {
			state.status.updateAccountDetails = LOADING;
		},
		updateAccountDetailsSuccess: (state: AccountState, action: PayloadAction<UpdateAccountDetailsRequest>) => {
			state.status.updateAccountDetails = SUCCESS;
			const oldAccountDetails = { ...state.accountDetails };
			state.accountDetails = {
				...oldAccountDetails,
				client_information: {
					...oldAccountDetails.client_information,
					...action.payload,
					phone_number: action.payload?.new_phone_number,
				},
			};
		},
		updateAccountDetailsFailure: (state: AccountState) => {
			state.status.updateAccountDetails = FAILED;
		},

		updateAccountPhoneNumberStart: (state: AccountState) => {
			state.status.updateAccountPhoneNumber = LOADING;
		},
		updateAccountPhoneNumberSuccess: (state: AccountState, action: PayloadAction<any>) => {
			state.accountDetails.client_information.phone_number = action.payload.client_information.phone_number;
			state.status.updateAccountPhoneNumber = SUCCESS;
		},
		updateAccountPhoneNumberFailure: (state: AccountState) => {
			state.status.updateAccountPhoneNumber = FAILED;
		},

		changePasswordStart: (state: AccountState) => {
			state.status.changePassword = LOADING;
		},
		changePasswordSuccess: (state: AccountState) => {
			state.status.changePassword = SUCCESS;
		},
		changePasswordFailure: (state: AccountState) => {
			state.status.changePassword = FAILED;
		},

		addUserAddressStart: (state: AccountState) => {
			state.status.addUserAddress = LOADING;
		},
		addUserAddressSuccess: (state: AccountState) => {
			state.status.addUserAddress = SUCCESS;
		},
		addUserAddressFailure: (state: AccountState) => {
			state.status.addUserAddress = FAILED;
		},

		updateUserAddressStart: (state: AccountState) => {
			state.status.updateUserAddress = LOADING;
		},
		updateUserAddressSuccess: (state: AccountState) => {
			state.status.updateUserAddress = SUCCESS;
		},
		updateUserAddressFailure: (state: AccountState) => {
			state.status.updateUserAddress = FAILED;
		},

		deleteUserAddressStart: (state: AccountState) => {
			state.status.deleteUserAddress = LOADING;
		},
		deleteUserAddressSuccess: (state: AccountState) => {
			state.status.deleteUserAddress = SUCCESS;
		},
		deleteUserAddressFailure: (state: AccountState) => {
			state.status.deleteUserAddress = FAILED;
		},

		getUserAddressesStart: (state: AccountState) => {
			state.status.getUserAddresses = LOADING;
			state.userAddresses = accountInitialState.userAddresses;
		},
		getUserAddressesSuccess(state: AccountState, action: PayloadAction<GetUserAddressesResponse[]>) {
			state.status.getUserAddresses = SUCCESS;
			state.userAddresses = action.payload;
		},
		getUserAddressesFailure(state: AccountState) {
			state.status.getUserAddresses = FAILED;
		},

		deleteAccountStart: (state: AccountState) => {
			state.status.deleteAccount = LOADING;
		},
		deleteAccountSuccess: (state: AccountState) => {
			state.status.deleteAccount = SUCCESS;
		},
		deleteAccountFailure: (state: AccountState) => {
			state.status.deleteAccount = FAILED;
		},

		getAccountDetailsStart: (state: AccountState) => {
			state.status.getAccountDetails = LOADING;
		},
		getAccountDetailsSuccess: (state: AccountState, action: PayloadAction<GetAccountDetailsResponse>) => {
			state.status.getAccountDetails = SUCCESS;
			state.accountDetails = action.payload;
		},
		getAccountDetailsFailure: (state: AccountState) => {
			state.status.getAccountDetails = FAILED;
		},

		clearAccountStart: (state: AccountState) => {
			state.accountDetails = null;
			state.status.getAccountDetails = StatusType.INITIAL;
		},

		cleanUpAccountStatusStart: (state: AccountState) => {
			state.status = accountInitialState.status;
		},
	},
});

export default accountSlice;

export const {
	confirmEmailStart,
	confirmEmailSuccess,
	confirmEmailFailure,

	generateNewPasswordStart,
	generateNewPasswordSuccess,
	generateNewPasswordFailure,

	updateAccountDetailsStart,
	updateAccountDetailsSuccess,
	updateAccountDetailsFailure,

	updateAccountPhoneNumberStart,
	updateAccountPhoneNumberSuccess,
	updateAccountPhoneNumberFailure,

	changePasswordStart,
	changePasswordSuccess,
	changePasswordFailure,

	deleteAccountStart,
	deleteAccountSuccess,
	deleteAccountFailure,

	addUserAddressStart,
	addUserAddressSuccess,
	addUserAddressFailure,

	updateUserAddressStart,
	updateUserAddressSuccess,
	updateUserAddressFailure,

	deleteUserAddressStart,
	deleteUserAddressSuccess,
	deleteUserAddressFailure,

	getUserAddressesStart,
	getUserAddressesSuccess,
	getUserAddressesFailure,

	getAccountDetailsStart,
	getAccountDetailsSuccess,
	getAccountDetailsFailure,

	clearAccountStart,

	cleanUpAccountStatusStart,
} = accountSlice.actions;
