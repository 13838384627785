
export const layout = {
    labelCol: {
        xs: {
            span: 24
        },
        md: {
            span: 8
        }
    },
    wrapperCol: {
        xs: {
            span: 24
        },
        md: {
            span: 8
        }
    }
};

export const tailLayout = {
    wrapperCol: {
        xs: {
            span: 24
        },
        md: {
            span: 8,
            offset: 8
        }
    }
};

export const layoutModal = {
    labelCol: {
        span: 24
    },
    wrapperCol: {
        span: 24
    }
};

export const tailLayoutModal = {
    wrapperCol: {
        span: 24,
    }
};