import { GetUserOrderResponse } from 'App/api/endpoints/userOrders/responses/getUserOrderResponse';
import { OrderForGetUserOrdersResponse } from 'App/api/endpoints/userOrders/responses/getUserOrdersResponse';
import StatusType from 'App/types/requestStatus';
const { INITIAL } = StatusType;

export interface OrdersState {
	status: {
		getUserOrders: StatusType;
		getUserOrder: StatusType;
	};
	orders: OrderForGetUserOrdersResponse[];
	selectedOrder: GetUserOrderResponse;
}

export const ordersInitialState: OrdersState = {
	status: {
		getUserOrders: INITIAL,
		getUserOrder: INITIAL,
	},
	selectedOrder: null,
	orders: [],
};
