import agent from 'App/api/agent/agent';

export const geolocationService = {
	getGeolocationFromAddress(address: string): any {
		if (address === '') return;

		return agent.Geolocation.getCords(address).then((response) => {
			try {
				let location = response.results[0].locations[0];
				let cords = [location.latLng.lat, location.latLng.lng];
				let country = location.adminArea1;

				//TODO to też śmierdzące ale pozwalamy tylko na Polskę
				if (country === 'PL') {
					return cords;
				} else {
					console.log('Adres z poza Polski');
					return;
				}
			} catch (e) {
				console.log(e);
				return;
			}
		});
	},
};
