import { FacebookOutlined, InstagramOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import { RouteComponentProps } from 'react-router';
import { Instructor } from '../instructorsMap/interfaces/instructor';
import { instructorsData } from '../instructorsMap/utils/instructorsData';

import mapSectionMap from './assets/mapa.png';

import './InstructorDetails.less';

interface RouteParams {
	instructorId: string;
}

interface InstructorDetailsProps extends RouteComponentProps<RouteParams> {}

const InstructorDetails: React.FC<InstructorDetailsProps> = ({ match }: InstructorDetailsProps) => {

    const [chosenInstructor, setChosenInstructor] = useState<Instructor>(instructorsData[0]);

    useEffect(() => {
        setChosenInstructor(instructorsData.find((x) => x.id === Number(match.params.instructorId)));
    }, []);
    


	return (
        <div>
            {/* Nagłówek */}
            <Row>
                <Row justify='center' style={{width: '100%'}} className='instructor-details-main-header'>
                    Instruktor
                </Row>
                <Row justify='center' style={{width: '100%'}}>
                    <div className='instructor-data-header-divider'>
						<span></span>
					</div>
                </Row>
            </Row>

            {/* Szczegóły */}
            <Row justify='center'>
                <Col xs={22} sm={22} md={8} lg={8} xl={8}>
                    <Row align='middle' style={{height: '100%'}}>
                        <div>
                            <Row className='instructor-details-description-header'>
                                {chosenInstructor.firstName} {chosenInstructor.lastName}
                            </Row>
                            <Row>
                                <div className='instructor-data-description-divider'>
                                    <span></span>
                                </div>
                            </Row>
                            <Row className='instructor-details-description-text'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </Row>
                            <Row className='instructor-details-description-list'>
                                <ul>
                                    <li>
                                        Lorem ipsum dolor sit
                                    </li>
                                    <li>
                                        Lorem ipsum dolor sit
                                    </li>
                                </ul>
                            </Row>
                        </div>
                    </Row>
                </Col>
                <Col xs={22} sm={22} md={12} lg={12} xl={12}>
                    <Row justify='center' align='middle' style={{height: '100%'}}>
                        <img
							src={require(`${chosenInstructor.imageUrl}`)}
                            alt='instructor'
                            style={{width: '80%', height: "auto"}}
						/>                    
                    </Row>
                    
                </Col>
            </Row>

            {/* Linki */}
            <Row justify='center'>

                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Row align='middle' className='instructor-details-social-item'>
                                <FacebookOutlined className='instructor-details-social-icon'/> Facebook
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Row align='middle' className='instructor-details-social-item'>
                                <InstagramOutlined className='instructor-details-social-icon'/> Instagram
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Row align='middle' className='instructor-details-social-item'>
                                <PhoneOutlined className='instructor-details-social-icon'/> Phone
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Row align='middle' className='instructor-details-social-item'>
                                <MailOutlined className='instructor-details-social-icon'/> Email
                            </Row>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </div>
	);
};

export default InstructorDetails;
