import React, { useEffect } from 'react';

import { Row, Col } from 'antd';

import './DistributorsPageContainer.less';

import distributorLogo1 from './assets/1.png';
import distributorLogo2 from './assets/2.png';
import distributorLogo3 from './assets/3.png';
import distributorLogo4 from './assets/4.png';
import distributorLogo5 from './assets/5.png';
import distributorLogo6 from './assets/6.png';

import AOS from 'aos';

const DistributorsPageContainer: React.FC<{}> = () => {
	useEffect(() => {
		AOS.init({
			duration: 2600,
			once: true,
		});
		window.addEventListener('load', AOS.refresh);
	}, []);

	return (
		<div>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div data-aos='fade-in'>
						<Row justify='center' className='distributors-page-container-title'>
							Dystrybutorzy
						</Row>
						<Row justify='center' className='distributors-page-container-description'>
							Oficjalna dystrybucja na terenie Polski
						</Row>
						<Row justify='center'>
							<div className='distributors-page-container-divider'></div>
						</Row>
					</div>

					<div data-aos='fade-in'>
						<Row justify='center' className='distributors-page-container-title'>
							<Col xs={22} sm={20} md={14} lg={10} xl={7}>
								<Row justify='center'>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className='distributors-page-container-card'
									>
										<Row justify='center'>
											<img
												className='distributors-page-container-card-image'
												src={distributorLogo1}
											/>
										</Row>
										<Row
											justify='center'
											className='distributors-page-container-description-section'
										>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>InLei Polska</Row>
												<Row justify='center'>ul. Armii Krajowej 12/4</Row>
												<Row justify='center'>50-541 Wrocław</Row>
											</Col>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>531-223-533</Row>
												<Row justify='center'>sklep@inlei.pl</Row>
												<Row justify='center'>
													<a target='_blank' href='https://inlei.pl'>
														https://inlei.pl
													</a>
												</Row>
											</Col>
										</Row>
										<Row justify='center' className='distributors-page-container-button-section'>
											<div className='distributors-page-container-collapse-button'>Mapa</div>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row justify='center' className='distributors-page-container-partners-description'>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Row justify='center'>Partnerzy</Row>
							</Col>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Row justify='center'>
									<div className='distributors-page-container-partners-divider'></div>
								</Row>
							</Col>
						</Row>

						<Row justify='center'>
							<Col xs={22} sm={22} md={22} lg={7} xl={7} className='distributors-page-container-card-col'>
								<Row justify='center' className='distributors-page-container-card-col-row'>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className='distributors-page-container-card'
									>
										<Row justify='center'>
											<img
												className='distributors-page-container-card-image'
												src={distributorLogo2}
											/>
										</Row>
										<Row
											justify='center'
											className='distributors-page-container-description-section'
										>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>Rzęsownia.pl</Row>
												<Row justify='center'>ul. Wyszogrodzka 4</Row>
												<Row justify='center'>03-337 Warszawa</Row>
											</Col>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>533-216-967</Row>
												<Row justify='center'>sklep@rzesownia.pl</Row>
												<Row justify='center'>
													<a target='_blank' href='https://www.rzesownia.pl'>
														https://www.rzesownia.pl
													</a>
												</Row>
											</Col>
										</Row>
										<Row justify='center' className='distributors-page-container-button-section'>
											<div className='distributors-page-container-collapse-button'>Mapa</div>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col xs={22} sm={22} md={22} lg={7} xl={7} className='distributors-page-container-card-col'>
								<Row justify='center' className='distributors-page-container-card-col-row'>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className='distributors-page-container-card'
									>
										<Row justify='center'>
											<img
												className='distributors-page-container-card-image'
												src={distributorLogo3}
											/>
										</Row>
										<Row
											justify='center'
											className='distributors-page-container-description-section'
										>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>Rarity.pl</Row>
												<Row justify='center'>ul. Barcza 48/2L</Row>
												<Row justify='center'>10-685 Olsztyn</Row>
											</Col>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>504-223-774</Row>
												<Row justify='center'>shop@rarity.pl</Row>
												<Row justify='center'>
													<a target='_blank' href='http://rarity.pl'>
														http://rarity.pl
													</a>
												</Row>
											</Col>
										</Row>
										<Row justify='center' className='distributors-page-container-button-section'>
											<div className='distributors-page-container-collapse-button'>Mapa</div>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col xs={22} sm={22} md={22} lg={7} xl={7} className='distributors-page-container-card-col'>
								<Row justify='center' className='distributors-page-container-card-col-row'>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className='distributors-page-container-card'
									>
										<Row justify='center'>
											<img
												className='distributors-page-container-card-image'
												src={distributorLogo4}
											/>
										</Row>
										<Row
											justify='center'
											className='distributors-page-container-description-section'
										>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>Bellalash Store</Row>
												<Row justify='center'>ul. Bartosza Głowackiego 28</Row>
												<Row justify='center'>85-717 Bydgoszcz</Row>
											</Col>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>662-479-900</Row>
												<Row justify='center'>sklep@bellalash.pl</Row>
												<Row justify='center'>
													<a target='_blank' href='https://www.bellalash.pl'>
														https://www.bellalash.pl
													</a>
												</Row>
											</Col>
										</Row>
										<Row justify='center' className='distributors-page-container-button-section'>
											<div className='distributors-page-container-collapse-button'>Mapa</div>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col xs={22} sm={22} md={22} lg={7} xl={7} className='distributors-page-container-card-col'>
								<Row justify='center' className='distributors-page-container-card-col-row'>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className='distributors-page-container-card'
									>
										<Row justify='center'>
											<img
												className='distributors-page-container-card-image'
												src={distributorLogo5}
											/>
										</Row>
										<Row
											justify='center'
											className='distributors-page-container-description-section'
										>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>Rzęsy dla Ciebie</Row>
												<Row justify='center'>ul. Andrzeja Struga 26</Row>
												<Row justify='center'>90-513 Łódź</Row>
											</Col>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>733-777-128</Row>
												<Row justify='center'>sklep@rzesydlaciebie.pl</Row>
												<Row justify='center'>
													<a target='_blank' href='https://rzesydlaciebie.pl'>
														https://rzesydlaciebie.pl
													</a>
												</Row>
											</Col>
										</Row>
										<Row justify='center' className='distributors-page-container-button-section'>
											<div className='distributors-page-container-collapse-button'>Mapa</div>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col xs={22} sm={22} md={22} lg={7} xl={7} className='distributors-page-container-card-col'>
								<Row justify='center' className='distributors-page-container-card-col-row'>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className='distributors-page-container-card'
									>
										<Row justify='center'>
											<img
												className='distributors-page-container-card-image'
												src={distributorLogo6}
											/>
										</Row>
										<Row
											justify='center'
											className='distributors-page-container-description-section'
										>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>Lexelle Beauty</Row>
												<Row justify='center'>ul. Wojska Polskiego 2a-e/2b</Row>
												<Row justify='center'>72-600 Świnoujście</Row>
											</Col>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>576-606-103</Row>
												<Row justify='center'>biuro@lexelle.pl</Row>
												<Row justify='center'>
													<a target='_blank' href='https://lexelle.pl'>
														https://lexelle.pl
													</a>
												</Row>
											</Col>
										</Row>
										<Row justify='center' className='distributors-page-container-button-section'>
											<div className='distributors-page-container-collapse-button'>Mapa</div>
										</Row>
									</Col>
								</Row>
							</Col>
							<Col xs={22} sm={22} md={22} lg={7} xl={7} className='distributors-page-container-card-col'>
								<Row justify='center' className='distributors-page-container-card-col-row'>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className='distributors-page-container-card'
									>
										<Row justify='center'>
											<img
												className='distributors-page-container-card-image'
												src={distributorLogo6}
											/>
										</Row>
										<Row
											justify='center'
											className='distributors-page-container-description-section'
										>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>LEBROSHOP</Row>
												{/* <Row justify='center'>ul. Wojska Polskiego 2a-e/2b</Row> */}
												<Row justify='center'>Koszalin</Row>
											</Col>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>505-990-517</Row>
												<Row justify='center'> info@lebro.shop</Row>
												<Row justify='center'>
													<a target='_blank' href='https://lebro.shop'>
														https://lebro.shop
													</a>
												</Row>
											</Col>
										</Row>
										<Row justify='center' className='distributors-page-container-button-section'>
											<div className='distributors-page-container-collapse-button'>Mapa</div>
										</Row>
									</Col>
								</Row>
							</Col>
							<Col xs={22} sm={22} md={22} lg={7} xl={7} className='distributors-page-container-card-col'>
								<Row justify='center' className='distributors-page-container-card-col-row'>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className='distributors-page-container-card'
									>
										<Row justify='center'>
											<img
												className='distributors-page-container-card-image'
												src={distributorLogo6}
											/>
										</Row>
										<Row
											justify='center'
											className='distributors-page-container-description-section'
										>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>Lashes Mania</Row>
												<Row justify='center'>ul. Ogrodowa 167</Row>
												<Row justify='center'>33-300 Nowy Sącz</Row>
											</Col>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>881-087-791</Row>
												<Row justify='center'> shop@lashesmania.com</Row>
												<Row justify='center'>
													<a target='_blank' href=' https://lashesmania.com'>
														https://lashesmania.com
													</a>
												</Row>
											</Col>
										</Row>
										<Row justify='center' className='distributors-page-container-button-section'>
											<div className='distributors-page-container-collapse-button'>Mapa</div>
										</Row>
									</Col>
								</Row>
							</Col>
							<Col xs={22} sm={22} md={22} lg={7} xl={7} className='distributors-page-container-card-col'>
								<Row justify='center' className='distributors-page-container-card-col-row'>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={24}
										xl={24}
										className='distributors-page-container-card'
									>
										<Row justify='center'>
											<img
												className='distributors-page-container-card-image'
												src={distributorLogo6}
											/>
										</Row>
										<Row
											justify='center'
											className='distributors-page-container-description-section'
										>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>Lash and You Shop</Row>
												<Row justify='center'>ul. Tadeusza Kościuszki 93</Row>
												<Row justify='center'>16-400 Suwałki</Row>
											</Col>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Row justify='center'>505-251-382</Row>
												<Row justify='center'>sklep@lashandyou.pl</Row>
												<Row justify='center'>
													<a target='_blank' href='https://lashandyou.pl/'>
														https://lashandyou.pl/
													</a>
												</Row>
											</Col>
										</Row>
										<Row justify='center' className='distributors-page-container-button-section'>
											<div className='distributors-page-container-collapse-button'>Mapa</div>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default DistributorsPageContainer;
