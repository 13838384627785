import { requests } from '../../agent/agent';
import {
	UpdateAccountDetailsRequest,
	ChangePasswordRequest,
	UpdateAccountPhoneNumberRequest,
	AddUserAddressRequest,
	UpdateUserAddressRequest,
	GenerateNewPasswordRequest,
} from './requests';
import {
	GetAccountDetailsResponse,
	GetUserAddressesResponse,
	UpdateAccountDetailsResponse,
	UpdateAccountPhoneNumberResponse,
} from './responses';

import { HttpStatusCodeResponse } from 'App/types/httpResponse.d';

export const AccountApi = {
	getAccountDetails: (): Promise<GetAccountDetailsResponse> => requests.get(`client`),

	updateAccountDetails: (body: UpdateAccountDetailsRequest): Promise<UpdateAccountDetailsResponse> =>
		requests.put(`/client`, body),

	updateAccountPhoneNumber: (body: UpdateAccountPhoneNumberRequest): Promise<UpdateAccountPhoneNumberResponse> =>
		requests.put(`/client/phoneNumber`, body),

	changePassword: (body: ChangePasswordRequest): Promise<HttpStatusCodeResponse> =>
		requests.put(`/client/password`, body),

	addUserAddress: (body: AddUserAddressRequest): Promise<HttpStatusCodeResponse> =>
		requests.post(`/client/address`, body),

	updateUserAddress: (addressId: number, body: UpdateUserAddressRequest): Promise<HttpStatusCodeResponse> =>
		requests.put(`/client/address/${addressId}`, body),

	deleteUserAddress: (addressId: number): Promise<any> => requests.delete(`/client/address/${addressId}`),

	getUserAddresses: (): Promise<GetUserAddressesResponse[]> => requests.get(`/client/address`),

	confirmEmail: (activationCode: string): Promise<HttpStatusCodeResponse> =>
		requests.get(`/register/activate/${activationCode}`),

	generateNewPassword: (body: GenerateNewPasswordRequest): Promise<HttpStatusCodeResponse> =>
		requests.post('/register/resetPassword', body),
};
