import React from 'react';

import { Row, Col } from 'antd';

import './ContactPageContainer.less';

const ContactPageContainer: React.FC<{}> = () => {

	return (
		<div className='contact-page-container'>
            <Row justify='center'>
                <Col xs={22} sm={22} md={22} lg={22} xl={20} className=''>

                    <Row justify='center' className='contact-page-container-header'>
                        Kontakt
                    </Row>


                    <Row justify='center'>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} className=''>
                            <Row className='contact-page-container-section-header'>
                                <span>
                                    Biuro obsługi klienta  
                                </span>
                            </Row>
                            <div className='contact-page-container-section-divider'></div>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    Tel: 531-223-533
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    E-mail: sklep@inlei.pl
                                </span>
                            </Row>

                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8} className=''>
                            <Row className='contact-page-container-section-header'>
                                <span>
                                    Sklep stacjonarny
                                </span>
                            </Row>
                            <div className='contact-page-container-section-divider'></div>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    ul. Armii Krajowej 12/4
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    50-541 Wrocław
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content contact-page-container-content-header'>
                                <span>
                                    Godziny otwarcia
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    Poniedziałek: 8:00 - 16:00
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    Wtorek: 8:00 - 16:00
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    Środa: 8:00 - 16:00
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    Czwartek: 8:00 - 16:00
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    Piątek: 10:00 - 14:00
                                </span>
                            </Row>

                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8} className=''>
                            <Row className='contact-page-container-section-header'>
                                <span>
                                    Dane firmy
                                </span>
                            </Row>
                            <div className='contact-page-container-section-divider'></div>

                            <Row className='contact-page-container-section-content contact-page-container-content-header'>
                                <span>
                                    InLei Polska
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    ul. Konduktorska 2/40
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    52-130 Wrocław
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    NIP: 6121820082
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    REGON: 366530740
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content contact-page-container-content-header'>
                                <span>
                                    36 1870 1045 2083 1064 2787 0001
                                </span>
                            </Row>

                            <Row className='contact-page-container-section-content'>
                                <span>
                                    Nest Bank S.A.
                                </span>
                            </Row>

                        </Col>

                    </Row>
                </Col>
            </Row>
		</div>
	);
};

export default ContactPageContainer;
