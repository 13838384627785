import { Col, Row } from 'antd';
import React, { useRef, useState } from 'react';
import Carousel from 'react-elastic-carousel';

import { Instructor } from './interfaces/instructor';
import mapSectionMap from './assets/mapa.png';

import './InstructorsMap.less';
import { Link } from 'react-router-dom';

interface InstructorsMapProps {
	instructors: Instructor[];
}

const InstructorsMap = ({ instructors }: InstructorsMapProps) => {

	const [chosenInstructor, setChosenInstructor] = useState<Instructor>(instructors[0]);
	const instructorsSlider = useRef(null);

	const handleInstructorSelectClick = (instructorId: number) => {
		setChosenInstructor(instructors.find((x) => x.id === instructorId));

		document.getElementById('map-item-' + chosenInstructor.id).classList.remove('map-section-image-grow');
		document.getElementById('map-item-' + instructorId).classList.add('map-section-image-grow');
		instructorsSlider.current.goTo(Number(instructorId));
	};

	return (
		<Row className='instructors-map-row'>
			<Col xs={22} sm={20} md={20} lg={12} xl={12} xxl={12} data-aos='fade-right' style={{marginLeft: 'auto', marginRight: 'auto'}}>

				<div style={{ position: 'relative', width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
					<img src={mapSectionMap} style={{ width: '100%', height: 'auto' }} alt='' />
					{instructors.map((instructor) => (
						<div
							key={instructor.id}
							onClick={() => handleInstructorSelectClick(instructor.id)}
							id={`map-item-${instructor.id}`}
							className='instructor-img-wrapper'
							style={{
								position: 'absolute',
								left: `${instructor.lonitudePercentage}%`,
								top: `${instructor.latitudePercentage}%`,
								width: '100%',
								height: '100%',
							}}
						>
							<img
								src={require(`${instructor.imageUrl}`)}
								className='instructor-img'
								alt={`${instructor.firstName} ${instructor.lastName}`}
							/>
						</div>
					))}
				</div>
			</Col>
			<Col xs={22} sm={20} md={20} lg={12} xl={12} data-aos='fade-left' style={{marginLeft: 'auto', marginRight: 'auto'}} >
				<Row justify='center' align='middle'>
					<div className='map-section-instructor-block-outer'>
						<div className='map-section-instructor-block-inner'>
							<Row justify='center' align='middle'>
								<Col xs={24} sm={24} md={12} lg={12} xl={12}>
									<Row justify='center' className='map-section-instructor-block-header'>
										{chosenInstructor.firstName} {chosenInstructor.lastName}
									</Row>
									<Row justify='center' className='map-section-instructor-block-description'>
										{chosenInstructor.description}
									</Row>
									<Row justify='center'>
										<Link to={`/instructors/${chosenInstructor.id}`} className='map-section-instructor-block-button-link'>
											<div className='map-section-instructor-block-button'>Zobacz</div>
										</Link>
									</Row>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12}>
									<Row justify='center'>
										<img
											src={require(`${chosenInstructor.imageUrl}`)}
											className='map-section-instructor-block-image'
											alt='instructor'
										/>
									</Row>
								</Col>
							</Row>
						</div>
					</div>
				</Row>
				<Row justify='center' align='middle'>
					<div className='map-section-instructor-carousel'>
						<Row justify='center' className='map-section-instructor-carousel-header'>
							Zobacz więcej instruktorów
						</Row>
						<Row align='middle' justify='center'>
							<Col xs={22} sm={22} md={22} lg={22} xl={22}>
								<Carousel
									ref={instructorsSlider}
									itemsToShow={3}
									initialActiveIndex={0}
									onChange={(currentItem, pageIndex) => {
										document
											.getElementById('map-item-' + chosenInstructor.id)
											.classList.remove('map-section-image-grow');
										setChosenInstructor(instructors.find((x) => x.id === currentItem.index));
										document
											.getElementById('map-item-' + currentItem.index)
											.classList.add('map-section-image-grow');
									}}
								>
									{instructors.map((instructor) => (
										<div 
										className='div map-section-carousel-block-div'
										onClick={() => {											
											setChosenInstructor(instructors.find((x) => x.id === instructor.id));
										}}
										>
											<Row justify='center'>
												<img
													src={require(`${instructor.imageUrl}`)}
													className='map-section-carousel-block-image'
													alt={`${instructor.firstName}`}
												/>
											</Row>
											<Row justify='center' className='map-section-carousel-block-title'>
												{instructor.firstName}
											</Row>
										</div>
									))}
								</Carousel>
							</Col>
						</Row>
					</div>
				</Row>
			</Col>
		</Row>
	);
};

export default InstructorsMap;
