import agent from 'App/api/agent/agent';
import { PromotionForGetAllUserPromotions } from 'App/api/endpoints/userPromotions/responses/getAllUserPromotionsResponse';
import { addDiscountCodeErrorCodes } from 'App/api/endpoints/userPromotions/utils/addDiscountCodeErrorCodes';
import { AppThunk } from '../store';
import store from 'App/state/store';
import {
	addDiscountCodePromotionFailure,
	addDiscountCodePromotionStart,
	addDiscountCodePromotionSuccess,
	getAllUserPromotionsFailure,
	getAllUserPromotionsStart,
	getAllUserPromotionsSuccess,
} from './promotions.slice';
import { cartService } from 'App/services/cartService';
import { ServiceResponseStatus } from 'App/services/utils/serviceResponse';
import { updateCart } from '../newOrder/newOrder.slice';
import { notification } from 'antd';

export const getAllUserPromotions = (): AppThunk => async (dispatch) => {
	dispatch(getAllUserPromotionsStart());
	agent.UserPromotions.getAllUserPromotions()
		.then((response: PromotionForGetAllUserPromotions[]) => {
			dispatch(getAllUserPromotionsSuccess(response));
		})
		.catch(() => dispatch(getAllUserPromotionsFailure()));
};

export const addDiscountCodePromotion = (code: string, onFinish: () => void): AppThunk => async (dispatch) => {
	dispatch(addDiscountCodePromotionStart());
	agent.UserPromotions.addDiscountCodePromotion(code)
		.then((response) => {
			if (response.status_code === addDiscountCodeErrorCodes.SUCCESS) {
				const cart = store.getState()?.newOrder.cart;
				const restaurant = store.getState()?.newOrder.restaurant
				const serviceResponse = cartService.isCouponCodeValidForCart(cart, restaurant, response.promotion)

				if (serviceResponse.status === ServiceResponseStatus.OK) {
					dispatch(updateCart({
						...cart,
						promotions: [response.promotion],
					}));
					notification['success']({
						message: "Sukces",
						description: "Dodano kod zniżkowy",
					});
					dispatch(addDiscountCodePromotionSuccess())
					onFinish();

				}
				else {
					notification['error']({
						message: "Błąd",
						description: serviceResponse.message,
					});
					dispatch(addDiscountCodePromotionFailure());
					onFinish();

				}
			}
		})
		.catch(() => {
			dispatch(addDiscountCodePromotionFailure())
			onFinish();
		})
};
