import React, { useEffect } from 'react';

import { Row, Col } from 'antd';

import './InstructorsPageContainer.less';

import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { instructorsData } from './utils/InstructorsData';
import AOS from 'aos';

const InstructorsPageContainer: React.FC<{}> = () => {
	useEffect(() => {
		AOS.init({
			duration: 2600,
			once: true,
		});
		window.addEventListener('load', AOS.refresh);
	}, []);

	return (
		<div>
			<Row justify='center'>
				<Col xs={24} sm={24} md={24} lg={22} xl={22} className=''>
					<div data-aos='fade-in'>
						<Row justify='center' className='trainings-page-container-title'>
							Instruktorzy
						</Row>
						<Row justify='center' className='trainings-page-container-image-divider'>
							<div></div>
						</Row>
					</div>

					<div data-aos='fade-in'>
						<Row justify='center' className='instructors-page-container-title'>
							{instructorsData.map((instructor) => (
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={6}
									xl={6}
									className='instructors-page-container-instructor-col'
								>
									<Row className='instructors-page-container-instructor-col-row'>
										<Col
											xs={24}
											sm={24}
											md={24}
											lg={24}
											xl={24}
											className='instructors-page-container-instructor-card'
										>
											<Row justify='center'>
												<img
													className='instructors-page-container-instructor-image'
													src={require(`${instructor.imageUrl}`)}
												/>
											</Row>
											<Row
												justify='center'
												className='instructors-page-container-instructor-name'
											>
												{instructor.firstName} {instructor.lastName}
											</Row>
											<Row
												justify='center'
												className='instructors-page-container-instructor-name'
											>
												{instructor.description}
											</Row>
											<Row
												justify='center'
												className='instructors-page-container-instructor-city'
											>
												{instructor.city}
											</Row>
											<Row
												justify='center'
												className='instructors-page-container-instructor-contact'
											>
												<div>{instructor.phone}</div>
												<div>{instructor.email}</div>
											</Row>
											<Row
												justify='center'
												className='instructors-page-container-instructor-social-media'
											>
												{instructor.facebookUrl ? (
													<a href={instructor.facebookUrl} target='_blank'>
														<FacebookOutlined className='instructors-page-container-instructor-social-media-icon-left' />
													</a>
												) : null}
												{instructor.instagramUrl ? (
													<a href={instructor.instagramUrl} target='_blank'>
														<InstagramOutlined className='instructors-page-container-instructor-social-media-icon-right' />
													</a>
												) : null}
											</Row>
										</Col>
									</Row>
								</Col>
							))}
						</Row>
					</div>

					<Row style={{ height: '100px' }}></Row>
				</Col>
			</Row>
		</div>
	);
};

export default InstructorsPageContainer;
